// strings.js
export const MISMATCH_PASSWORD = 'Passwords do not match';
export const LOGO_LENGTH = 'File size exceeds the limit of 3MB';
export const LOGO_REQUIRED = 'logo is required';
export const ERROR_OCCURED = 'Error Occured';
export const REGISTERED_SUCCESS ='Successfully Registered!';
export const PHONENO_ERROR ='Phone number must be in the format: (cc)number with 9-15 numbers';
export const PHONENO_LEADING_ZERO_ERROR ='The phone number should not begin with a zero';
export const PHONECODE_ERROR ='Enter an existing country code';
export const PASSWORD_ERROR ='Password should be 6-40 characters long and contain at least one uppercase letter, one lowercase letter, and one digit';
export const PASSWORD_ERROR_LOGIN ='Either email or password is invalid';
export const LENGTH_MAX = 'Length should be 3-50 characters';
export const INTERNET_AVAI = 'Internet Not Available';
export const TOKEN_EXPIRED = 'Access Token Expired';
export const DEVICENAME_LENGTH_ERROR ='Length should be 3-50 characters';
export const DEVICENAME_NULL_ERROR ='Name should not be "Null"';
export const DEVICENO_LENGTH_ERROR= 'Length should be 12-20 characters';
export const INVALID_EMAIL= 'Invalid Email';
export const EMAIL_SENT = 'Successfully Email Sent';
export const MISSING_FIELD= 'Entry has empty/missing values';
export const EMPTY_FILE= 'File must contain at least 1 item';
export const INVALID_DEVICE_ID = 'ffffffffffffffffffff';
export const INVALID_DEVICE_ID_FORMAT = 'Device number is without quotes';