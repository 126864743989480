import React from "react";
import { FaTimes, FaPlus, FaTrashAlt } from "react-icons/fa";
import DevicesList from "./DevicesList";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import intl from "../../locale/en-US.json";
import { useEffect } from "react";
import useInputFocus from "../../utils/customHooks/useInputFocus";
import BulkAddModal from "../Modal/BulkAddModal";
import{Actions, Categories, logEvent} from "../../analytics";

const Devices = ({ history }) => {
	const [searchText, setSearchText] = React.useState('');
	const [searchTerm, setSearchTerm] = React.useState('');
	const [currentPage, setCurrentPage] = React.useState(1);
	const [showModal, setShowModal] = React.useState(false);
	const [handleDelete, setHandleDelete] = React.useState(false);
	const [userList, setUserList] = React.useState([]);
	const isAnyInputFocused = useInputFocus();
	
	useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);

	const changePage = (page) => {
		setCurrentPage(page);
	};
	const closeModal = () => {
		setShowModal(false);
		logEvent({category:Categories.badge,action:Actions.device_bulk_upload_cancel_btn});											
	}

	const clearSearch = () => {
		setSearchTerm('');
		setSearchText('');
	}

	const checkUserLength = (users) => {
		setUserList(users);
	}

	return (
		<>
			<Breadcrumb links={[{ link: '/device', title: intl.deviceList }]}  />
			{showModal && (
				<BulkAddModal
					closeModal={closeModal}
					header={'Bulk upload devices'}
					type={'device'}
					columnNames={['device_name', 'device_number', 'device_type']}
					route={'/api/v1/devices'}
				/>
			)}
			<div className='container-fluid'>
				<div className='row page-titles'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
						<div className='card CardNoBorder' id='PersonForm'>
							<div className='card-header card_headertitle'>
								<div className='card-title card_head'>
									<div className='row d-flex align-items-center  '>
										<div className=' col-lg-2 col-md-2 col-sm-6 col-6'>
											<h4 className='card_head CardTitle text-uppercase component-heading  '>
												{intl.devices}
											</h4>
										</div>
										<div className=' col-lg-5 col-md-6 col-sm-8 col-8 d-flex'>
											<input
												type='search'
												placeholder={intl.searchButton}
												className='w-100 search-input'
												style={
													searchText.length < 1
														? {
																borderTopRightRadius: '0.5em',
																borderBottomRightRadius: '0.5em',
														  }
														: {
																borderTopRightRadius: '0em',
																borderBottomRightRadius: '0em',
														  }
												}
												value={searchText}
												onKeyDown={(e) => {
													if (e.keyCode === 13) {
														setSearchTerm(searchText);
														setCurrentPage(1);
													}
												}}
												onChange={(e) => setSearchText(e.target.value)}
											/>

											{searchText.length > 0 ? (
												<span
													className='cancel-container'
													onClick={() => {clearSearch();
													}}	>
													<FaTimes id='btnClear' className='cancel-icon' />
												</span>
											) : (
												''
											)}
										</div>
										<div className=' col-lg-1 col-md-2 col-sm-4 col-4 d-flex'>
											<div className='float_left mr-3'>
												<input
													type='submit'
													className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
													value={intl.searchButton}
													id='PersonBtn'
													onClick={(e) => {
														setSearchTerm(searchText);
														setCurrentPage(1);
														logEvent({category:Categories.device,action:Actions.device_search_btn});
											
													}}
												/>
											</div>
											
										</div>

										{/* <div className=' col-lg-1 col-md-2 col-sm-6 col-6 bulk_buttons_div'>
											<p
												title='Add Staff'
												className='btn AddButton btn-sm bulk_buttons'
												onClick={() => history.push("/addDevice")}
											>
												<i>
													<FaPlus />
												</i>{" "}
												{intl.addDeviceButton}
											</p>
										</div> */}
										<div className=' col-lg-4 col-md-6 col-sm-12 col-12 bulk_buttons_div  '>
											<span												title='Add Device'
												className='btn AddButton btn-sm bulk_buttons'
												onClick={() => {history.push('/addDevice');	logEvent({category:Categories.device,action:Actions.device_add_btn});
											}}
											>
												<i>
													<FaPlus />
												</i>{' '}
												{intl.addDeviceButton}
											</span>
											<span												title='Add in Bulk'
												className='btn AddButton btn-sm bulk_buttons ml-2 mr-2'
												onClick={() => {setShowModal(true);logEvent({category:Categories.device,action:Actions.device_bulk_upload_btn});}}
											>
												<i>
													<FaPlus />
												</i>{' '}
												{intl.bulkuploadButton}
											</span>
											{userList.length > 0 && <span	title='Delete'
												className='btn AddButton btn-sm bulk_buttons'
												onClick={() =>
													setHandleDelete(true)
												}
											>
												<i>
													<FaTrashAlt />
												</i>{'  '}
												{intl.deleteButton}
											</span>}
											{/* <span												title='Add Staff'
												className='float_right mr-3 mt-1 d-flex align-items-center'
												onClick={() => setHandleDelete(true)}
											>
												<FaTrashAlt
													className='iconAction-danger'
													title='Delete'
													onClick={() => {
														// setDeviceId(device._id);
														// setShowModal(true);
													}}
												/>
											</span> */}
										</div>
									</div>
								</div>
							</div>
							<div className='card-body DataTable PersonGrid'>
								<DevicesList
									searchTerm={searchTerm}
									currentPage={currentPage}
									changePage={(page) => changePage(page)}
									bulkModal={showModal}
									handleDelete={handleDelete}
									setHandleDelete={() => setHandleDelete(false)}
									clearSearch={clearSearch}
									checkUserLength={checkUserLength}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Devices;
