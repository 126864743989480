import React from "react";
import Modal from "react-bootstrap/Modal";
import intl from "../../locale/en-US.json";
import { Spinner } from "react-bootstrap";
const AlertModal = ({ closeModal, header, body, handlerFunction, loading }) => {
	return (
		<div
			className='modal show'
			style={{ display: "block", position: "initial" }}
		>
			<Modal show={true} onHide={closeModal}>
				<Modal.Header >
					<Modal.Title>{header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>{body}</h4>
				</Modal.Body>
				<Modal.Footer>
					<button
						type='button'
						className='btn btn-secondary'
						data-bs-dismiss='modal'
						onClick={() => closeModal()}
					>
						{intl.cancelButton}
					</button>

					<div className='float_left'>
						<button
							onClick={() => handlerFunction()}
							type='submit'
							className={body.includes('Proceeding with the deletion of the account') ? 
								"btn ActionButton PersonBtnadd PersonBtnloader button-fixed schoolDeleteButton" : 
								"btn ActionButton PersonBtnadd PersonBtnloader button-fixed"} 
							id='PersonBtn'
						>{loading ? <Spinner size="sm" animation="border" variant="secondary" /> : intl.confirmButton}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default AlertModal;
