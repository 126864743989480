import React from "react";
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: for the blur effect
import {baseurl} from "../../api";
import { Popup } from "semantic-ui-react";
import { FaInfoCircle } from "react-icons/fa";
import { getExpiryColor } from "../../utils/common";
import intl from '../../locale/en-US.json'
import { logEvent,Categories,Actions } from "../../analytics";

const SchoolItem = ({ history, school }) => {

	
 	const expiryColorClass = getExpiryColor(school.expiry_date);
	const whiteImageSVG = 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="120" height="120"%3E%3Crect width="100%" height="100%" fill="%23fff" /%3E%3C/svg%3E';
	return (
		<div
			className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 filterSchool"
			id="customResponsive"
			onClick={() => {history.push(`/organizationdetails/${school._id}`);logEvent({category:Categories.school,action:Actions.school_details_btn});}}
		>
			<div className='card card_hover'>
				<div className='card-body school_card cursor '>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 school-card'>
						<div className='row d-flex justify-content-center align-items-center flex-row '>
							<div className='col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2  p-0 school_desc' >
								<div className='card school-logo '>
									<span onClick={() => {history.push(`/organizationdetails/${school._id}`); 
									}}
									>
										<center>
										
										<LazyLoadImage
											src={`${baseurl}/${school.logo_file}`}
											
											className="img-responsive LogoWidth"
											alt="Organization Logo"
											onError={(e) => {
												e.target.src = whiteImageSVG;
											}}
											effect="blur"
										/>

										</center>
									</span>
								</div>
							</div>
							<div className='col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 school_desc '>
								<div className=''>
									<div className=''>
										<h5 className='ml-2 card-text School_text school_name'>
											{school.name}
										</h5>
									</div>
									<div className='mb-2'>
										<p className='small-text ml-3 card-text school_expiry_date'>
											{school.email.length > 0 ? school.email[0]: "Email unavaliable"}
										</p>
									</div>
									<div className=''>
										<p className={`small-text ml-3 card-text school_expiry_date ${expiryColorClass}`}>
											{school.expiry_date ? new Date(school.expiry_date).toLocaleString() : 'Invalid Expiry'}
										</p>
									</div>
									
									<div className=' d-flex justify-content-between'>
										<strong className={`small-text ml-3 card-text ${!school.is_blocked ? 'text-success' : 'text-danger'}`}>
											{!school.is_blocked ? intl.unblocked : intl.blocked}
										</strong>
										<span>
										
										<Popup
										position='top center'
										trigger={<FaInfoCircle onClick={(e) => e.stopPropagation()} className="float-right" />}
										>
										<div className="pl-2 small-text">
											<strong>{school.name}</strong>
											<table className="">
											<tbody>
												<tr>
												<td className="pr-2">{intl.totalAlerts}:</td>
												<td align="left"><strong>{school.deviceAlertsCount}</strong></td>
												</tr>
												<tr>
												<td className="pr-2">{intl.badgesRegistered}:</td>
												<td align="left"><strong>({school.badgeCount} / { school.allowed_badges})</strong></td>
												</tr>
												<tr>
												<td className="pr-2">{intl.devicesRegistered}:</td>
												<td align="left"><strong>({school.deviceCount} / {school.allowed_devices})</strong></td>
												</tr>
												<tr>
												<td className="pr-2">{intl.teachersRegistered}:</td>
												<td align="left"><strong>({school.teacherCount} / {school.allowed_students})</strong></td>
												</tr>
												<tr>
												<td className="pr-2">{intl.studentsRegistered}:</td>
												<td align="left"><strong>({school.studentCount} / {school.allowed_teachers})</strong></td>
												</tr>
											</tbody>
											</table>
										</div>
										</Popup>
										</span>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(SchoolItem);
