import React, { useState, useEffect, useRef } from 'react';

import { withRouter } from 'react-router-dom';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import apiInstance from '../../api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Strings from '../../locale/en-US.json';
import { maxLength } from '../../utils/apiUtils';
import { Spinner } from 'react-bootstrap';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import AlertModal from '../Modal/AlertModal';
import { Categories, logEvent, Actions } from "../../analytics";

const AddPlan = ({ history, location }) => {
	const { state } = location;
    const data = state && state.data ? state.data : null;

    const [name, setName] = useState("");
 	const [staff, setStaff] = useState('');
 	const [trialperiod, settrialperiod] = useState(0);
	const [students, setStudents] = useState('');
	const [badges, setBadges] = useState('');
	const [devices, setDevices] = useState('');
    const [isDefault, setIsDefault] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
 	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const isAnyInputFocused = useInputFocus();
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);
	const handleFormChange = async (event) => {
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	}

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
		  	}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);
	  

	const closeUnsavedModal = () => setShowUnsavedModal(false);
	
	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
				return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		}
		else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	  const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	  };

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);
	
		return () => {
		  unblock();
		};
	  }, [history]);
	
	useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);

	useEffect(() => {
        if (data) {
            setStaff(data.allowed_teachers)
			settrialperiod(data.trial_period)
            setStudents(data.allowed_students)
            setBadges(data.allowed_badges)
            setDevices(data.allowed_devices)
            setName(data.license_type)
            setIsDefault(data.is_default)
        }
	}, []);


	const handleSubmit = async (event) => {
		event.preventDefault();
		if(data){
			logEvent({category:Categories.plan,action:Actions.plan_edit_submit_btn});
		 
		 }else{
			logEvent({category:Categories.plan,action:Actions.plan_add_submit_btn});
		 }
		try {
			setLoading(true);
            setErrorMsg("");
			const updateSchoolData = new FormData();
 			updateSchoolData.append('allowed_teachers', staff);
 			updateSchoolData.append('trial_period', trialperiod);
			updateSchoolData.append('allowed_students', students);
			updateSchoolData.append('allowed_badges', badges);
			updateSchoolData.append('allowed_devices', devices);
 			updateSchoolData.append('license_type', name);
            updateSchoolData.append('is_default', isDefault);
            if (data !== null && data._id) {
                
                if (!submitted) {
                    setSubmitted(true)
                    const response = await apiInstance.put(`/api/v1/plans/${data._id}`, updateSchoolData)
                    setLoading(false);
                    if (response && response.status === 200) {
						allowNavigationRef.current = true;
						logEvent({category:Categories.plan,action:Actions.plan_edit_submit_success});
                        toast.success('Successfully Saved!', {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                        });
    
                        setTimeout(()=>{
                            history.push('/plan');
                        }, 2000)
                    } else {
                        setSubmitted(false)
                    }
                }
            } else {
                // new plan add
                //updateSchoolData.append('trial_period', trialperiod);
                 if (!submitted) {
                    setSubmitted(true)
                    const response = await apiInstance.post(`/api/v1/plans`, updateSchoolData)
                    
                    setLoading(false);
                    if (response && response.status === 200) {
						logEvent({category:Categories.plan,action:Actions.plan_add_submit_success});
                       allowNavigationRef.current = true;
                        toast.success('Successfully Saved!', {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                        });
    
                        setTimeout(()=>{
                            history.push('/plan');
                        }, 2000)
                    } else {
                        setSubmitted(false)
                    }
                }
            }	
		
		}
		
		catch (error) {
			setSubmitted(false);
			setLoading(false);
			const result = errorFunction(error);
			//console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMsg(result);
			}
		}
	};

	return (
		<>
			<div>
				<Breadcrumb
					links={[
						{ link: '/plan', title: intl.planList },
						data
							? { link: '', title: 'Edit Plan' }
							: { link: '', title: intl.addPlan },
					]}
					
				/>

				<div className='card-header card_headertitle'>
					<div className='card-title card_head'>
						{data ? (
							<h4 className='card_head CardTitle text-uppercase component-heading  '>
								{intl.editPlan}
							</h4>
						) : (
							<h4 className='card_head CardTitle text-uppercase component-heading  '>
								{intl.addPlan}
							</h4>
						)}
					</div>
					{errorMsg && (
						<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
							{errorMsg}
						</div>
					)}
				</div>

				<div className='card-body' style={{ background: '#fff' }}>
					<form id='PersonSaveForm' onChange={handleFormChange} onSubmit={handleSubmit} action=''>
                        
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>
                                            {intl.licenseName}
                                            <span className='font_red'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={name}
                                            onChange={(event) =>
                                                {formChanged.current =true
												setName(maxLength(event.target.value, 50))}
                                            }
                                            required
                                            autoFocus
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className='row'>
							<div className='col-lg-6 col-md-12 col-sm-12 col-12'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.validDays}
											<span className='font_red'>*</span>
										</label>
										<input
											type='number'
											className='form-control'
											name='LastName'
											value={trialperiod}
											onChange={(event) =>
												{formChanged.current =true
												settrialperiod(maxLength(event.target.value, 6))}
											}
											required
										/>
									</div>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.staffCount}
											<span className='font_red'>*</span>
										</label>
										<input
											type='number'
											className='form-control'
											name='FirstName'
											value={staff}
											onChange={(event) =>
												{formChanged.current =true
												setStaff(maxLength(event.target.value, 6))}
											}
											required
 										/>
									</div>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.studentCount}
											<span className='font_red'>*</span>
										</label>
										<input
											type='number'
											className='form-control'
											name='LastName'
											value={students}
											onChange={(event) =>
												{formChanged.current =true
												setStudents(maxLength(event.target.value, 6))}
											}
											required
										/>
									</div>
								</div>
							   
							</div>
						</div>

                        <div className='row'>
							<div className='col-lg-6 col-md-12 col-sm-12 col-12'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.badgesCount}
											<span className='font_red'>*</span>
										</label>
										<input
											type='number'
											className='form-control'
											name='FirstName'
											value={badges}
											onChange={(event) =>
												{formChanged.current =true
												setBadges(maxLength(event.target.value, 6))}
											}
											required
 										/>
									</div>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.devicesCount}
											<span className='font_red'>*</span>
										</label>
										<input
											type='number'
											className='form-control'
											name='LastName'
											value={devices}
											onChange={(event) =>
												{formChanged.current =true
												setDevices(maxLength(event.target.value, 6))}
											}
											required
										/>
									</div>
								</div>
							</div>
						</div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>
                                            {intl.defaultPlan}
                                            <span className='font_red'>*</span>
                                        </label>
                                        <br />
                                        <div style={{paddingLeft:"1em"}}>
                                            <input type="radio" id="true" checked={isDefault === true} value="true" onChange={()=> {formChanged.current =true;setIsDefault(true)}}/>
                                            <label htmlFor="true">True</label> 
                                            <input type="radio" id="false" checked={isDefault === false} value="false" onChange={()=>{formChanged.current =true;setIsDefault(false)}}/>
                                            <label style={{marginLeft:'1em'}} htmlFor="false">False</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float_right mr-3'>
									<div align='center'>
										<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading ? <Spinner size='sm' /> : Strings.submitButton}
										</button>
									</div>
								</div>
								 
								<div className='float_right mr-3'>
									<span										className='btn btn-secondary cancel-btn button-fixed'
										onClick={(event) =>{
											event.preventDefault(); 
											history.goBack();
											if(data){
											logEvent({category:Categories.plan,action:Actions.plan_edit_cancel_btn});
											}else{
												logEvent({category:Categories.plan,action:Actions.plan_add_cancel_btn});
											}
										}}
									>
										{intl.cancelButton}
									</span>
								</div>
							</div>
						</div>
					</form>
				</div>
				{showModal2 ? <LogoutModal /> : null}
				{showUnsavedModal ? (
						<AlertModal
							closeModal={closeUnsavedModal}
							header={Strings.unsavedChanges}
							body={Strings.unsavedChangesText}
							handlerFunction={handleNavigationConfirmation}
						/>
					) : null}
				<ToastContainer />
			</div>
		</>
	);
};
export default withRouter(AddPlan);
