import React from "react";
import Modal from "react-bootstrap/Modal";
import intl from "../../locale/en-US.json";
import VerifyPhone from '../verifyphone/VerifyPhone';

const VerifyPhoneModal = ({ closeModal ,currentphno}) => {
    // { closeModal, header, body, handlerFunction }
	return (
		<div
			className='modal show'
		>
			<Modal show={true} onHide={closeModal}>
                <VerifyPhone closeModal={closeModal} currentphno={currentphno}/>
				<Modal.Footer>
					<button
						type='button'
						className='btn btn-light'
						data-bs-dismiss='modal'
						onClick={() => closeModal()}
					>
						{intl.cancelButton}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default VerifyPhoneModal;
