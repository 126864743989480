import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history"; 
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production') {
	//console.log("HERE***9");
	const baseurl = `${window.location.origin}`;
	Sentry.init({
	  dsn: "https://c0b6693b067a84867cf58828eda84acd@o4505956508237824.ingest.sentry.io/4505957104353280",
	  integrations: [
		new Sentry.BrowserTracing({
		  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		  tracePropagationTargets: [baseurl],
		}),
		new Sentry.Replay(),
	  ],
	  // Performance Monitoring
	  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	  // Session Replay
	  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
  }
  
const history = createBrowserHistory();
ReactDOM.render(
		<BrowserRouter history={history}>
			<App />
		</BrowserRouter>,

	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
