import React from 'react';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import{Actions, Categories, logEvent} from "../../analytics";

const Privacy = () => {

    // const openPage = (links) => {
    //     const link = window.location.origin + links
    //     console.log('----------- ', link)
    //     window.open(link, '_blank')
    // }
	return (
		<div>
			<Breadcrumb links={[{ link: '/privacy', title: intl.privacySecurity }]} />
			<div className='card-header card_headertitle'>
				<div className='card-title card_head'>
					<h4 className='card_head CardTitle text-uppercase component-heading  '>
						Privacy and Security
					</h4>
				</div>
			</div>
			<div className='card-body' style={{ background: '#fff' }}>
				<ul>
					<li onClick={()=>{logEvent({category:Categories.accountSettings,action:Actions.EULA_btn}) }}>
						<a
							href={window.location.origin + '/privacy_pages/user-agreement.html'}
							target='_blank'
							rel='noopener noreferrer'
						>
							End User License Agreement
						</a>
					</li>
					<li onClick={()=>{logEvent({category:Categories.accountSettings,action:Actions.privacy_policy_btn}) }}>
						<a
							href={window.location.origin + '/privacy_pages/privacy-policy.html'}
							target='_blank'
							rel='noopener noreferrer'
						  >
							Privacy Policy
						</a>
					</li>
					{/* <li >
						<a
							href={window.location.origin + '/privacy_pages/privacy-policy.html'}
							target='_blank'
							rel='noopener noreferrer'
						>
							Terms and Conditions
						</a>
					</li> */}
				</ul>
			</div>
		</div>
	);
};

export default Privacy;
