import React, { useEffect, useState } from 'react';
import AllImages from '../../assets/images/images.js';
import { withRouter } from 'react-router-dom';
import {
	getRoleInStore,
	getUserBlockedStore,
	getPhoneNumberInStore,
} from '../../utils/apiUtils';
import { version } from '../../../package.json';
import VerifyPhoneModal from '../Modal/VerifyPhoneModal';
import strings from '../../locale/en-US.json';
//import { Categories, logEvent, Actions } from "../../analytics";

const Header = (props) => {
	const { history, location } = props;
	const role = getRoleInStore();
	const blocked = getUserBlockedStore();
	const blockedByAdmin = getUserBlockedStore();
	const [phoneNumber, setPhoneNumber] = useState('');
	const [showVerifyModal, setShowVerifyModal] = useState(false);
	const [showVerifyBtn, setShowVerifyBtn] = useState(false);


	useEffect(() => {
		getPhoneNumber();
	}, [location.pathname, showVerifyBtn]);

	const getPhoneNumber = () => {
		const phone = getPhoneNumberInStore();
		if (phone) {
			setShowVerifyBtn(true);
			setPhoneNumber(phone);
		} else {
			setShowVerifyBtn(false);
		}
	};

	const closePhoneModal = () => {
		setShowVerifyModal(false);
		getPhoneNumber();
	};

	// useEffect(() => {
	// 	pollAPI();
	// 	// Check if the browser tab is focused or not
	// 	const handleVisibilityChange = () => {
	// 		if (document.hidden) {
	// 		stopPolling();
	// 		} else {
	// 		startPolling();
	// 		}
	// 	};

	// 	// Add event listener for visibility change
	// 	document.addEventListener("visibilitychange", handleVisibilityChange);

	// 	// Clean up the event listener when the component unmounts
	// 	return () => {
	// 		document.removeEventListener("visibilitychange", handleVisibilityChange);
	// 	};
	// }, []);

	// const startPolling = () => {
	// 	if (id) {
	// 		if (!pollingIntervalRef.current) {
	// 		pollingIntervalRef.current = setInterval(pollAPI, 10000);
	// 		}
	// 	}
	// };

	// const stopPolling = () => {
	// 	if (pollingIntervalRef.current) {
	// 	clearInterval(pollingIntervalRef.current);
	// 	pollingIntervalRef.current = null;
	// 	}
	// };

	// const pollAPI = async () => {
	// 	try {
	// 		const result = await apiInstance.get(`/api/v1/users/${id}`);
	// 		if (result && result.data) {
	// 			setShowVerifyBtn(result.data.user.phone_verification_code && result.data.user.phone_verification_code)
	// 			if (!(result.data.user.phone_verification_code && result.data.user.phone_verification_code)) {
	// 				stopPolling();
	// 			}
	// 		}
	// 	} catch (error) {
	// 		setShowVerifyBtn(false);
	// 	}
	// };


	return (
		<div>
			{showVerifyModal && (
				<VerifyPhoneModal
					closeModal={closePhoneModal}
					currentphno={phoneNumber}
				/>
			)}
			<header className='topbar'>
				<div className='header_div'>
					{/* <div className=' logo_div '> */}
					<div className='navbar top-navbar navbar-expand-md navbar-light col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12'>
						<div className='navbar-header'>
							{/* {props.userRole === 'SchoolAdmin' && props.notificationStatus === 'false' && <span className='notification-dot'></span>} */}
							<i id='sidebarCollapse' onClick={(event) => {
								props.onClick(event);
								}}>
								{/* <svg
									stroke='currentColor'
									fill='currentColor'
									strokeWidth='0'
									viewBox='0 0 448 512'
									className='fa fa-bars mr-2 menu_style hamburger_icon_align'
									id='sidebarCollapse'
									height='1em'
									width='1em'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'
										id='hamburger'
									></path>
								</svg> */}
								<img
									style={{ width: '25px', marginLeft: '0.3em' }}
									className='fa fa-bars mr-2 menu_style hamburger_icon_align'
									id='hamburger'
									alt='Hamburger icon'
									src='data:image/svg+xml;base64,PHN2ZyBzdHJva2U9IiM1MjUyNTIiIGZpbGw9IiM1MjUyNTIiIHN0cm9rZS13aWR0aD0iMCIgdmlld0JveD0iMCAwIDQ0OCA1MTIiIGNsYXNzPSJmYSBmYS1iYXJzIG1yLTIgbWVudV9zdHlsZSBoYW1idXJnZXJfaWNvbl9hbGlnbiIgaWQ9InNpZGViYXJDb2xsYXBzZSIgaGVpZ2h0PSIxZW0iIHdpZHRoPSIxZW0iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDEzMmg0MTZjOC44MzcgMCAxNi03LjE2MyAxNi0xNlY3NmMwLTguODM3LTcuMTYzLTE2LTE2LTE2SDE2QzcuMTYzIDYwIDAgNjcuMTYzIDAgNzZ2NDBjMCA4LjgzNyA3LjE2MyAxNiAxNiAxNnptMCAxNjBoNDE2YzguODM3IDAgMTYtNy4xNjMgMTYtMTZ2LTQwYzAtOC44MzctNy4xNjMtMTYtMTYtMTZIMTZjLTguODM3IDAtMTYgNy4xNjMtMTYgMTZ2NDBjMCA4LjgzNyA3LjE2MyAxNiAxNiAxNnptMCAxNjBoNDE2YzguODM3IDAgMTYtNy4xNjMgMTYtMTZ2LTQwYzAtOC44MzctNy4xNjMtMTYtMTYtMTZIMTZjLTguODM3IDAtMTYgNy4xNjMtMTYgMTZ2NDBjMCA4LjgzNyA3LjE2MyAxNiAxNiAxNnoiPjwvcGF0aD48L3N2Zz4='
								></img>
							</i>

							<b>
								<span
									onClick={() => {
										if (!blocked) {
											if (role === 'SuperAdmin') {
												history.push('/organizations');
											} else {
												history.push('/dashboard');
											}
										}
									}}
								>
									<img
										src={AllImages["logo"]}
										alt='homepage'
										className='light-logo logo_align img-responsive hover-pointer'
									/>
								</span>
							</b>
						</div>
						<div
							style={{
								fontSize: 'xx-small',
								marginTop: '35px',
								marginLeft: '10px',
							}}
						>
							<p style={{ width: '3.5em', fontSize: '0.8rem' }}>
								v {version}
							</p>
						</div>
					</div>
					{/* </div> */}
					<div className='pr-4 verify_text_div col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12'>
						{showVerifyBtn && !blockedByAdmin ? (
							<div className='float_right'>
								<span
									className='login_contact blackText hover-pointer sm-font'
									style={{ backgroundColor: '#F2F2F2' }}
									onClick={() => setShowVerifyModal(true)}
								>
									{role === 'SuperAdmin'
										? strings.verifyPhonenoAdmin
										: strings.verifyPhoneno}
								</span>
							</div>
						) : null}
					</div>
				</div>
			</header>
			<div className='header-height'></div>
		</div>
	);
};
export default withRouter(Header);
