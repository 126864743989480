import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import LogoutModal from '../Modal/LogoutModal';
import { FaTimes } from 'react-icons/fa';
import { Pagination } from 'semantic-ui-react';
import apiInstance from '../../api';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import { errorFunction } from '../../utils/errors';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { FaTrashAlt } from "react-icons/fa";
import { logEvent,Categories,Actions } from "../../analytics";
const PAGE_OFFSET = 15;

const AssignStudents = ({location }) => {
 	const { data } = location.state;
 	const divRef = useRef();
	const isFirstRender = useRef(true);

	const [assigned, setAssignedStudents] = useState([]);
	const [unassigned, setUnassignedStudents] = useState([]);
	const [activePage1, setActivePage1] = useState(1);
	const [activePage2, setActivePage2] = useState(1);
	const [totalPages1, setTotalPages1] = useState(1);
	const [searchTerm, setSearch] = useState('');
	const [studentCount, setStudentCount] = useState(0);
	const [stdIndex, setStdIndex] = useState(99);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [addStd, setAddStd] = useState(false);
	const [count, setCount] = useState(0)
	const [showModal2, setShowModal2] = useState(false);

	useEffect(() => {
		function handleClickOutside(event) {
			if (event.target.id === 'icon') {
				return;
			}
			if (
				!(event.target.id === 'AddStudent' || event.target.id === 'icon') &&
				divRef.current &&
				!divRef.current.contains(event.target)
			) {
				setAddStd(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [divRef]);

	useEffect(()=>{
		getAssignedStudents();
	},[]) 

	useEffect(()=>{
		getUnassignedStudents();
	},[activePage2])

	const getAssignedStudents = async (page) => {
		setLoading(true);
		try {
			const params = {
				page: page ? page : activePage1,
				limit: PAGE_OFFSET,
			};
			if (searchTerm) {
				params.search = document.getElementById('searchName').value.trim();
			}
			await apiInstance
				.get(
					`/api/v1/users?role=${intl.roleStudent}&teacher_id=${data._id}`, {
						params
					}
				)
				.then((response) => {
					showError('');
					setAssignedStudents(response.data.data.users);
					setCount(response.data.data.totalUsers)
					setStudentCount(response.data.data.users.length);
					setTotalPages1(response.data.data.totalPages);
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
			const result = errorFunction(error);

			// console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	const refreshStudentList =  () => {
		// setLoading(true);
		let page = assigned.length -1 === 0 ? 1 : activePage1;
		setActivePage1(page);
		try {
			const params = {
				page: assigned.length -1 === 0 ? 1 : activePage1,
				limit: PAGE_OFFSET,
			};
			if (searchTerm) {
				params.search = document.getElementById('searchName').value.trim();
			}
				apiInstance
				.get(
					`/api/v1/users?role=${intl.roleStudent}&teacher_id=${data._id}`,{
						params
					}
				)
				.then((response) => {
					showError('');
					setAssignedStudents(response.data.data.users);
					setStudentCount(response.data.data.users.length);
					setTotalPages1(response.data.data.totalPages);
					setCount(response.data.data.totalUsers)
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
			const result = errorFunction(error);
			//console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};
	
	
	const getUnassignedStudents = async (page) => {
		try {
			await apiInstance
				.get(
					`/api/v1/users?role=${intl.roleStudent}&teacher_id&page=${activePage2}&limit=50`
				)
				.then((response) => {
					showError('');
					setUnassignedStudents([...unassigned, ...response.data.data.users]);
					// setTotalPages2(response.data.data.totalPages);
				});
		} catch (error) {
			const result = errorFunction(error);
			//console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};
	
	React.useEffect(() => {
		if (!isFirstRender.current && searchTerm === '') {
			getAssignedStudents(1);
			getUnassignedStudents(1);
		}
		isFirstRender.current = false;
	}, [searchTerm]);

	const getAssignedSearch = async () => {
		try {
			if (searchTerm === '') return;
			setLoading(true);
			await apiInstance
				.get(
					`/api/v1/users?role=${intl.roleStudent}&teacher_id=${data._id}&page=1&limit=${PAGE_OFFSET}&search=${document.getElementById('searchName').value.trim()}`
				)
				.then((response) => {
					showError('');
					setLoading(false);
					setStudentCount(response.data.data.users.length)
					setAssignedStudents(response.data.data.users);
					setTotalPages1(response.data.data.totalPages);
					setCount(response.data.data.totalUsers)
					setActivePage1(1);
				});
		} catch (error) {
			setLoading(false);
			const result = errorFunction(error);

			//console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	const onChange1 = (e, pageInfo) => {
		setActivePage1(pageInfo.activePage);
		getAssignedStudents(pageInfo.activePage);
	};
	
	// removing student
	const handleUnassignStudent = async (student) => {		
		setLoading2(true);
		logEvent({category:Categories.staff,action:Actions.staff_unassign_btn});
		try {
			const result = await apiInstance.put(`/api/v1/users/${data._id}/bind`,{
				unassign: [student],
			})
			if (result.status === 200) {
				setLoading2(false);
				logEvent({category:Categories.staff,action:Actions.staff_unassign_success});
				setStdIndex(99);
				const studentObj = assigned.find((std) => std._id === student._id);
				setUnassignedStudents([...unassigned, studentObj]);
				setAssignedStudents(assigned.filter((e) => e._id !== student._id));
				if (assigned.length === 1 && searchTerm !== '') onClear();
				else refreshStudentList();
				toast.success('Successfully Removed!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				

			}
		} catch (error) {
 			const result = errorFunction(error);
			 setLoading2(false);
			//console.log('errro-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	const handleAssignStudent = async (student) => {
		logEvent({category:Categories.staff,action:Actions.staff_assign_add_btn});
		try {
			setLoading2(true);
			const result = await apiInstance.put(`/api/v1/users/${data._id}/bind`,{
				assign: [student],
			})
			
			if (result.status === 200) {
				setLoading2(false);
				logEvent({category:Categories.staff,action:Actions.staff_assign_add_success});
				setStdIndex(99);
				let unassignedStudentList = unassigned.filter((e) => e._id !== student._id)
				setUnassignedStudents(unassignedStudentList);
				refreshStudentList();
				if (unassignedStudentList.length === 0) setAddStd(false);

				toast.success('Successfully Added!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});

				

			}
		} catch (error) {
 			const result = errorFunction(error);
			setLoading2(false);
			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	const showError = (result) => {
		setErrorMessage(result);
		if (result) {
			window.scrollTo(0, 0);
		}
	};

	const onClear = async () => {
		setSearch("");
		setActivePage1(1);
		setTotalPages1(1);
		setTimeout(() => {
			setSearch("");
		}, 200);
		
	}

	const loadMoreStudents = () =>{
		setActivePage2(prev => prev+1)
	}
	
	return (
		<div>
			<Breadcrumb
				links={[
					{ link: '/responder', title: intl.staffList },
					{ link: '', title: `${data.first_name} ${data.last_name}` },
				]}
	
			/>

			<div className='row'>
			{errorMessage && (
				<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
					{errorMessage}
				</div>
			)}
				<div className='col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mb-2'>
 					<div
						className='AddStdButton btn btn-sm'
						id='AddStudent'
						onClick={() => setAddStd(!addStd)}
						style={{ marginLeft: '-14px' }}
					>
						{intl.assignStudents}
						<i>
							<svg
								stroke='currentColor'
								fill='currentColor'
								id='icon'
								strokeWidth='0'
								viewBox='0 0 320 512'
								height='1em'
								width='1em'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									id='icon'
									d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'
								></path>
							</svg>
						</i>{' '}
					</div>
				</div>
			</div>	

			
			{addStd ? (
				<div style={{position:"relative" }}>
					<div
						ref={divRef}
						className="assign_div"
					>
						<InfiniteScroll
							dataLength={unassigned.length}
							next={loadMoreStudents}
							hasMore={true}
							// loader={unassigned.length > 0 && <p>Loading...</p>}
							endMessage={<p>No more data to load.</p>}
							height={unassigned.length === 0 ? "2em" : "20em"}
							>
							{unassigned.length === 0 ? <h5>{intl.noStudentsFound}</h5> : unassigned.map((student, i) => {
							return (
								<div
									className='d-flex justify-content-between align-items-center my-1 mb-2'
									key={i}
								>
									<h6 className='long_name'>
										{student.first_name} {''} {student.last_name}{' '}
									</h6>
									<button
										type='button'
										className=' AddStdButton btn btn-sm'
										style={{padding:'0.5em 1em'}}
										onClick={() => {
											if (!loading2 && stdIndex !== student._id) {
												handleAssignStudent(student);
												setStdIndex(student._id);
											}
										}}
									>
										{loading2 && stdIndex === student._id ? (
											<Spinner size='sm' />
										) : (
											intl.add
										)}
									</button>
								</div>
							);
						})}
						</InfiniteScroll>
						
					</div>
				</div>
			) : null}

			<div className=' '>
				<div className='row page-titles'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
						<div className='card CardNoBorder' id='PersonForm'>
							<div className='card-header card_headertitle'>
								<div className='row d-flex align-items-center'>
									<div className='mt-2 col-lg-3 col-md-12 col-sm-12 '>
										<h4 className='card_head CardTitle text-uppercase component-heading  '>
											{intl.assignedStudents}
										</h4>
									</div>
									<div className='mt-2 col-lg-7 col-md-12 col-sm-12 d-flex'>
										<input
											id='searchName'
											type='search'
											placeholder={intl.searchButton}
											className='w-100 search-input'
											value={searchTerm}
											onKeyDown={(e) => {
												if (e.keyCode === 13) {
													getAssignedSearch()
												}
											  }}
											// ref={searchInputRef}
											style={searchTerm.length > 1 ? {borderRight: '0px solid red', borderTopRightRadius: '0em', borderBottomRightRadius:'0em'} : null}
											onChange={(e) => setSearch(e.target.value)}
										/>
										{searchTerm.length > 1 ? <span className='cancel-container' onClick={() => {onClear();
			}}>
											<FaTimes
												id='btnClear'
												className='cancel-icon'
											/>
										</span> : null }
									</div>
									<div
										className='mt-2 col-lg-2 col-md-12 col-sm-12'
										style={{ position: 'relative', height: '3em' }}
									>
										<input
											onClick={() => {(searchTerm.length > 1 && getAssignedSearch());logEvent({category:Categories.staff,action:Actions.staff_assign_search_btn});}}
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed addstaff-search'
											value={intl.searchButton}
											id='PersonBtn'
											type='button'
										/>
									</div>
								</div>
							</div>

							<div className='card-body DataTable PersonGrid'>
								<div className='table-responsive'>
									{loading ? (
										<div className="d-flex justify-content-center align-items-center">
											<Spinner animation="border" variant="secondary" />
										</div>
									) : (
										<table className='table table-bordered'>
											<thead>
												<tr>
													<th scope='col'>{intl.Name}</th>
													{/* <th scope='col'>{intl.gender}</th> */}
													<th scope='col'>{intl.badge}</th>
													<th scope='col' style={{width:'10%'}}>{intl.actions}</th>
												</tr>
											</thead>
											<tbody>
												{assigned && assigned.length ? (
													assigned.map((assignedStudent, i) => {
														return (
															<tr key={i}>
																<td>
																	{assignedStudent.first_name}{' '}
																	{assignedStudent.last_name}
																</td>
																{/* <td>{assignedStudent.gender}</td> */}
																<td>
																	{assignedStudent.mac_address
																		? assignedStudent.mac_address
																		: '-'}
																</td>
																<td>
																	<div>
																		<div>
																			{loading2 &&
																			stdIndex === assignedStudent._id ? (
																				<Spinner size='sm' animation="border" variant="secondary" />
																			) : (
																				<FaTrashAlt
																					className='iconAction-danger'
																					title='Delete'
																					onClick={() => {
																						if (
																							!loading2 &&
																							stdIndex !== assignedStudent._id
																						) {
																							handleUnassignStudent(assignedStudent);
																							setStdIndex(assignedStudent._id);
																						}
																					}}
																				/>
																			)}
																		</div>
																	</div>
																</td>
															</tr>
														);
													})
												):<tr>
												<td colSpan='4' style={{ textAlign: 'center' }}>
													{intl.noRecordFound}
												</td>
											</tr>}
											</tbody>
										</table>
									)}
									{studentCount > 0 ? (
										<div className='count_div'>
											<Pagination
												boundaryRange={1}
												ellipsisItem="..."
												firstItem={null}
												lastItem={null}
												activePage={activePage1}
												onPageChange={onChange1}
												siblingRange={1}
												totalPages={totalPages1}
												className={totalPages1 === 1 ? "paginationbutton" : null}
											/>
											<div>Total assigned users: {count}</div>
										</div>
									) : (
										''
									)}
									<ToastContainer />
								</div>
							</div>
						</div>
					</div>
				</div>
				{showModal2 ? <LogoutModal /> : null}
			</div>
		</div>
	);
};

export default withRouter(AssignStudents);
