import React from "react";
import { FaTimes, FaPlus } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import apiInstance from "../../api";
import SchoolItem from "./SchoolItem";
import intl from "../../locale/en-US.json";
import { SCHOOLS_ETAG_KEY, deleteEtag, getEtag, getRoleInStore, maxLength, setEtag } from "../../utils/apiUtils";
import { errorFunction } from '../../utils/errors'
import LogoutModal from '../Modal/LogoutModal'
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from "react-bootstrap";
import moment from 'moment';
import useInputFocus from "../../utils/customHooks/useInputFocus";
import { logEvent,Categories,Actions } from "../../analytics";
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';

const Schools = ({ history }) => {
	const pollingIntervalRef = React.useRef(null);
	const timeoutIdRef = React.useRef(null);
	const [schoolNames, setSchoolNames] = React.useState();
	const [loaderState, setLoaderState] = React.useState(true);
	const [showModal2, setShowModal2] = React.useState(false);
	const [searchText, setSearchText] = React.useState('');
	const [searchTerm, setSearchTerm] = React.useState('');
	const [timezone, setTimezone] = React.useState('');
	const [schoolMetaData,setSchoolMetadata]= React.useState({
		totalSchools : 0,
		activeSchools : 0,
		blockedSchools : 0,
		expiredSchools : 0,
	});
	const isAnyInputFocused = useInputFocus();

	React.useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			// stopPolling(pollingIntervalRef);
			setLoaderState(false);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, getData, pollingIntervalRef);

	React.useEffect(() => {
		const localTimezone = moment.tz.guess();
		setTimezone(localTimezone);
		deleteEtag(SCHOOLS_ETAG_KEY);
		// startPolling(getData, pollingIntervalRef);
		// const handleVisibilityChange = () => {
		// 	if (document.hidden) {
		// 		stopPolling(pollingIntervalRef);
		// 	} else {
		// 		startPolling(getData, pollingIntervalRef);
		// 	}
		// };

		// Add event listener for visibility change
		document.addEventListener('visibilitychange', visibilityChangeHandler);

		// Clean up the event listener when the component unmounts
		return () => {
			stopPolling(pollingIntervalRef);
			setLoaderState(false);
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, [searchTerm]);

	React.useEffect(() => {
		const role = getRoleInStore();
		if (role && role === "SchoolAdmin") {
			history.push("/dashboard");
		} else if (role === "SuperAdmin") {
			setLoaderState(true);
			getData();
		}
		return () => {
			// stopPolling(pollingIntervalRef);
			setLoaderState(false);
		};
	}, [searchTerm]);

	const handleAddSchool = () => {
		history.push('/addorganization');
		logEvent({category:Categories.school,action:Actions.school_invite_btn});
							
	};

	const getData = async () => {
		try {
			let url = `/api/v1/schools`;
			if (searchTerm.length > 0) {
				url += `?search=${encodeURIComponent(searchTerm)}&timezone=${encodeURIComponent(timezone)}`;
			}
			const headers = {};
			stopPolling(pollingIntervalRef);
			const storedEtag = getEtag(SCHOOLS_ETAG_KEY);
			if (storedEtag) {
				headers["If-None-Match"] = storedEtag;
			}
			const result = await apiInstance.get(url, {headers});
			startPolling(getData, pollingIntervalRef);
			if (result.status === 304) {
				
				return;
			}
			if (result && result.data && result.data.data) {
				setEtag(SCHOOLS_ETAG_KEY, result.headers.etag);
				setSchoolNames([...result.data.data.schools]);
				setLoaderState(false);
				setSchoolMetadata({
					totalSchools: result.data.data.totalSchools || 0,
					activeSchools:result.data.data.activeSchools || 0,
					blockedSchools:result.data.data.blockedSchools || 0,
					expiredSchools:result.data.data.totalExpired || 0,
				});
			}

		} catch (error) {
			startPolling(getData, pollingIntervalRef);
			setLoaderState(false);
			if (error.response && error.response.status === 304) {
				return;
			}
			const result = errorFunction(error)
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				// setErrorMessage(result);
			}
		}
	};

	return (
		<div id='main-wrapper'>
			<div className='page-wrapper d-block'>
				<div className='container-fluid'>
					<div className='row align-items-end'>
						<div className='col-lg-6 col-md-7 col-sm-12'>
							<h5 className='Map_select_heading'>
								{intl.selectOrganizationToProceed}
							</h5>
						</div>
						<div className='col-lg-4 col-md-4 col-sm-11 d-flex '>
							<input
								id='SearchSchool'
								type='search'
								className='search_school'
								value={searchText}
								placeholder={intl.typeToFilter}
								onChange={(e) => setSearchText(maxLength(e.target.value, 40))}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										setSearchTerm(searchText);
										if(searchTerm.length > 0) {
											setLoaderState(true);
										}
									}
								  }}
							/>
							{searchText.length >= 1 ? <div className="d-flex justify-content-center align-items-center" 
							style={{borderBottom: '1px solid #8f9091', paddingTop:'1em', cursor:'pointer'}}>
								<FaTimes onClick={()=> {
									setSearchText("");
									setSearchTerm("");
							
								}}/>
								</div>: null}
						</div>
						<div className='col-lg-2 col-md-2 col-sm-6 col-6' >
							<div className='float marginTop'>
								<button
								onClick={(e) => {
									if (searchText.length >= 1) {
										setSearchTerm(searchText);
										// setLoaderState(true);
										logEvent({category:Categories.school,action:Actions.school_search_btn});
									}
								}}
									className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
									type='submit'
								>
									{loaderState ? <Spinner size='sm' /> : intl.searchButton}
								</button>
							</div>
						</div>
					</div>
					<div className='mt-4 row'>
						<div className='col-lg-10 col-md-12 col-sm-12 float_right'>
								<div className='card-header card_headertitle school_header_width'>
								<div className=''>
								<div className='row d-flex'>
								<div className='col-lg-3  col-md-6 col-sm-6 school-total-div'>
									<p className=''>
										{intl.totalOrganizations}
										<strong className="text-black padding-left-small">
											{schoolMetaData.totalSchools}
										</strong>
									</p>
								</div>

								<div className='col-lg-3 col-md-6 col-sm-6 school-total-div'>
									<p className='mr-2'>
										{intl.ActiveOrganizations}  
										<strong className="text-black padding-left-small">
											{schoolMetaData.activeSchools}
										</strong>
									</p>
								</div>

								<div className='col-lg-3 col-md-6 col-sm-6 school-total-div'>
									<p className=''>
										{intl.blockedOrganizations}   
										<strong className='word-break text-black padding-left-small'>
											{schoolMetaData.blockedSchools}
										</strong>
									</p>
								</div>

								<div className='col-lg-3 col-md-6 col-sm-6 school-total-div'>
									<p className=''>
										{intl.expiredOrganizations}
										<strong className="text-black padding-left-small">
											{schoolMetaData.expiredSchools}
										</strong>
									</p>
								</div>
							</div>
							
							</div>
							</div>

						</div>
						
						<div className='col-lg-2 col-md-12 col-sm-12'>
						<div className="">
							<button
								title='Add Organization'
								className='btn mt-2 AddButton btn-sm float_right'
								onClick={handleAddSchool}
								>
								<i>
									<FaPlus />
								</i>{" "}
								{intl.addOrganization}
							</button>
						</div>
						</div>
					</div>
					<div className='row page-titles'>
						{loaderState ? (
							<div className="d-flex justify-content-center align-items-center">
								<Spinner animation="border" variant="secondary" />
							</div>
						) : (
							schoolNames && schoolNames.length > 0 ? schoolNames.map((school, index) => {
								return <SchoolItem school={school} key={index} />;
							}):<div className="ml-4">{intl.noSchoolFound} {" "}{searchTerm ? intl.fromSearch : ''}</div> 
						)}
					</div>
				</div>
			</div>
			{showModal2 ? (
					<LogoutModal/>
				) : null}
		</div>
	);
};
export default withRouter(Schools);
