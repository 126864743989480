import React, { useState, useEffect, useRef } from 'react';

import { withRouter } from 'react-router-dom';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import apiInstance from '../../api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Strings from '../../locale/en-US.json';
import {maxLength} from '../../utils/apiUtils';
import { Spinner } from 'react-bootstrap';
// import { Dropdown } from 'semantic-ui-react';
import { LENGTH_MAX } from '../../strings';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FaTimesCircle } from 'react-icons/fa';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import AlertModal from '../Modal/AlertModal';
import { logEvent,Categories,Actions } from "../../analytics";
const MAX_LENGTH = 255;
const MIN_LENGTH = 3;

const AddStudent = ({ history, match }) => {
	const { id } = match.params;
	const gender='Male';
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [badgeNumber, setBadgeNumber] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const [showModal, setShowModal] = useState(false);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);
	const formChanged = useRef(false);
	const isAnyInputFocused = useInputFocus();
	const [sendNotification, setSendNotification] = React.useState(true);

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue =
					'You have unsaved changes. Are you sure you want to leave this page?';
			}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);

	  const closeModal = () => setShowModal(false);

	  const handleFormChange = async (event) => {
		const { type } = event.target;
		if (type === 'checkbox') {
			return;
		}
		event.preventDefault();
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		} else {
			setShowModal(true);
			return false;
		}
	};

	const handleSendNotificationSwitchChange = () => {
		setSendNotification(!sendNotification);
	};

	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
			return true;
		}
		return false;
	};

	const handleNavigationConfirmation = () => {
		setShowModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	};

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);

		return () => {
			unblock();
		};
	}, [history]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	// const genderOptions = [
	// 	{ key: 1, text: 'Male', value: 'Male' },
	// 	{ key: 2, text: 'Female', value: 'Female' },
	// 	{ key: 3, text: 'Other', value: 'Other' },
	// ];

	useEffect(() => {
		async function getBadgeData(badge) {
			setLoading(true);
			setLoading(false);
			// try {
				// const result = await apiInstance.get('/api/v1/badges');
				// const assignedBadge = result.data.data.badges.filter(badge => badge._id === badge)

				// console.log('dsdsdds: ',badge)

				// assignedBadge.length >= 1 && setBadgeNumber({name: assignedBadge[0].mac_address, value: badge});

				// const result = await apiInstance.get('/api/v1/badges');
				// console.log('BADGES BADGES BADGES', result.data.data.badges);
				// const unassignBadges = result.data.data.badges.filter(
				// 	(badge) => badge.user.role === undefined
				// );
				// let newBadgeList=[];
				// if (unassignBadges.length === 0) {
				// 	newBadgeList = [{key: 9, text: 'No badges available', value: null}]
				// } else {
				// 	newBadgeList = unassignBadges.map((badge, i) => {
				// 		return { key: i, text: badge.mac_address, value: badge._id };
				// 	});
				// }
				// setAllUnassignedBadges([...newBadgeList]);

				// // in edit view
				// if (badge) {
				// 	setAllUnassignedBadges(
				// 		badge._id && badge._id !== undefined && unassignBadges.length === 0
				// 			? [
				// 				{ key: 99, text: badge.mac_address, value: badge._id },
				// 			  ]
				// 			: badge._id && badge._id !== undefined ?
				// 			[
				// 				{ key: 99, text: badge.mac_address, value: badge._id },
				// 				...newBadgeList,
				// 		  	]:
				// 			[...newBadgeList]
				// 	);
				// 	console.log(badge.mac_address);
				// 	console.log('badge    ', badge);
				// 	setBadgeNumber(badge._id);
				// }
			// } catch (error) {
			// 	setLoading(false);
			// 	const result = errorFunction(error);

			// 	console.log('result-------- ', result);
			// 	if (typeof result === 'boolean') {
			// 		setShowModal2(true);
			// 	} else {
			// 		setErrorMsg(result);
			// 	}
			// }
		}

		// in edit mode
		async function getData() {
			try {
				setLoading(true);
				const result = await apiInstance.get(`/api/v1/users/${id}`);
				setFirstName(result.data.user.first_name);
				setSendNotification(
					result.data.user.send_notification === false ? false : true
				);
				setLastName(result.data.user.last_name);
				setLoading(false);
				getBadgeData(result.data.user.badge_id);
				// getBadgeData(result.data.user.badge_id);
				result.data.user.badge_id &&
					setBadgeNumber({
						name: result.data.user.badge_id.mac_address,
						value: result.data.user.badge_id._id,
					});
			} catch (error) {
				setLoading(false);
				const result = errorFunction(error);				// 
				if (typeof result === 'boolean' && error.response.data.statusCode !== -16) {
					setShowModal2(true);
				} else if (error.response.data.statusCode === -16) {
					setErrorMsg('User not found');
				} else if (typeof result !== 'boolean') {
					setErrorMsg(result);
				}
			}
		}

		if (id) {
			// edit mode
			getData();
		} else {
			// add mode
			// getBadgeData();
		}
	}, []);

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		try {
			const result = await apiInstance.get(
				`/api/v1/badges?page=${page}&limit=200`
			);
			const unassignBadges = result.data.data.badges.filter(
				(badge) => badge.user.role === undefined
			);

			let options;

			// edit mode if no badge assigned and there are no badges available to assign
			if (id && page === 1 && unassignBadges.length === 0) {
				options = [{ name: 'No badges available', value: null }];
			} else if (page === 1 && unassignBadges.length === 0 && !id) {
				options = [{ name: 'No badges available', value: null }];
			} else {
				options = unassignBadges.map((badge, i) => ({
					name: badge.mac_address,
					value: badge._id,
				}));
			}

			// in edit if a badge is assigned 
			if (badgeNumber !== null && badgeNumber.value !== null) {
				if (options[0] && options[0].value === null) {
					options = [badgeNumber];
 				} else if (options && options.length > 0) {
					options = [badgeNumber, ...options];
 				}
			} else {
				// no badge assigned
				options = [...options];
			}
			// options = badgeNumber !== null && badgeNumber.value !== null ? [badgeNumber, ...options]:[...options];
			return {
				options,
				hasMore: unassignBadges.length >= 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		} catch (error) {
			const result = errorFunction(error);
			//console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMsg(result);
			}
		}
	};

	const customStyles = {
		menu: (provided, state) => ({
			...provided,
			top: 'auto',
			bottom: '100%',
		}),
		control: (provided, state) => ({
			...provided,
			borderRadius: '5px',
			// border: '0.5px solid grey',
			boxShadow: state.isFocused ? '0 0 0 0.2px grey' : null,
			'&:hover': {
				border: '1px solid grey',
			},
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? 'rgba(0,0,0,.03)' : null,
			color: 'black',
			fontWeight: state.isSelected ? 'bold' : null,
		}),
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if(id){
		   logEvent({category:Categories.student,action:Actions.student_edit_submit_btn});
	    
		}else{
		   logEvent({category:Categories.student,action:Actions.student_add_submit_btn});
	    }
		setFirstName(firstName.trim());
		setLastName(lastName.trim());
		if (
			firstName.trim().length < MIN_LENGTH ||
			firstName.trim().length > MAX_LENGTH
		) {
			setErrorMsg(Strings.firstName + ' : ' + LENGTH_MAX);
			return;
		}


		if (
			lastName.trim().length < MIN_LENGTH ||
			lastName.trim().length > MAX_LENGTH
		) {
			setErrorMsg(Strings.lastName + ' : ' + LENGTH_MAX);
			return;
		}

		if (gender === '') {
			setErrorMsg("Gender can't be empty");
			return;
		}
		try {
			let result;
			let requestBody = {
				first_name: firstName.trim(),
				last_name: lastName.trim(),
				gender: gender,
				role: intl.roleStudent,
				send_notification: sendNotification,
			};

			if (badgeNumber !== null && badgeNumber.value !== null) {
				requestBody.badge_id = badgeNumber.value;
			}

			// if (badgeNumber !== '-- SELECT --') {
			// 	if (badgeNumber !== '') {
			// 		console.error('Badge');
			// 		requestBody.badge_id = badgeNumber;
			// 	}
			// }

			if (id) {
				if (!submitted) {
					setLoading2(true);
					setSubmitted(true);
					setSubmitted(true);
					result = await apiInstance.put(`/api/v1/users/${id}`, requestBody);

					setErrorMsg('');
					if (result && result.status === 200) {
						setLoading2(false);
						logEvent({category:Categories.student,action:Actions.student_edit_success});
						allowNavigationRef.current = true;
						toast.success('Successfully Saved!', {
							position: toast.POSITION.TOP_CENTER,
							autoClose: 2000,
						});
					}
					setTimeout(() => {
						history.push('/users');
					}, 2000);
				}
			} else {
				if (!submitted) {
					setSubmitted(true);
					setLoading2(true);
					setSubmitted(true);
					result = await apiInstance.post('/api/v1/users/register', [
						requestBody,
					]);
					if (result) {
						setErrorMsg('');
						setLoading2(false);
						allowNavigationRef.current = true;
						logEvent({category:Categories.student,action:Actions.student_add_success});
						toast.success('Successfully Saved!', {
							position: toast.POSITION.TOP_CENTER,
							autoClose: 2000, // Toast message will be shown for 2 seconds
						});

						setTimeout(() => {
							history.push('/users');
						}, 2000);
					}
				}
			}
		} catch (error) {
			setSubmitted(false);
			setLoading2(false);
			const result = errorFunction(error);
			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMsg(result);
			}
		}
	};

	return (
		<>
			<div>
				<Breadcrumb
					links={[
						{ link: '/users', title: intl.studentList},
						id
							? { link: '', title: 'Edit User'}
							: { link: `/addUser`, title: intl.addStudentButton},
					]}
					
				/>

				<div className='card-header card_headertitle'>
					<div className='card-title card_head'>
						{id ? (
							<h4 className='card_head CardTitle text-uppercase component-heading  '>
								{intl.editStudent}
							</h4>
						) : (
							<h4 className='card_head CardTitle text-uppercase component-heading  '>
								{intl.addStudentButton}
							</h4>
						)}
					</div>
					{errorMsg && (
						<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
							{errorMsg}
						</div>
					)}
					{loading ? <div className="d-flex justify-content-center align-items-center"><Spinner animation="border" variant="secondary" /></div>
					 : null}
				</div>

				<div className='card-body' style={{ background: '#fff' }}>
					<form id='PersonSaveForm' onChange={handleFormChange} onSubmit={handleSubmit} action=''>
						<div className='row'>
							<div className='col-lg-6 col-md-12 col-sm-12 col-12'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.firstName}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											className='form-control'
											name='FirstName'
											value={firstName}
											onChange={(event) =>
												setFirstName(maxLength(event.target.value, 50))
											}
											required
											autoFocus
										/>
									</div>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.lastName}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											className='form-control'
											name='LastName'
											value={lastName}
											onChange={(event) =>
												setLastName(maxLength(event.target.value, 50))
											}
											required
										/>
									</div>
								</div>
								{/* <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<label className='form-label'>
										{intl.gender}
										<span className='font_red'>*</span>
									</label>
									<div className='form-group'>
										<span className='GenderGroup'>
											<Dropdown
												options={genderOptions}
												required
												selection
												placeholder='Gender'
												value={gender}
												onChange={(e, { value }) => setGender(value)}
											/>
										</span>
									</div>
								</div> */}
								<div className='add-student-badge-div'>
									<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
										<label className='form-label'>{intl.badgeNumber}</label>
										<div className='form-group d-flex'>
											<span
												className={`${
													badgeNumber !== null && badgeNumber.value !== null
														? 'remove-badge'
														: null
												}`}
												style={{ width: '100%' }}
											>
												{/* <Dropdown
												placeholder='-- SELECT --'
												options={allUnassignedBadges}
												selection
												style={{ width: '100%' }}
												value={badgeNumber}
												required
												onChange={handleOptionClick}
											/> */}
												<AsyncPaginate
													value={badgeNumber}
													loadOptions={loadOptions}
													getOptionValue={(option) => option.name}
													getOptionLabel={(option) => option.name}
													onChange={(value) => {
														value.value !== null && setBadgeNumber(value);
													}}
													isSearchable={false}
													placeholder='-- SELECT --'
													additional={{
														page: 1,
													}}
													styles={customStyles}
												/>
											</span>
											{badgeNumber && (
												<span
													className='unassign-badge-button'
													onClick={() => setBadgeNumber(null)}
												>
													<FaTimesCircle
														id='btnClear'
														className='cancel-icon'
													/>
												</span>
											)}
										</div>

										{/* {id && badgeNumber !== null && badgeNumber.value !== null ? (
										<input
											onClick={() => setBadgeNumber(null)}
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed mb-4'
											style={{ minWidth: '7em' }}
											value='Un-Assign'
											type='button'
											id='PersonBtn'
										/>
									) : null} */}
									</div>
									<div className='ml-3 mt-2 d-flex align-items-center'>
										{badgeNumber && badgeNumber !== '' ? (
											<div className=' d-flex'>
												<label
													style={{ minWidth: '9.04em' }}
													className='form-label'
												>
													{intl.sendNotification}
												</label>
												<div className='switch'>
													<label className='form-label'>
														<input
															type='checkbox'
															className='toggle-input'
															onChange={()=> {
																formChanged.current = true;
																handleSendNotificationSwitchChange()}
															}
															checked={sendNotification}
														/>
														<span className='lever'></span>
													</label>
												</div>
											</div>
										) : (
											''
										)}
									</div>
								</div>
							</div>
							<div
								className='col-lg-6 col-md-12 col-sm-12 col-12'
								id='LocationMapping'
							>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='LocationsDiv'></div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float_right mr-3'>
									<div align='center'>
										<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading2 ? <Spinner size='sm' /> : Strings.submitButton}
										</button>
									</div>
								</div>
								{/* <div className='float_right mr-3'>
									<a
										type='Reset'
										className='btn btn-secondary button-fixed'
										id='reset_form'
										onClick={handleReset}
									>
										{intl.resetButton}
									</span>
								</div> */}
								<div className='float_right mr-3'>
									<span										className='btn btn-secondary cancel-btn button-fixed'
										onClick={(event) => 
											{event.preventDefault();
											history.push('/users');
											if(id){
												logEvent({category:Categories.student,action:Actions.student_edit_cancel_btn});
										  
											}else{
												logEvent({category:Categories.student,action:Actions.student_add_cancel_btn});
										    }
										  }
										}
									>
										{intl.cancelButton}
									</span>
								</div>
							</div>
						</div>
					</form>
					{showModal ? (
						<AlertModal
							closeModal={closeModal}
							header={Strings.unsavedChanges}
							body={Strings.unsavedChangesText}
							handlerFunction={handleNavigationConfirmation}
						/>
					) : null}
				</div>
				{showModal2 ? <LogoutModal /> : null}
				<ToastContainer />
			</div>
		</>
	);
};
export default withRouter(AddStudent);
