import ReactGA from "react-ga4";
const KEY = "G-2P3FL9HNGX";//"G-SDY9C955TN";//

export const Categories = {
    authentication:"authentication",
    feedback:"feedback",
    staff:"staff",
    student:"student",
    badge:"badge",
    device:"device",
    alert:"alert",
    accountSettings:"account",
    school:"school",
    plan:"plan",
}

export const Actions = {
  forgot_pwd_submitted_btn:"forgot_pwd_submitted_btn_clicked",
  forgot_pwd_submitted_success:"forgot_pwd_submitted_success",
  forgot_pwd_back_to_signin:"forgot_pwd_back_to_signin_btn_clicked",
  forgot_pwd_btn:"forgot_pwd_btn_clicked",
  signup_btn:"signup_btn_clicked",
  signup_submitted_btn:"signup_submitted_btn_clicked",
  signup_submitted_success:"signup_submitted_success",
  signin_btn:"signin_btn_clicked",
  signin_submitted_btn:"signin_submitted_btn_clicked",
  signin_submitted_success:"signin_submitted_success",
  signout_btn:"signout_btn_clicked",
  signout_confirm_btn:"signout_confirm_btn_clicked",
  signout_cancel_btn:"signout_cancel_btn_clicked",
  resend_verification_email_btn:"resend_verification_email_btn_clicked",
  resend_verification_email_success:"resend_verification_email_success",
  contact_us_btn:"contact_us_btn_clicked",
  contact_us_cancel_btn:"contact_us_cancel_btn_clicked",
  contact_us_submitted_btn:"contact_us_submitted_btn_clicked",
  contact_us_submitted_success:"contact_us_submitted_success",
  staff_btn:"staff_btn_clicked",
  staff_search_btn:"staff_search_btn_clicked",
  staff_search_clear_btn:"staff_search_clear_btn_clicked",
  staff_add_btn:"staff_add_btn_clicked",
  staff_edit_btn:"staff_edit_btn_clicked",
  staff_assign_btn:"staff_assign_student_btn_clicked",
  staff_delete_cancel_btn:"staff_delete_cancel_btn_clicked",
  staff_delete_submit_btn:"staff_delete_submit_btn_clicked",
  staff_delete_success:"staff_delete_submitted_success",
  staff_bulk_upload_btn:"staff_bulk_upload_btn_clicked",
  staff_bulk_upload_success:"staff_bulk_uploaded_success",
  staff_download_samplefile_btn:"staff_bulk_samplefile_download_btn_clicked",
  staff_bulk_upload_choose_file_download_btn:"staff_bulk_choose_file_download_btn_clicked",
  staff_bulk_upload_cancel_btn:"staff_bulk_upload_cancel_btn_clicked",
  staff_add_submit_btn:"staff_add_submit_btn_clicked",
  staff_add_submit_success:"staff_add_submitted_success",
  staff_add_notification_status_changed:"staff_add_notification_send_status_changed",
  staff_add_cancel_btn:"staff_add_cancel_btn_clicked",
  //staff_add_cancel_changes_btn:"staff_add_cancel_changes_btn_clicked",
  staff_edit_submit_btn:"staff_edit_submit_btn_clicked",
  staff_edit_success:"staff_edit_submitted_success",
  staff_edit_notification_receive_status:"staff_edit_notification_receive_status_changed",
  staff_edit_notification_send_status:"staff_edit_notification_send_status_changed",
  staff_edit_cancel_btn:"staff_edit_cancel_btn_clicked",
  staff_assign_search_btn:"staff_assign_student_search_btn_clicked",
  staff_assign_search_clear_btn:"staff_assign_student_search_clear_btn_clicked",
  staff_unassign_btn:"staff_unassign_btn_clicked",
  staff_unassign_success:"staff_unassign_student_submitted_success",
  staff_assign_add_btn:"staff_assign_student_add_btn_clicked",
  staff_assign_add_success:"staff_assign_student_add_submitted_success",
  student_btn:"student_btn_clicked",
  student_bulk_upload_btn:"student_bulk_upload_btn_clicked",
  student_bulk_upload_success:"student_bulk_uploaded_success",
  student_download_samplefile_btn:"student_bulk_samplefile_download_btn_clicked",
  student_bulk_upload_choose_file_download_btn:"student_bulk_choose_file_download_btn_clicked",
  student_bulk_upload_cancel_btn:"student_bulk_upload_cancel_btn_clicked",																									
  student_delete_btn:"student_delete_btn_clicked",
  student_delete_success:"student_delete_submitted_success",
  student_search_btn:"student_search_btn_clicked",
  student_add_btn:"student_add_btn_clicked",
  student_add_submit_btn:"student_add_submit_btn",
  student_add_success:"student_add_submitted_success",
  student_add_cancel_btn:"student_add_cancel_btn_clicked",
  student_edit_btn:"student_edit_btn_clicked",
  student_edit_submit_btn:"student_edit_submit_btn",
  student_edit_success:"student_edit_submitted_success",
  student_edit_cancel_btn:"student_edit_cancel_btn_clicked",
  badge_btn:"badge_btn_clicked",
  badge_bulk_upload_btn:"badge_bulk_upload_btn_clicked",
  badge_bulk_upload_success:"badge_bulk_uploaded_success",
  badge_download_samplefile_btn:"badge_bulk_samplefile_download_btn_clicked",
  badge_bulk_upload_choose_file_download_btn:"badge_bulk_choose_file_download_btn_clicked",
  badge_bulk_upload_cancel_btn:"badge_bulk_upload_cancel_btn_clicked",	
  badge_search_btn:"badge_search_btn_clicked",
  badge_delete_btn:"badge_delete_btn_clicked",
  badge_delete_success:"badge_delete_submitted_success",
  badge_add_btn:"badge_add_btn_clicked",
  badge_add_submit_btn:"badge_add_submit_btn",
  badge_add_success:"badge_add_submitted_success",
  badge_add_cancel_btn:"badge_add_cancel_btn_clicked",
  badge_edit_btn:"badge_edit_btn_clicked",
  badge_edit_submit_btn:"badge_edit_submit_btn",
  badge_edit_success:"badge_edit_submitted_success",
  badge_edit_cancel_btn:"badge_edit_cancel_btn_clicked",
  device_btn:"device_btn_clicked",
  device_Qr_scan_btn:"device_Qr_scan_btn_clicked",
  device_Qr_scan_submitted:"device_Qr_scanned_success",
  device_bulk_upload_btn:"device_bulk_upload_btn_clicked",
  device_bulk_upload_success:"device_bulk_uploaded_success",
  device_download_samplefile_btn:"device_bulk_samplefile_download_btn_clicked",
  device_bulk_upload_choose_file_download_btn:"device_bulk_choose_file_download_btn_clicked",
  device_bulk_upload_cancel_btn:"device_bulk_upload_cancel_btn_clicked",	
  device_delete_btn:"device_delete_btn_clicked",
  device_delete_success:"device_delete_submitted_success",
  device_search_btn:"device_search_btn_clicked",
  device_add_btn:"device_add_btn_clicked",
  device_add_submit_btn:"device_add_submit_btn",
  device_add_success:"device_add_submitted_success",
  device_add_cancel_btn:"device_add_cancel_btn_clicked",
  device_edit_btn:"device_edit_btn_clicked",
  device_edit_submit_btn:"device_edit_submit_btn",
  device_edit_success:"device_edit_submitted_success",
  device_edit_cancel_btn:"device_edit_cancel_btn_clicked",
  alert_btn:"alert_btn_clicked",
  alert_search_btn:"alert_search_btn_clicked",
  alert_apply_btn:"alert_apply_datetime_btn_clicked",
  schoolSettings_btn:"school_settings_btn_clicked",
  schoolSettings_delete_school_btn:"school_settings_delete_btn_clicked",
  schoolSettings_delete_school_success:"school_settings_delete_submitted_success",
  schoolSettings_submit_btn:"school_settings_submit_btn_clicked",
  schoolSettings_submit_success:"school_settings_submitted_success",
  schoolSettings_cancel_btn:"school_settings_cancel_btn_clicked",
  accountSettings_btn:"account_settings_btn_clicked",
  accountSettings_notification:"account_settings_notification_receive_status_changed",
  accountSettings_submit_btn:"account_settings_submit_btn_clicked",
  accountSettings_submit_success:"account_settings_submitted_success",
  accountSettings_cancel_btn:"account_settings_cancel_btn_clicked",
  student_bulk_upload_submit_btn:"student_bulk_upload_submit_btn_clicked",
  badge_bulk_upload_submit_btn:"badge_bulk_upload_submit_btn_clicked",
  staff_bulk_upload_submit_btn:"staff_bulk_upload_submit_btn_clicked",
  device_bulk_upload_submit_btn:"device_bulk_upload_submit_btn_clicked",
  
  //////////////////////////
 //SuperAdmin
  school_btn:"school_btn_clicked",
  school_search_btn:"school_search_btn_clicked",
  school_invite_btn:"school_invite_btn_clicked",
  school_invite_confirm_btn:"school_invite_confirm_btn_clicked",
  school_invite_success:"school_invite_submitted_success",
  school_reinvite_btn:"school_reinvite_btn_clicked",
  school_reinvite_success:"school_reinvite_submitted_success",
  school_invite_cancel_btn:"school_invite_cancel_btn_clicked",
  school_details_btn:"school_details_btn_clicked",
  school_details_cancel_btn:"school_details_cancel_btn_clicked",
  school_details_save_btn:"school_details_save_btn_clicked",
  school_details_save_success:"school_details_save_submitted_success",
  school_details_activation_settings:"school_details_block_settings_changed",
  school_details_expiry_settings:"school_details_expiry_settings_changed",
  plan_btn:"plan_btn_clicked",
  plan_add_btn:"plan_add_btn_clicked",
  plan_add_submit_btn:"plan_add_submit_btn_clicked",
  plan_add_submit_success:"plan_add_submitted_success",
  plan_add_cancel_btn:"plan_add_cancel_btn_clicked",
  plan_edit_btn:"plan_edit_btn_clicked",
  plan_edit_submit_success:"plan_edit_submitted_success",
  plan_edit_submit_btn:"plan_edit_submit_btn_clicked",
  plan_edit_cancel_btn:"plan_edit_cancel_btn_clicked",
  plan_search_btn:"plan_search_btn_clicked",
  plan_delete_btn:"plan_delete_submit_btn_clicked",
  plan_delete_success:"plan_delete_submitted_success",
  feedbacklist_btn:"feedback_list_btn_clicked",
  feedback_list_search_btn:"feedback_list_search_btn_clicked",
  alert_list_btn:"alert_list_btn_clicked",
  alert_list_apply_btn:"alert_list_apply_datetime_btn_clicked",
  alert_list_search_btn:"alert_list_search_btn_clicked",
  policies_info_btn:"policies_information_btn_clicked",
  EULA_btn:"policies_license_agreement_btn_clicked",
  privacy_policy_btn:"policies_privacy_policy_btn_clicked",
  

}

export const initGA = () => {
  if(process.env.NODE_ENV === 'production' && KEY)  {
      ReactGA.initialize(KEY, {
        titleCase: true,
      });
  }
};

export const logPageView = () => {
  if(KEY)  {
     ReactGA.send("pageview");
  }
};

export const logEvent = ({category = '', action = '', value=undefined,label=""}) => {
  if (process.env.NODE_ENV === 'production' && category && action && KEY) {
    setTimeout(function() {
        ReactGA.event({category: category,action:action,value:value,label:label});
    },500);
  }
};

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};