import { useEffect, useState, useRef } from 'react';
import {FaTimes} from 'react-icons/fa'
import React from 'react';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Strings from '../../locale/en-US.json';
import apiInstance from '../../api';
import { Spinner } from 'react-bootstrap';
import {tz} from 'moment-timezone';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import {
	deleteEtag,
	getListInStore,
	getEtag,
	setEtag,
	setListInStore,
	FEEDBACK_ETAG_KEY,
	FEEDBACK_LIST_KEY,
} from '../../utils/apiUtils';
import 'react-toastify/dist/ReactToastify.css';
import { Pagination } from 'semantic-ui-react';
import { maxLength } from '../../utils/apiUtils';
import { LENGTH_MAX } from '../../strings';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import intl from '../../locale/en-US.json';
import { logEvent,Categories,Actions } from "../../analytics";
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';

const LIMIT_OFFSET = 50;

const FeedbackList = () => {
	const [feedbackList, setFeedbackList] = useState([]);
	const [searchString, setSearchString] = useState('');
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1);
	const [showModal2, setShowModal2] = useState(false);
	const [count, setCount] = useState(0);
	const [timezone, setTimezone] = useState('');
	const pollingIntervalRef = useRef(null);
	const timeoutIdRef = useRef(null);
	const isAnyInputFocused = useInputFocus();
	
	useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);
	
	

	let isMounted = false;

	const handlePageChange = (event, { activePage }) => {
		setCurrentPage(activePage);
	};

	useEffect(() => {
		deleteEtag(FEEDBACK_ETAG_KEY);
		fetchData();
	}, [searchString, currentPage]);

	const fetchData = async () => {
		try {
			let url = '/api/v1/feedbacks';
			let queryString = '';

			if (searchString.length > 0) {
				queryString += `search=${searchString}`;
			}
			if (queryString.length > 0) {
				url = `/api/v1/feedbacks/?${queryString}`;
			}
			const headers = {};
			const storedEtag = getEtag(FEEDBACK_ETAG_KEY);
			if (storedEtag) {
				headers['If-None-Match'] = storedEtag;
			}
			//   headers['cache-control'] = 'public, max-age=3600';
			//console.log('URl', url);
			stopPolling(pollingIntervalRef);
			const res = await apiInstance.get(url, {
				headers,
				params: {
					page: currentPage,
					limit: LIMIT_OFFSET,
				},
			});
			console.log('Result-----: ', res);
			if (res && res.data && res.data.data) {
				setLoading(false);
				setCount(res.data.data.totalFeedbacks);
				setFeedbackList(res.data.data.feedbacks);
				setCount(res.data.data.totalFeedbacks)
				setEtag(FEEDBACK_ETAG_KEY, res.headers.etag);
				setTotalPages(res.data.data.totalPages); // Set the total number of pages
				setListInStore(FEEDBACK_LIST_KEY, JSON.stringify(res.data.data.feedbacks));
				// fetchDataTimeout();
			}
			startPolling(fetchData, pollingIntervalRef);
			isMounted = true;
		} catch (error) {
			// fetchDataTimeout();
			startPolling(fetchData, pollingIntervalRef);
			if (error.response && error.response.status === 304) {
				setLoading(false);
				setErrorMsg('');
				return;
			}
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				// setErrorMessage(result);
			}
		} 
		// finally {
		// 	setLoading(false);
		// }
	};

	useEffect(() => {
		const localTimezone = tz.guess();
        setTimezone(localTimezone);
		// deleteEtag(FEEDBACK_ETAG_KEY);
		const handlePageLoad = async () => {
			const storedBadgeList = getListInStore(FEEDBACK_LIST_KEY); 
			if (storedBadgeList) {
				setFeedbackList(JSON.parse(storedBadgeList));
				// setLoading(false);
			}
		};
		if (!isMounted) {
			handlePageLoad();
		}
		
	}, []);
	
	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);

	useEffect(() => {
		const handlePageLoad = async () => {
			// deleteEtag(FEEDBACK_ETAG_KEY);
			const storedBadgeList = getListInStore(FEEDBACK_LIST_KEY); //localStorage.getItem("BadgeList");
			if (storedBadgeList) {
				setFeedbackList(JSON.parse(storedBadgeList));
				// setLoading(false);
			}
		};
		if (!isMounted) {
			handlePageLoad();
		}
		window.onbeforeunload = () => {
			//deleteEtag("badges");
			// deleteEtag(FEEDBACK_ETAG_KEY);
		};

		document.addEventListener('visibilitychange', visibilityChangeHandler);

		return () => {
			stopPolling(pollingIntervalRef);
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, [currentPage, searchString]);

	const handleSubmit = (e) => {
		e.preventDefault();
		//fetchData(inputValue);
	};

	const onSearch = async () => {
		let isChanged = false;
		let updatedString = document.getElementById('searchName').value.trim();
		if (updatedString === undefined) {
			updatedString = '';
		}
		const MAX_LENGTH = 255;
		const MIN_LENGTH = 1;
		if (updatedString !== '') {
			if (
				updatedString.length < MIN_LENGTH ||
				updatedString.length > MAX_LENGTH
			) {
				setErrorMsg(Strings.lastName + ' : ' + LENGTH_MAX);
				return;
			}
		}
		if (updatedString !== searchString) {
			isChanged = true;
			setSearchString(updatedString);
		}
		// if (
		// 	dateValue[0].toString() !== dateValueTemp[0].toString() ||
		// 	dateValue[1].toString() !== dateValueTemp[1].toString()
		// ) {
		// 	setDateValue([dateValueTemp[0], dateValueTemp[1]]);
		// 	isChanged = true;
		// }
		if (isChanged) {
			stopPolling(pollingIntervalRef);
			setCurrentPage(1);
			setTotalPages(1);
			setLoading(true);
			setTimeout(() => {
				startPolling(fetchData, pollingIntervalRef);
			}, 100);
		}
		//intervalId = setInterval(fetchData, 10000);
	};
   

	const onSearchClear = async () => {
		setSearchString('');
		setSearchText('');
		stopPolling(pollingIntervalRef);
		setTotalPages(1);
		setLoading(true);
		setTimeout(() => {
			startPolling(fetchData, pollingIntervalRef);	
		}, 100);
		setTimeout(() => {
			setSearchString('');
			setSearchText('');	
		}, 300); 
	}

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Breadcrumb
					links={[{ link: '/feedback', title: Strings.feedbackList }]}
					
				/>

				<div className='container-fluid'>
					<div className='row page-titles'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
							<div
								className='card CardNoBorder'
								id='PersonForm'
								style={{
									border: '3px solid red',
									// minWidth: '25em'
								}}
							>
								<div className='card-header card_headertitle'>
									<div className='card-title card_head'>
										<div className='row d-flex align-items-center'>
											<div className='col-xl-2 col-lg-2 col-md-12 col-sm-12'>
												<h5 style={{fontSize:"1em"}} className='card_head CardTitle text-uppercase '>
													{Strings.feedbackList}
												</h5>
											</div>

											<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex'>
												<input
													type='search'
													id='searchName'
													// ref={searchInputRef}
													name='searchName'
													placeholder={intl.searchButton}
													className='w-100 search-input'
													style={
														searchText.length > 1
															? {
																	borderRight: '0px solid red',
																	borderTopRightRadius: '0em',
																	borderBottomRightRadius: '0em',
															  }
															: null
													}
													value={searchText}
													onKeyDown={(e) => {
														if (e.keyCode === 13) {
															onSearch();
														}
													}}
													onChange={(e) => {
														maxLength(e.target.value, 255);
														setSearchText(e.target.value);
														setErrorMsg('');
													}}
												/>

												{searchText.length > 1 ? (
													<span
														className='cancel-container'
														onClick={() =>{
															(searchText.length > 1 && onSearchClear());
														}
														}
													>
														<FaTimes id='btnClear' className='cancel-icon' />
													</span>
												) : null}
											</div>

											<div className='col-xl-1 col-lg-2 col-md-12 col-sm-12 col-12 panel-alert-padding'>
												<div
													style={{ position: 'relative', height: '3em' }}
													className=''
												>
													<input
														onClick={() => {
															onSearch();
															setCurrentPage(1)
															logEvent({category:Categories.feedback,action:Actions.feedback_list_search_btn});
													}}
														type='submit'
														className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed search_button'
														value={Strings.searchButton}
														id='PersonBtn'
													/>
												</div>
											</div>
											<div
												className=' col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12 date-picl-div strip_top_margin'
												style={{ position: 'relative', height: '3em' }}
											></div>
										</div>
									</div>
								</div>
								{errorMsg && <div className='btn_error'>{errorMsg}</div>}
								<div className='card-body DataTable PersonGrid'>
									{loading && feedbackList ? (
										<div className="d-flex justify-content-center align-items-center">
											<Spinner animation="border" variant="secondary" />
										</div>
									) : (
										<div className='table-responsive'>
											<table className='table table-bordered'>
												{/* <colgroup> //style={{ tableLayout: "fixed" }} inside table
       										 <col style={{ width: "15%" }} />
       										 <col style={{ width: "15%" }} />
       										 <col style={{ width: "35%" }} />
       										 <col style={{ width: "15%" }} />
      										</colgroup> */}
												<thead>
													<tr>
														<th scope='col' style={{ minWidth: '15em', maxWidth:'20em' }}>
															{Strings.feedbackName}
														</th>
														<th scope='col' style={{ minWidth: '15em', maxWidth:'20em' }}>
															{Strings.email}
														</th>
														<th scope='col' style={{ minWidth: '10em', maxWidth:'12em' }}>
															{Strings.feedbackContactReason}
														</th>
														<th scope='col' style={{ minWidth: '20em',maxWidth:'25em' }}>
															{Strings.message}
														</th>
													</tr>
												</thead>
												<tbody>
													{feedbackList && feedbackList.length > 0 ? (
														feedbackList.map((feedback, index) => {
															return (
																<tr key={index} >
																	<td>
																		<p>{feedback.name}</p>
																		<p style={{ fontSize: '0.8em', marginTop:'-10px'}} >{feedback.created_at && moment(feedback.created_at)
																		.tz(timezone)
																		.format('DD MMM YYYY, HH:mm:ss')}</p>
							
																	</td>
																	<td>
																		<p>{feedback.email}</p>
																	</td>
																	<td>
																		<p>{feedback.contact_reason}</p>
																	</td>
																	<td>
																		<p>{feedback.message}</p>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td colSpan='6' style={{ textAlign: 'center' }}>
																No records found
															</td>
														</tr>
													)}
												</tbody>
											</table>
											{ (feedbackList && feedbackList.length) > 0 ?
												<div className='count_div'>
													<Pagination
													boundaryRange={0}
													ellipsisItem={null}
													firstItem={null}
													lastItem={null}
													activePage={currentPage}
													totalPages={totalPages}
													siblingRange={1}
													onPageChange={handlePageChange}
													className={totalPages === 1 ? "paginationbutton" : null}
													/>
													<div>Total: {count}</div>
												</div>
												: ''
											}
											<ToastContainer />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
			{showModal2 ? <LogoutModal /> : null}
		</div>
	);
};

export default FeedbackList;
