import React, { useState } from "react";
import apiInstance from "../../../api";
import Breadcrumb from "../../breadcrumb/Breadcrumb";
import intl from "../../../locale/en-US.json";
import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import AlertModal from "../../Modal/AlertModal";
import { ToastContainer } from 'react-toastify';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import useInputFocus from "../../../utils/customHooks/useInputFocus";
import Strings from '../../../locale/en-US.json';
import { useRef, useEffect } from 'react';
import { errorFunction } from "../../../utils/errors";
import { logEvent,Categories,Actions } from "../../../analytics";

const AddSchool = ({history}) => {
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	const [schoolEmail, setSchoolEmail] = useState("");
	const [sendMail, setSendMail] = useState(false); //for loader
	const [submitted, setSubmitted] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modifiedResendInviteEmail, setModifiedResendInviteEmail] = useState(intl.resendInviteEmail.replace("(updatedTime)", ''));
	const isAnyInputFocused = useInputFocus();
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);

	React.useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);

	  const handleFormChange = async (event) => {
		event.preventDefault();
		console.log(event.nativeEvent.inputType);
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	}

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
		  	}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);
	  

	const closeUnsavedModal = () => setShowUnsavedModal(false);
	
	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
				return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		}
		else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	  const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	  };

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);
	
		return () => {
		  unblock();
		};
	  }, [history]);

	const resendOnConfirmation = async () => {
		if (!submitted) {
			logEvent({category:Categories.school,action:Actions.school_reinvite_btn});
			try {
			setSendMail(true);
			setSubmitted(true);
			const response = await apiInstance
				.post("/api/v1/schools/invite", { email: schoolEmail, force_send: true })
				.catch((error) => {
					console.log("error: ", error);
					setSubmitted(false)
					setSendMail(false);
				});
				if (response && response.status === 200) {
					logEvent({category:Categories.school,action:Actions.school_reinvite_success});
					allowNavigationRef.current = true;
					toast.success('Successfully Invited!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
					});
	
					setTimeout(() => {
						history.push('/organizations');
					}, 2000);
				}
				else{
					setSubmitted(false);
					setSendMail(false);
				}
			} catch(error) {
				setSubmitted(false)
				setSendMail(false);
			}
		}
	  };

	const closeModal = () => setShowModal(false);
	// setShowModal(true);
	const handleGoBack = () => {
		window.history.back();
	  };
	const handleSubmit = async (event) => {
		if (!submitted) {
			logEvent({category:Categories.school,action:Actions.school_invite_confirm_btn});
		try {
		setSendMail(true);
		event.preventDefault();
		setSubmitted(true);
		const response = await apiInstance
			.post("/api/v1/schools/invite", { email: schoolEmail })
			.catch((error) => {
				const result = errorFunction(error);
				if(result) {
				toast.error("Error in Inviting Organization", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
					});
				}
				setSubmitted(false)
				setSendMail(false);
			});
			if (response && response.status === 200) {
				logEvent({category:Categories.school,action:Actions.school_invite_success});
				if(response.data && response.data.statusCode === -36 && response.data.invite) {
					const lastSendDate = moment(new Date(response.data.invite.updated_at));
					const modifiedEmail = intl.resendInviteEmail.replace("(updatedTime)",  "at " + lastSendDate.format('DD-MMM-YYYY HH:mm:ss'));
    				setModifiedResendInviteEmail(modifiedEmail);
					setSubmitted(false)
					setSendMail(false);
					setShowModal(true);
				}
				else{
					allowNavigationRef.current = true;
					toast.success('Successfully Sent!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
					});
					setSendMail(false);
					setTimeout(() => {
						history.push('/organizations');
					}, 2000);
				}
			}
			else{
				setSendMail(false);
				setSubmitted(false);
			}
		} catch(error) {
			const result = errorFunction(error);
			if(result) {
			toast.error("Error in Inviting Organization", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				});
			}
			
			setSendMail(false);
			setSubmitted(false)
		}
	}

	};

	return (
		<>
			<div>
				<Breadcrumb
					links={[
						{ link: "/organizations", title: intl.organizations  },
						{ link: "/addorganization", title: intl.addOrganization},
					]}
					
				/>

				<div className='card-header card_headertitle'>
					<div className='card-title card_head'>
						<h4 className='card_head CardTitle text-uppercase component-heading  '>
							{intl.addSchool}
						</h4>
					</div>
				</div>

				<div className='card-body' style={{ background: "#fff" }}>
					<form id='PersonSaveForm' method='post' onChange={handleFormChange} onSubmit={handleSubmit}>
						<div className='row'>
							{/* <div className='col-lg-6 col-md-12 col-sm-12 col-12'> */}
							<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
								<div className='form-group'>
									<label className='form-label'>
										{intl.schoolEmail}
										<span className='font_red'>*</span>
									</label>
									<input
										type='email'
										className='form-control'
										name='SchoolName'
										id='SchoolName'
										required
										autoFocus
										onChange={(e) => setSchoolEmail(e.target.value)}
										value={schoolEmail}
									/>
								</div>
							</div>
						</div>
						<div className='d-flex justify-content-center'>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float_right mr-3'>
									<button
										className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
										type='submit'
									>
										{sendMail ? <Spinner  size='sm' animation="border" variant="secondary" />: intl.submitButton}
									</button>
								</div>
								<div className='float_right mr-3'>
								<button
									className='btn btn-secondary cancel-btn button-fixed'
									onClick={(e) => {
										e.preventDefault();
										handleGoBack();
										logEvent({category:Categories.school,action:Actions.school_invite_cancel_btn});
									}}
									>
									{intl.cancelButton}
								</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				{showUnsavedModal ? (
				<AlertModal
					closeModal={closeUnsavedModal}
					header={Strings.unsavedChanges}
					body={Strings.unsavedChangesText}
					handlerFunction={handleNavigationConfirmation}
				/>
			) : null}
				{showModal ? (
				<AlertModal
					closeModal={closeModal}
					header={intl.resendInviteHeader}
					body={modifiedResendInviteEmail}
					handlerFunction={resendOnConfirmation}
				/>
				) : null}
				<ToastContainer />
			</div>
		</>
	);
};
export default withRouter(AddSchool);
