import { useState, useEffect } from 'react';

const useInputFocus = () => {
  const [isAnyInputFocused, setIsAnyInputFocused] = useState(false);

  useEffect(() => {
    const handleInputFocus = () => setIsAnyInputFocused(true);
    const handleInputBlur = () => setIsAnyInputFocused(false);

    document.addEventListener('focusin', handleInputFocus);
    document.addEventListener('focusout', handleInputBlur);

    return () => {
      document.removeEventListener('focusin', handleInputFocus);
      document.removeEventListener('focusout', handleInputBlur);
    };
  }, []);

  return isAnyInputFocused;
};

export default useInputFocus;