import {POLL_INTERVAL, BLOCK_POLL_INTERVAL} from '../utils/apiUtils';
// for block page and verify email block page
export const blockPageStartPolling = (id, pollAPI, pollingIntervalRef) => {
	if (id) {
		if (!pollingIntervalRef.current) {
			pollingIntervalRef.current = setInterval(pollAPI, POLL_INTERVAL);
		}
	}
};

export const blockStartPolling = (fetchData, pollingIntervalRef) => {
    if (!pollingIntervalRef.current) {
        pollingIntervalRef.current = setInterval(fetchData, BLOCK_POLL_INTERVAL);
    }
};

export const startPolling = (fetchData, pollingIntervalRef) => {
    if (!pollingIntervalRef.current) {
        pollingIntervalRef.current = setInterval(fetchData, POLL_INTERVAL);
    }
};
    
export const stopPolling = (pollingIntervalRef) => {
    if (pollingIntervalRef && pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
    }
};

export const handleVisibilityChange = (timeoutIdRef, fetchData, pollingIntervalRef) => {
    if (document.visibilityState === 'visible') {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        startPolling(fetchData, pollingIntervalRef);
    } else {
        stopPolling(pollingIntervalRef);
    }
};

// for school settings. account settings and contact us
export const blockHandleVisibilityChange = (pollAPI, pollingIntervalRef) => {
    if (document.visibilityState === 'visible') {
        if (pollingIntervalRef.current) {
            clearTimeout(pollingIntervalRef.current);
        }
        blockStartPolling(pollAPI, pollingIntervalRef);
    } else {
        stopPolling(pollingIntervalRef);
    }
};