import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import schoolImage from '../headsidebar/assets/images/sidebar/school.svg';
import apiInstance, { baseurl } from '../../api';
import {
	getSchoolIdInStore,
	getUserBlockedStore,
	removeUserBlockedInStore,
	removeBlockedByAdminInStore,
	setSchoolNameInStore,
	getRoleInStore,
} from '../../utils/apiUtils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import AlertModal from '../Modal/AlertModal';
import LogoutModal from '../Modal/LogoutModal';
import Strings from '../../locale/en-US.json';
import { maxLength } from '../../utils/apiUtils';
// import { Loader } from 'semantic-ui-react';
import { LENGTH_MAX, LOGO_LENGTH } from '../../strings';
import { Spinner } from 'react-bootstrap';
import { tz } from 'moment-timezone';
import moment from 'moment';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { errorFunction } from '../../utils/errors';
import { Actions, Categories, logEvent } from '../../analytics';
import {
	blockStartPolling,
	stopPolling,
	blockHandleVisibilityChange,
} from '../../utils/polling';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MAX_LENGTH = 255;
const MIN_LENGTH = 3;
const MAX_LOGO_SIZE = 3 * 1024 * 1024;

const AccountSettings = ({ history }) => {
	const [showModal, setShowModal] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const [schoolName, setSchoolName] = useState('');
	const [schoolLogo, setSchoolLogo] = useState('');
	const [timezone, setTimezone] = useState('');
	const [expiryDate, setExpiryDate] = useState('');
	const [errorMessage, setErrorMessage] = React.useState('');
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dataFetchLoading, setDataFetchLoading] = useState(true);
	const [previewImage, setPreviewImage] = useState('');
	const [origImage, setOrigImage] = useState('');
	const [timezonesWithOffsets, setTimezonesWithOffsets] = useState([]);
	const isAnyInputFocused = useInputFocus();
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);
	const role = getRoleInStore();
	const whiteImageSVG =
		'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="120" height="120"%3E%3Crect width="100%" height="100%" fill="%23fff" /%3E%3C/svg%3E';

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	useEffect(() => {
		// Get the list of timezones from Moment-Timezone
		const tzList = tz.names();

		// Get the timezone offsets for each timezone
		const timezonesData = tzList.map((timezone) => {
			const zone = tz.zone(timezone);
			const offsetMinutes = zone ? zone.utcOffset(moment()) : null;

			if (offsetMinutes !== null) {
				// Calculate the offset in hours and minutes separately
				const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
				const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60;
				const offsetSign = offsetMinutes >= 0 ? '-' : '+';

				return {
					timezone,
					offset: `${offsetSign}${offsetHours}:${offsetMinutesRemaining
						.toString()
						.padStart(2, '0')}`,
					value: `${timezone}${' '}${offsetSign}${offsetHours}:${offsetMinutesRemaining
						.toString()
						.padStart(2, '0')}`,
				};
			} else {
				return {
					timezone,
					offset: '(Invalid timezone)',
				};
			}
		});

		setTimezonesWithOffsets(timezonesData);
		return () => {
			setLoading(false);
		};
	}, []);

	const id = getSchoolIdInStore();

	const pollingIntervalRef = useRef(null);

	const visibilityChangeHandler = () =>
		blockHandleVisibilityChange(pollAPI, pollingIntervalRef);

	useEffect(() => {
		if (getUserBlockedStore()) blockStartPolling(pollAPI, pollingIntervalRef);

		document.addEventListener('visibilitychange', visibilityChangeHandler);
		return () => {
			stopPolling(pollingIntervalRef);

			if (pollingIntervalRef.current) {
				clearTimeout(pollingIntervalRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, []);

	const pollAPI = async () => {
		try {
			stopPolling(pollingIntervalRef);
			const response = await apiInstance
				.get(`/api/v1/schools/${id}/status`)
				.catch((error) => {
					console.log(error);
				});

			if (response.status === 200) {
				removeUserBlockedInStore();
				removeBlockedByAdminInStore();
				stopPolling(pollingIntervalRef);
			} else {
				blockStartPolling(pollAPI, pollingIntervalRef);
			}
		} catch (error) {
			blockStartPolling(pollAPI, pollingIntervalRef);
			console.log(error);
		}
	};

	useEffect(() => {
		// const id = getUserIdInStore();
		const fetchData = async () => {
			try {
				const id = getSchoolIdInStore();
				const url = `/api/v1/schools/${id}`;
				const res = await apiInstance.get(url);
				if (res && res.data) {
					setSchoolName(res.data.school.school.name);
					setSchoolLogo(res.data.school.school.logo_file);
					setTimezone(res.data.school.school.timezone);
					setPreviewImage(baseurl + '/' + res.data.school.school.logo_file);
					setOrigImage(baseurl + '/' + res.data.school.school.logo_file);
					const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
					const expTime = moment(res.data.school.school.expiry_date)
						.tz(timezone)
						.format('MMM Do, YYYY');
					// .format('YYYY-MM-DD HH:mm:ss');
					setExpiryDate(expTime);
					setDataFetchLoading(false);
				}
			} catch (error) {
				setDataFetchLoading(false);
				const result = errorFunction(error);
				if (typeof result === 'boolean') {
					setShowModal2(true);
				} else {
					showError(result);
				}
			}
		};
		fetchData();
	}, []);

	const closeModal = () => setShowModal(false);

	const deleteAccount = async () => {
		try {
			logEvent({
				category: Categories.school,
				action: Actions.schoolSettings_delete_school_btn,
			});
			const id = getSchoolIdInStore();
			const result = await apiInstance.delete(`/api/v1/schools/${id}`);
			if (result && result.status === 200) {
				logEvent({
					category: Categories.school,
					action: Actions.schoolSettings_delete_school_success,
				});
				toast.success('Successfully Deleted!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000, // Toast message will be shown for 2 seconds
				});
				localStorage.clear();
				history.push('login');
			}
		} catch (error) {
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
		setShowModal(false);
	};

	const showError = (result) => {
		setErrorMessage(result);
		if (result) {
			window.scrollTo(0, 0);
		}
	};

	const handleLogoChange = (e) => {
		const selectedFile = e.target.files[0];

		if (selectedFile) {
			const allowedTypes = ['image/png', 'image/jpeg'];
			const maxSize = MAX_LOGO_SIZE; // Assuming MAX_LOGO_SIZE is defined elsewhere

			if (
				allowedTypes.includes(selectedFile.type) &&
				selectedFile.size <= maxSize
			) {
				setSchoolLogo(selectedFile);
				setErrorMessage('');
				formChanged.current = true;

				const reader = new FileReader();
				reader.onloadend = () => {
					setPreviewImage(reader.result);
				};
				reader.readAsDataURL(selectedFile);
			} else {
				setErrorMessage(
					selectedFile.size > maxSize
						? LOGO_LENGTH
						: 'Please select a valid image file (PNG or JPEG).'
				);
				setPreviewImage(origImage);
				setSchoolLogo('');
				e.target.value = '';
			}
		} else {
			setErrorMessage('');
			setPreviewImage(origImage);
			setSchoolLogo('');
		}
	};

	const handleFormChange = async (event) => {
		event.preventDefault();
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	};

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue =
					'You have unsaved changes. Are you sure you want to leave this page?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const closeUnsavedModal = () => setShowUnsavedModal(false);

	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
			return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		} else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	};

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);

		return () => {
			unblock();
		};
	}, [history]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const id = getSchoolIdInStore();
		logEvent({
			category: Categories.school,
			action: Actions.schoolSettings_submit_btn,
		});
		setErrorMessage('');
		if (
			schoolName.trim().length < MIN_LENGTH ||
			schoolName.trim().length > MAX_LENGTH
		) {
			setErrorMessage(Strings.organizationName + ' : ' + LENGTH_MAX);
			return;
		}

		if (!schoolLogo) {
			setErrorMessage('Logo Required');
			return;
		}

		const formData = new FormData();
		formData.append('school_name', schoolName.trim());
		setSchoolName(schoolName.trim());
		setSchoolNameInStore(schoolName.trim());
		formData.append('logo', schoolLogo);
		formData.append('timezone', timezone);
		try {
			if (!submitted && errorMessage === '') {
				setLoading(true);
				setSubmitted(true);
				const response = await apiInstance
					.put(`/api/v1/schools/${id}`, formData)
					.catch((error) => {
						setErrorMessage(
							error.response
								? error.response.data.statusMessage
								: 'Unknown error occurred'
						);
					});
				setLoading(false);
				if (response && response.status === 200) {
					logEvent({
						category: Categories.school,
						action: Actions.schoolSettings_submit_success,
					});
					allowNavigationRef.current = true;
					toast.success('Successfully Saved!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000,
					});
					const blocked = getUserBlockedStore();
					if (blocked) {
						setTimeout(() => {
							history.goBack();
						}, 2000);
					} else {
						setTimeout(() => {
							role === 'SuperAdmin'
								? history.push('/organizations')
								: history.push('/dashboard');
						}, 2000);
					}
				} else {
					setSubmitted(false);
				}
			}
		} catch (error) {
			setLoading(false);
			setSubmitted(false);
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	return (
		<div>
			<Breadcrumb
				links={[{ link: '/organizationSettings', title: Strings.schoolSettings }]}
			/>

			<div className='card-header card_headertitle'>
				<div className='card-title card_head'>
					<h4 className='card_head CardTitle text-uppercase component-heading  '>
						{Strings.schoolSettings}
					</h4>
				</div>
			</div>
			{errorMessage && (
				<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
					{errorMessage}
				</div>
			)}
			{dataFetchLoading ? (
				<div className='d-flex justify-content-center my-2'>
					<Spinner animation='border' variant='secondary' />
				</div>
			) : (
				<div className='card-body' style={{ background: '#fff' }}>
					<Form
						onSubmit={handleSubmit}
						onChange={handleFormChange}
						encType='multipart/form-data'
					>
						<div className='row'>
							<div className='col-lg-6 col-md-12'>
								<div className='col-12 mb-4'>
									<Form.Field className='double-height'>
										<h4 style={{ fontWeight: 600, color: 'black' }}>
											{Strings.schoolInformation}
										</h4>
									</Form.Field>
								</div>{' '}
								<div className='col-12 my-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.organizationName}
											<span className='font_red'>*</span>
										</label>
										<input
											placeholder={Strings.organizationName}
											type='text'
											value={schoolName}
											onChange={(e) =>
												setSchoolName(maxLength(e.target.value, 50))
											}
											required
										/>
									</Form.Field>
								</div>
								<div className='col-12 my-3'>
									<Form.Field className='double-height '>
										<label>
											{Strings.timezone}
											<span className='font_red'>*</span>
										</label>
										<div className='select-wrapper'>
											<select
												className='custom-select-settings'
												value={timezone}
												onChange={(e) => {
													formChanged.current = true;
													setTimezone(e.target.value);
												}}
											>
												<option value=''>Select</option>
												{timezonesWithOffsets.map(
													({ timezone, offset, value }, index) => (
														<option
															value={timezone}
															key={index}
															className='rtl-text-right'
														>
															{timezone} {offset}
														</option>
													)
												)}
											</select>
											<span className='custom-select-arrow'></span>
										</div>
									</Form.Field>
								</div>
								<div className='col-12 my-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.license}
											<span className='font_red'>*</span>
										</label>
										<input
											disabled
											type='text'
											value={`Expires on ${expiryDate}`}
										/>
									</Form.Field>
								</div>
								<div className='col-12 my-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.organizationLogo}
											<span className='font_red'>*</span>
										</label>
										<div className='col-4 file-input-container'>
											{previewImage && (
												<LazyLoadImage
													src={previewImage || schoolImage}
													style={
														!previewImage
															? { width: '150px', height: '150px' }
															: {}
													}
													className='image-preview'
													alt='Organization Logo'
													onError={(e) => {
														e.target.src = whiteImageSVG;
														e.target.style.width = '50px';
														e.target.style.height = '50px';
													}}
													effect='blur'
												/>
											)}
										</div>
										<input
											type='file'
											accept='image/png, image/jpeg'
											name='logo'
											onChange={handleLogoChange}
											className='mt-3'
										/>
									</Form.Field>
								</div>
							</div>

							
						</div>

						<div className='row mt-4 d-flex'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float-left ml-3'>
									<p
										className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
										onClick={() => {
											setShowModal(true);
										}}
									>
										{Strings.deleteButton}
									</p>
								</div>
								<div className='float-right mr-3'>
									<div className='float-left mr-3'>
										<span
											className='btn btn-secondary cancel-btn button-fixed'
											onClick={(event) => {
												event.preventDefault();
												history.goBack();
												logEvent({
													category: Categories.school,
													action: Actions.schoolSettings_cancel_btn,
												});
											}}
										>
											{Strings.cancelButton}
										</span>
									</div>

									<div className='float-left '>
										<div align='center'>
											<button
												className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
												type='submit'
											>
												{loading ? <Spinner size='sm' /> : Strings.submitButton}
											</button>
										</div>
									</div>
								</div>
								{/* {loading ? <Spinner className='float_right'/> : ""} */}
							</div>
						</div>
					</Form>
					<div>
						{showModal2 ? <LogoutModal /> : null}
						{showModal ? (
							<AlertModal
								closeModal={closeModal}
								header={Strings.deleteHeader}
								body={Strings.deleteAccountAlertMsg}
								handlerFunction={deleteAccount}
							/>
						) : null}
						{showUnsavedModal ? (
							<AlertModal
								closeModal={closeUnsavedModal}
								header={Strings.unsavedChanges}
								body={Strings.unsavedChangesText}
								handlerFunction={handleNavigationConfirmation}
							/>
						) : null}
					</div>
				</div>
			)}
			<ToastContainer />
		</div>
	);
};

export default withRouter(AccountSettings);
