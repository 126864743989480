import React from "react";
import Modal from "react-bootstrap/Modal";
import QrReader from "react-qr-reader";
import { logEvent,Categories,Actions } from "../../analytics";
 
const QRScanModal = ({ closeModal, header, handleScanResult }) => {
	return (
		
		<div
			className='modal show'
			style={{ display: "block", position: "initial" }}
		>
			<Modal show={true} onHide={closeModal}>
				<Modal.Header closeButton>
					<Modal.Title>{header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <QrReader
                        delay={1000}
                        onScan={ async (scanData) => {
                            if (!!scanData) {
                                handleScanResult(scanData); 
								logEvent({category:Categories.device,action:Actions.device_Qr_scan_submitted});
                            }
                          }}
						  onError={ async (err) => {
							console.log("Scanning error",err);
						  }}
                        style={{ width: '100%' }}
                    />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default QRScanModal;
