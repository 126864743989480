import { withRouter } from "react-router-dom";
import React from "react";
import SignUpPage from "./SignupPage";
import {getRoleInStore} from '../../utils/apiUtils';

const SignUp = ({ history }) => {

	React.useEffect(() => {
		const role = getRoleInStore();
		if (role) {
			if (role && role === "SuperAdmin") {
				history.push("/organizations");
			} else {
				history.push("/dashboard");
			}
		}
	  }, []);

	const handleSignUp = (role) => {
		if (role && role === "SuperAdmin") {
			history.push("/organizations");
		} else {
			history.push("/dashboard");
		}
	};

	// const token = cookies.token;

	// if (token) {
	// 	history.push("/");
	// }

	return (
		<div>
			<SignUpPage onSignup={handleSignUp} />
		</div>
	);
};

export default withRouter(SignUp);
