import { withRouter } from "react-router-dom";
import React from "react";
import LoginPage from "./LoginPage";
import {getRoleInStore} from '../../utils/apiUtils'

const Login = ({ history, updateNotificationStatus }) => {

	React.useEffect(() => {
		const role = getRoleInStore();
		if (role) {
			if (role && role === "SuperAdmin") {
				history.push("/organizations");
			} else {
				history.push("/dashboard");
			}
		}
	  }, []);

	const handleLogin = (role) => {

		if (role && role === "SuperAdmin") {
			history.push("/organizations");
		} else {
			history.push("/dashboard");
		}
	};

	return (
		<div>
			<LoginPage onLogin={handleLogin} updateNotificationStatus={updateNotificationStatus}/>
		</div>
	);
};

export default withRouter(Login);
