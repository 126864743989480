import React from "react";
import { withRouter } from "react-router-dom";
import apiInstance from "../../../api";
import Breadcrumb from "../../breadcrumb/Breadcrumb";
import intl from "../../../locale/en-US.json";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { maxLength } from '../../../utils/apiUtils';
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Spinner } from "react-bootstrap";
import { Dropdown } from 'semantic-ui-react';
import { errorFunction } from '../../../utils/errors'
import LogoutModal from '../../Modal/LogoutModal'
import { useRef, useEffect, useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import useInputFocus from "../../../utils/customHooks/useInputFocus";
import { getExpiryColor } from "../../../utils/common";
import AlertModal from "../../Modal/AlertModal";
import Strings from '../../../locale/en-US.json';
import { logEvent,Categories,Actions } from "../../../analytics";

const SchoolDetails = ({ history, match }) => {
	const { id } = match.params;
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	const [schoolBlocked, setSchoolBlocked] = React.useState(false);
	const [staff, setStaff] = React.useState(0);
	const [admin, setAdmin] = React.useState({});
	const [registeredstaffs, setregisteredStaffs] = React.useState();
	const [student, setStudent] = React.useState(0);
	const [registeredstudents, setregisteredStudents] = React.useState();
	const [badge, setBadge] = React.useState(0);
	const [registeredbadges, setregisteredBadges] = React.useState();
	const [device, setDevice] = React.useState(0);
	const [registeredDevices, setregisteredDevices] = React.useState();
	const [totalAlerts, setTotalAlerts] = React.useState();
	const [schoolName, setSchoolName] = React.useState("");
	const [expiryTimestamp, setExpiryTimestamp] = React.useState(null)
	const [expiryTimestampOriginal, setExpiryTimestampOriginal] = React.useState(null)
	const [errorMessage, setErrorMessage] = React.useState('');
	const [submitted, setSubmitted] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [showModal2, setShowModal2] = React.useState(false);
	const [plansDropdown, setPlansDropdown] = React.useState([]);
	const [allPlans, setAllPlans] = React.useState([]);
	const [planSelected, setPlanSelected] = React.useState("");
	const [expiryColorClass, setExpiryColorClass] = React.useState("");
	const [schoolBlockedOriginal, setSchoolBlockedOriginal] = React.useState(false);
	const isAnyInputFocused = useInputFocus();
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);


	React.useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);


	React.useEffect(() => {
		const getData = async () => {
			try {
				const response = await apiInstance.get(`/api/v1/schools/${id}`);
				setSchoolBlocked(response.data.school.school.is_blocked);
				setSchoolBlockedOriginal(response.data.school.school.is_blocked);
				const expiryDate = moment(new Date(response.data.school.school.expiry_date));
				setExpiryColorClass(getExpiryColor(response.data.school.school.expiry_date));
				setExpiryTimestamp(expiryDate.format('YYYY-MM-DD HH:mm:ss'));
				setExpiryTimestampOriginal(expiryDate.format('YYYY-MM-DD HH:mm:ss'));
				setSchoolName(response.data.school.school.name);
				setStaff(response.data.school.school.allowed_teachers);
				setStudent(response.data.school.school.allowed_students);
				setBadge(response.data.school.school.allowed_badges);
				setDevice(response.data.school.school.allowed_devices);
				setPlanSelected(response.data.school.school.license_id._id);
				setregisteredBadges(response.data.school.registeredBadges);
				setregisteredDevices(response.data.school.registeredDevices);
				setregisteredStaffs(response.data.school.registeredTeachers);
				setregisteredStudents(response.data.school.registeredStudents);
				setAdmin(response.data.school.admin);
				setTotalAlerts(response.data.school.totalAlerts);
			} catch (error) {
				const result = errorFunction(error)
				//console.log("error-------- ", result)
				if (typeof result === 'boolean') {
					setShowModal2(true);
				} else {
					setErrorMessage(result);
				}
			}
			
		};

		const getAllPlans = async () => {
			try {
				const response = await apiInstance.get(`/api/v1/plans/`);
				setAllPlans(response.data.data.plans);
				setPlansDropdown(response.data.data.plans.map((plan,i)=>  {
					return {key:i, text:plan.license_type, value: plan._id}
				}))
			} catch (error) {
				const result = errorFunction(error)
				//console.log("result-------- ", result)
				if (typeof result === 'boolean') {
					setShowModal2(true);
				} else {
					setErrorMessage(result);
				}
			}
		}

		getData();
		getAllPlans();
	}, []);

	const handleFormChange = async (event) => {
		event.preventDefault();
		console.log(event.nativeEvent.inputType);
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	}

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
		  	}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);
	  

	const closeUnsavedModal = () => setShowUnsavedModal(false);
	
	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
				return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		}
		else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	  const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	  };

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);
	
		return () => {
		  unblock();
		};
	  }, [history]);


	const customTimeShow = (time) => {
		if (!expiryTimestamp) {
		  return '';
		}
		const selectedTime = moment(expiryTimestamp).format('HH:mm');
		const currentTime = moment(time).format('HH:mm');
		return selectedTime === currentTime ? 'selected-time' : '';
	  };
	 
	const handlePlan = (id) =>{
		formChanged.current =true
		setPlanSelected(id);

		const plan = allPlans.filter(plan => plan._id === id)
		setStaff(plan[0].allowed_teachers);
		setStudent(plan[0].allowed_students);
		setBadge(plan[0].allowed_badges);
		setDevice(plan[0].allowed_devices);

	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		logEvent({category:Categories.school,action:Actions.school_details_save_btn});
		try {
			setLoading(true);
			const updateSchoolData = new FormData();
			const expiryTimestampISO = moment(expiryTimestamp).toISOString();
			//const expiryTimestampISO = (new Date(expiryTimestamp)).toISOString();
			updateSchoolData.append('allowed_teachers', staff);
			updateSchoolData.append('allowed_students', student);
			updateSchoolData.append('allowed_badges', badge);
			updateSchoolData.append('allowed_devices', device);
			updateSchoolData.append('is_blocked', schoolBlocked);
			updateSchoolData.append('expiry_date', expiryTimestampISO);
			updateSchoolData.append('license_id', planSelected);
			if (!submitted) {
				setSubmitted(true)
				const response = await apiInstance.put(`/api/v1/schools/${id}`, updateSchoolData)
				.catch((error) => {
					setErrorMessage(
						error.response
							? error.response.data.statusMessage
							: 'Unknown error occurred'
					);
				});
				setLoading(false);
				if (response && response.status === 200) {
					if(expiryTimestampOriginal !== expiryTimestamp){
						logEvent({category:Categories.school,action:Actions.school_details_expiry_settings});		
					}
					if(schoolBlockedOriginal !== schoolBlocked){
						logEvent({category:Categories.school,action:Actions.school_details_activation_settings,value:schoolBlocked});
			     	}
					logEvent({category:Categories.school,action:Actions.school_details_save_success});
					allowNavigationRef.current = true;
					toast.success('Successfully Saved!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000,
					});

					setTimeout(()=>{
						history.push('/organizations');
					}, 2000)
				} else {
					setSubmitted(false)
				}
			}
		
		}
		catch(error) {
			//console.log("error", error);
			setSubmitted(false)
			setLoading(false)
			const result = errorFunction(error)
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}

		}
	};

	const handleDateChange = (selectedDate) => {
		formChanged.current =true
		const expirymoment = moment(selectedDate);
		setExpiryTimestamp(expirymoment.format('YYYY-MM-DD HH:mm:ss'));
		setExpiryColorClass(getExpiryColor(expirymoment.format('YYYY-MM-DD HH:mm:ss')));
	};
	return (
		<div>
			<div
				className='col-lg-12 col-md-12 col-sm-12 col-12'
			>
			<Breadcrumb
				links={[
					{ link: "/organizations", title: intl.organizations},
					{ title: intl.organizationDetails },
				]}
				

			/>
			</div>

			<div className='card-header card_headertitle mt-2'>
				<h4>{intl.admin}</h4>
				<div className="row">
					<div className='col-lg-3 col-md-6 col-sm-12 col-12'>
						<label className=''>
							{intl.Name} : <strong className="word-break card_head CardTitle  "> {admin ? admin.first_name+' '+ admin.last_name :""}</strong>
						</label>
					</div>
					<div className='col-lg-3 col-md-6 col-sm-12 col-12'>
						<label className=''>
							{intl.email} : <strong className="word-break card_head CardTitle  ">{admin && admin.email}</strong>
						</label>
					</div>
					<div className='col-lg-3 col-md-6 col-sm-12 col-12'>
						<label className=''>
							{intl.phoneNo} : <strong className="word-break card_head CardTitle  ">{admin && admin.phone_no}</strong>
						</label>
					</div>
					{/* <div className='col-lg-3	 col-md-6 col-sm-12 col-12'>
						<label className=''>
							{intl.gender} : <strong className="word-break card_head CardTitle  ">{admin && admin.gender}</strong>
						</label>
					</div> */}
				</div>
			</div>
			
			<div className='card-header card_headertitle d-flex flex-wrap justify-content-between mt-4 school-name-padding'>
					<div className='card-title card_head pt-2 col-lg-9 col-md-12 col-sm-12'>
						<h4 className='card_head CardTitle text-uppercase component-heading  '>
						<span>{schoolName} </span>
						</h4>
					</div>
					
					<div className='card-title card_head pt-2 col-lg-3 col-md-12 col-sm-12'>
						<h6 className='card_head CardTitle   school-name-padding float_right'>
						<span>{intl.totalAlerts}: {totalAlerts}</span>
						</h6>
					</div>
			</div>
			{errorMessage && (
					<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
						{errorMessage}
					</div>
			)}
			
			<div className='card-body blocked-class'>
				<form id='PersonSaveForm' onChange={handleFormChange} onSubmit={handleSubmit} action=''>
					<div className="row">
						<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
							<label className='form-label'>{intl.homePlan}</label>
							<div className='form-group'>
								<span className='GenderGroup' >
									<Dropdown
										options={plansDropdown}
										required
										selection
										placeholder='Plan'
										value={planSelected}
										className="plan_dropdown"
										onChange={(e, { value }) => handlePlan(value)}
									/>
								</span>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='form-group'>
								<label className='form-label'>
									{intl.staffCount}
									<span className='font_red'>* </span>
									({intl.registered} : {registeredstaffs} )
								</label>
								<input
									type='number'
									className='form-control'
									value={staff}
									onChange={(event) => setStaff(maxLength(event.target.value, 6))}
									required
								/>
							</div>
						</div>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='form-group'>
								<label className='form-label'>
									{intl.studentCount}
									<span className='font_red'>* </span>
									({intl.registered} : {registeredstudents} )
								</label>
								<input
									type='number'
									className='form-control'
									value={student}
									onChange={(event) => setStudent(maxLength(event.target.value, 6))}
									required
								/>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='form-group'>
								<label className='form-label'>
									{intl.badgesCount}
									<span className='font_red'>* </span>
									({intl.registered} : {registeredbadges})
								</label>
								<input
									type='number'
									className='form-control'
									value={badge}
									onChange={(event) => setBadge(maxLength(event.target.value, 6))}
									required
								/>
							</div>
						</div>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='form-group'>
								<label className='form-label'>
									{intl.deviceCount}
									<span className='font_red'>* </span>
									({intl.registered} : {registeredDevices})
								</label>
								<input
									type='number'
									className='form-control'
									value={device}
									onChange={(event) => setDevice(maxLength(event.target.value, 6))}
									required
								/>
							</div>
						</div>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='form-group'>
								<label className={`form-label`}>
									{intl.expiryDate}:
								</label><br></br>
								{expiryTimestamp && (
								<div className="d-flex ">
									<DatePicker
										selected={moment(expiryTimestamp).toDate()}
										onChange={handleDateChange}
										showTimeSelect
										timeFormat="HH:mm"
										timeIntervals={15}
										className="cursor"
										timeCaption="Time"
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										dateFormat="d MMM yyyy, h:mm aa"
										customTimeClassName={customTimeShow}
									/>
									{ expiryColorClass && expiryColorClass ==='red-text' ?
									<p style={{marginLeft:'7.5em'}} className={`mt-2 ${expiryColorClass}`}>{intl.expired}</p>
									:""}
								</div>
								)}
								
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="form-group">
								<label className="form-label">{intl.status}:</label>
								<br />
								<ButtonGroup>
									<Button
										variant={schoolBlocked ? 'secondary' : 'btn ActionButton PersonBtnadd PersonBtnloader'}
										onClick={() => {formChanged.current =true
											setSchoolBlocked(false)}}
									>
										{intl.activated}
									</Button>
									<Button
										variant={schoolBlocked ? 'btn ActionButton PersonBtnadd PersonBtnloader' : 'secondary'}
										onClick={() => {
											formChanged.current =true
											setSchoolBlocked(true)}}
									>
										{intl.blocked}
									</Button>
								</ButtonGroup>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
							<div className='float_right mr-3'>
								<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading ? <Spinner size="sm" animation="border" variant="secondary" /> : intl.saveButton}
										</button>
							</div>

							<div className='float_right mr-3'>
								<button
									className='btn btn-secondary cancel-btn button-fixed'
									onClick={(e) => 
										
										{ e.preventDefault();
											history.push("/organizations");
											logEvent({category:Categories.school,action:Actions.school_details_cancel_btn});
										}
										}
								>
									{intl.cancelButton}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			{showUnsavedModal ? (
				<AlertModal
					closeModal={closeUnsavedModal}
					header={Strings.unsavedChanges}
					body={Strings.unsavedChangesText}
					handlerFunction={handleNavigationConfirmation}
				/>
			) : null}

			{showModal2 ? (
					<LogoutModal/>
				) : null}
			<ToastContainer />
		</div>
	);
};

export default withRouter(SchoolDetails);
