import React, { useState, useEffect, useRef } from 'react';
import { FaTimes, FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import apiInstance from '../../api';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import AlertModal from '../Modal/AlertModal';
import { Spinner } from 'react-bootstrap';
import { Pagination } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {
	deleteEtag,
	getEtag,
	setEtag,
	setListInStore,
	STUDENTS_ETAG_KEY,
	STUDENTS_LIST_KEY,
	maxLength,
	getListInStore,
	getPageAndCountInStore,
	STUDENTS_PAGECOUNT_KEY,
	setPageAndCountInStore,
} from '../../utils/apiUtils';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import BulkAddModal from "../Modal/BulkAddModal";
import { logEvent,Categories,Actions } from "../../analytics";
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';

const PAGE_OFFSET = 15;

const Student = ({ history }) => {
	const [searchText, setSearchText] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [StudentList, setStudentList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [showBulkAddModal, setShowBulkAddModal2] = useState(false);
	const [disableDelete, setDisableDelete] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(true);
	const [showHyphen, setShowHyphen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showModal2, setShowModal2] = useState(false);
	const [count, setCount] = useState(0);
	const timeoutIdRef = useRef(null);
	const pollingIntervalRef = React.useRef(null);
	const [disablePagination, setDisablePagination] = useState(false);
	const [selectedStudents, setSelectedStudents] = useState([]); // for bulk delete
 	const [bulkDelete, setBulkDelete] = useState(false); // for showing selected message and select all option
	const [deleteAll, setDeleteAll] = useState(false); // for Total records delete in DB option
	const [deleteAllCheckbox, setDeleteAllCheckbox] = useState([]); // for storing state of top checkbox on different pages
	const isAnyInputFocused = useInputFocus();

	useEffect(() => {
		deleteEtag(STUDENTS_ETAG_KEY);
		setLoading(true);
		setActivePage(1);
		fetchData();
		return () => {
			setLoading(false);
		};
	}, [searchTerm, activePage]);
  
	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);

	useEffect(() => {
		deleteEtag(STUDENTS_ETAG_KEY);
		const storedList = getListInStore(STUDENTS_LIST_KEY);
		const pageCountList = getPageAndCountInStore(STUDENTS_PAGECOUNT_KEY)
		if (storedList && pageCountList) {
			const data = JSON.parse(pageCountList);
			setTotalPages(data.totalPages);
			setCount(data.count);
			setStudentList(JSON.parse(storedList));
		}
	
		visibilityChangeHandler()

		document.addEventListener('visibilitychange', visibilityChangeHandler);
		return () => {
			stopPolling(pollingIntervalRef);
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
		}
		document.removeEventListener("visibilitychange", visibilityChangeHandler);
		};
	}, [activePage, searchTerm]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	async function fetchData(refreshAfterDelete) {
		const lowercasePathname = window.location.pathname.toLowerCase();
			if (!lowercasePathname.includes('users')) {
				stopPolling(pollingIntervalRef);
			  return;
		}
		// setErrorMessage('');
		setActivePage(refreshAfterDelete && refreshAfterDelete ===1 ? 1:activePage);

		try {
			// setLoading(true);
			const headers = {};
			const storedEtag = getEtag(STUDENTS_ETAG_KEY);
			if (storedEtag) {
				headers['If-None-Match'] = storedEtag;
			}
			const url = `/api/v1/users?role=${intl.roleStudent}`;
			const params = {
				page: refreshAfterDelete === 1 ? refreshAfterDelete : activePage,
				limit: PAGE_OFFSET,
			};

			if (searchTerm) {
				params.search = searchTerm;
			}
			stopPolling(pollingIntervalRef);
			const res = await apiInstance.get(url, {
				headers,
				params,
			});
			//const res = await apiInstance.get(url, config);

			if (res && res.status === 304) {
				setErrorMessage('');
				setLoading(false);
				setDisablePagination(false);
				// Not modified, no need to update data
				return;
			}
			if (res && res.status === 200) {
				setStudentList(res.data.data.users);
				setEtag(STUDENTS_ETAG_KEY, res.headers.etag);
				setTotalPages(res.data.data.totalPages);
				setCount(res.data.data.totalUsers);
				setListInStore(STUDENTS_LIST_KEY, JSON.stringify(res.data.data.users));
				const pageAndCount = {count:res.data.data.totalUsers,
					totalPages:res.data.data.totalPages}
				setPageAndCountInStore(STUDENTS_PAGECOUNT_KEY, JSON.stringify(pageAndCount));
				setLoading(false);
				setDisablePagination(false);
				setErrorMessage('');
			}
			startPolling(fetchData, pollingIntervalRef);
		} catch (error) {
			startPolling(fetchData, pollingIntervalRef);
			if (error.response && error.response.status === 304) {
				setLoading(false);
				setErrorMessage('');
				return;
			}
			setLoading(false);
			const result = errorFunction(error);
			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}
		}
	}

	const onChange = (e, pageInfo) => {
		// reset if page change when total records are selected
		if(deleteAll) {
			setBulkDelete(false);
			setDeleteAll(false);
			setSelectedStudents([]);
			setDeleteAllCheckbox([])
		}
		setActivePage(pageInfo.activePage);
		setLoading(true);
		setDisablePagination(true);
	};

	const closeModal = () => setShowModal(false);

	const closeBulkAddModal = () => {
		setShowBulkAddModal2(false);
		fetchData();
		logEvent({category:Categories.student,action:Actions.student_bulk_upload_cancel_btn});
	}


	const onSearchClear = async () => {
		setSearchTerm('');
		setSearchText('');
		setDeleteAllCheckbox([]);
	};

	// checkboxes handling
	const handleDeleteCheckbox = (e) => {
		// setBulkDelete(true);
		const { value, checked } = e.target;
		if (!checked) {
			setBulkDelete(false);
			setDeleteAll(false);
			setDeleteAllCheckbox(deleteAllCheckbox.filter(number => number !== activePage));
			setSelectedStudents(selectedStudents.filter(id => id !== value));
			selectedStudents.length <= 1 ? setShowHyphen(false) : setShowHyphen(true); 
		}
		else {
			setSelectedStudents([...selectedStudents, value]);
			if (areAllCheckboxesChecked([...selectedStudents, value])) {
				setShowHyphen(false);
 				let checkBox = deleteAllCheckbox;
				checkBox.push(activePage);
				setDeleteAllCheckbox([...checkBox]);
			} 
			else {
				setShowHyphen(true);
			}
		}
		// console.log([...selectedStaff, value])
 	}

	const areAllCheckboxesChecked = (arr) => {
		return StudentList.every((student) => arr.includes(student._id));
	};

	const convertDeleteData = (users) => {
		return users.map(user => {
			return {id:user, role:intl.roleStudent}
		});
	}

	const handleBulkDelete = async () => {
		setShowHyphen(false)
		closeModal();
		logEvent({category:Categories.student,action:Actions.student_delete_btn});
		setDisableDelete(true);
		const requestData = convertDeleteData(selectedStudents); 
		try {
			setSelectedStudents([]);
			if (selectedStudents.length !== 0) {
				let result;
				setLoading(true);

				if (deleteAll) {
					result = await apiInstance.delete(`/api/v1/users/?deleteAll=true&role=${intl.roleStudent}`);
				} else {
					result = await apiInstance.delete(`/api/v1/users/?deleteAll=false`, {data: requestData});
				}	

				if (result && result.status === 200) {
					logEvent({category:Categories.student,action:Actions.student_delete_success});
					toast.success('Successfully Deleted!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 1000,
					});
					setDisableDelete(false);
					setBulkDelete(false);
					setDeleteAllCheckbox([]);
					setDeleteAll(false);
					// when at last page and all are selected
					if (activePage === totalPages && selectedStudents.length === StudentList.length) {
						// console.log("activePage === totalPages: ", activePage === totalPages);
						// console.log("selectedStudents.length === StudentList.length: ", selectedStudents.length === StudentList.length);
						if (searchTerm !== '' || searchText !== '') {
							setSearchTerm('');
							setSearchText('');
						} else {
							setActivePage(1);
							fetchData(1);
						}
					}
					// when all selected OR more are selected, only one is remaining on last page
					else if (selectedStudents.length >= PAGE_OFFSET || selectedStudents.length === StudentList.length ) {
						// console.log("selectedStudents.length >= PAGE_OFFSET ", selectedStudents.length >= PAGE_OFFSET);
						// console.log("selectedStudents.length === StudentList.length ", selectedStudents.length === StudentList.length);
						// console.log("selectedStudents.length - 1 === 0 ", selectedStudents.length - 1 === 0);
						activePage === 1 ? fetchData() : setActivePage(1);

						// setActivePage(1);
					}
					// when 1 or more selected 
					else fetchData();
				}
			}
		} catch (error) {
			setLoading(false);
			setDeleteAllCheckbox([]);
			setDeleteAll(false);
			setBulkDelete(false);
			setDisableDelete(false);
			const result = errorFunction(error);
			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}
		}
		setShowModal(false);
	}

	const handleDeleteAllOnOnePage = (e) => {
		const { checked } = e.target;
		if (StudentList.length !== 0) setBulkDelete(true);
		let staffArr = [];
		StudentList.forEach((student) => staffArr.push(student._id));
		if (!checked) {
			setDeleteAll(false);
			setBulkDelete(false);
			setDeleteAllCheckbox(
				deleteAllCheckbox.filter(
					(deletePageNumber) => deletePageNumber !== activePage
				)
			);
			setSelectedStudents(
				selectedStudents.filter((staff) => !staffArr.includes(staff))
			);
			selectedStudents.length === 0 && setBulkDelete(false);
		} else {
			// for making the top checkbox remain checked in all pages where it is selected
			let checkBox = deleteAllCheckbox;
			checkBox.push(activePage);
			setDeleteAllCheckbox([...checkBox]);

			staffArr = staffArr.filter((staff) => !selectedStudents.includes(staff));
			setSelectedStudents([...selectedStudents, ...staffArr]);
		}
	};

	const resetStates = () => {
		setSelectedStudents([]);
		setDeleteAllCheckbox([])
		setDisableDelete(false);
		setBulkDelete(false);
		setDeleteAll(false);
	}

	return (
		<div>
			<Breadcrumb links={[{ link: '/users', title: intl.studentList }]} />
			<div className='container-fluid'>
				<div className='row page-titles'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
						<div className='card CardNoBorder' id='PersonForm'>
							<div className='card-header card_headertitle'>
								<div className='card-title card_head'>
									<div className='row d-flex align-items-center'>
										<div className='col-lg-2 col-md-2 col-sm-6 col-6 d-flex align-items-center'>
											<h4 className='card_head CardTitle text-uppercase component-heading  '>
												{intl.students}
											</h4>
										</div>
										<div className=' col-lg-5 col-md-6 col-sm-8 col-8 d-flex'>
											<input
												id='searchName'
												type='search'
												placeholder={intl.searchButton}
												className='w-100 search-input'
												style={
													searchText.length > 1
														? {
																borderRight: '0px solid red',
																borderTopRightRadius: '0em',
																borderBottomRightRadius: '0em',
														  }
														: null
												}
												value={searchText}
												// ref={searchInputRef}
												onKeyDown={(e) => {
													if (e.keyCode === 13) {
														setSearchTerm(searchText);
														setActivePage(1);
														resetStates();
													}
												}}
												onChange={(e) => {
													maxLength(e.target.value, 255);
													setSearchText(e.target.value);
												}}
											/>

											{searchText.length > 1 ? (
												<span
													className='cancel-container'
													onClick={() =>
														{
															searchText.length > 1 && onSearchClear();
															resetStates();														 
														}
													}
												>
													<FaTimes id='btnClear' className='cancel-icon' />
												</span>
											) : null}
										</div>
										<div className='col-lg-1 col-md-2 col-sm-4 col-4'>
											<div className='float_left mr-3'>
												<input
													type='button'
													onClick={() => {
														setSearchTerm(searchText);
														setActivePage(1);
															logEvent({category:Categories.student,action:Actions.student_search_btn});
														resetStates();
													}}
													className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
													value={intl.searchButton}
													id='PersonBtn'
												/>
											</div>
										</div>
										<div className=' col-lg-4 col-md-6 col-sm-12 col-12 bulk_buttons_div  '>
											<span
												title='Add User'
												className='btn AddButton btn-sm bulk_buttons'
												onClick={() => {history.push('/addUser');logEvent({category:Categories.student,action:Actions.student_add_btn});}}
											>
												<i>
													<FaPlus />
												</i>{' '}
												{intl.addStudentButton}
											</span>
											<span
												title='Add Users'
												className='btn AddButton btn-sm bulk_buttons ml-2 mr-2'
												onClick={() => {setShowBulkAddModal2(true);logEvent({category:Categories.student,action:Actions.student_bulk_upload_btn});}}
											>
												<i>
													<FaPlus />
												</i>{' '}
												{intl.bulkuploadButton}
											</span>
											{selectedStudents.length > 0 && <span	title='Delete'
												className='btn AddButton btn-sm bulk_buttons'
												onClick={() =>
													selectedStudents.length >= 1 && setShowModal(true)
												}
											>
												<i>
													<FaTrashAlt />
												</i>{'  '}
												{intl.deleteButton}
											</span>}
											{/* <span
												title='Add Staff'
												className='float_right mr-3 mt-1 d-flex align-items-center'
												onClick={() =>
													selectedStudents.length >= 1 && setShowModal(true)
												}
											>
												<FaTrashAlt
													className='iconAction-danger'
													title='Delete'
													onClick={() => {
														// setDeviceId(device._id);
														// setShowModal(true);
													}}
												/>
											</span> */}
										</div>
									</div>
								</div>
							</div>
							{bulkDelete && searchTerm === '' && searchText === '' && totalPages > 1 ?(
								<div className='d-flex justify-content-center align-items-center pt-4' style={{marginBottom:'-0.5em'}}>
									<h6 className='' style={{padding:'0.5em', marginLeft:'0.7em'}}>
										{deleteAll ? `All ${count} records are selected`  : ` ${selectedStudents.length} records are selected`}
									</h6>
									{!deleteAll ? (
										<h6
											className='ml-4 delete-all '
											onClick={() => setDeleteAll(true)}
										>
											Select all {count} records
										</h6>
									) : (
										<h6 onClick={() => {
											setDeleteAll(false);
											setSelectedStudents([])
											setBulkDelete(false);
											setDeleteAllCheckbox([])
										}} className='delete-all'>Clear selection</h6>
									)}
								</div>
							):null}
							<div className='card-body DataTable PersonGrid'>
								<div className='table-responsive'>
									{errorMessage && (
										<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
											{errorMessage}
										</div>
									)}
									{loading ? (
										<div className="d-flex justify-content-center align-items-center">
											<Spinner animation="border" variant="secondary" />
										</div>
									) : (
										<table className='table table-bordered'>
											<thead>
												<tr>
													<th
														style={{ paddingTop: '11px' }}
														className='pb-0 d-flex align-items-center justify-content-center'
													>
														<div className="input-label-container">
															<input
																type='checkbox'
																id='selectAll'
																name='selectAll'
																value='selectAll'
																disabled={(StudentList.length === 0 && true) || disableDelete}
																onChange={(e) => {
																	setShowHyphen(false);
																	handleDeleteAllOnOnePage(e)}
																}
																checked={deleteAll || deleteAllCheckbox.includes(activePage)}
															/>
															<label htmlFor='selectAll'></label>
															{showHyphen ? (<span onClick={(e) => {
																setShowHyphen(false);
																handleDeleteAllOnOnePage(e)}
															} className="hyphen hover-pointer ">-</span>): ""}
														</div>
													</th>
													<th scope='col'>{intl.Name}</th>
													{/* <th scope='col'>{intl.gender}</th> */}
													<th scope='col'>{intl.badge}</th>
													<th scope='col'>{intl.sendNotification}</th>
													<th scope='col' style={{ width: '10%' }}>
														{intl.actions}
													</th>
												</tr>
											</thead>
											<tbody>
												{StudentList && StudentList.length ? (
													StudentList.map((Student, i) => {
														return (
															<tr key={i}>
																<td
																	className='pb-0 px-0 d-flex align-items-center justify-content-center'
																	style={{
																		border: 'none',
																		borderColor: 'none',
																		paddingTop: '14px'
																	}}
																>
																	<input
																		type='checkbox'
																		id={Student._id}
																		name={Student._id}
																		value={Student._id}
																		disabled={disableDelete}
																		onChange={(e)=>handleDeleteCheckbox(e)}
																		checked={deleteAll || (selectedStudents.includes(Student._id) && true)}
																	/>
																	<label htmlFor={Student._id}></label>
																</td>
																<td>
																	{Student.first_name} {Student.last_name}
																</td>
																{/* <td>{Student.gender}</td> */}
																<td>
																	{Student.mac_address
																		? Student.mac_address
																		: '-'}
																</td>
																<td>{ Student.mac_address ? (Student.send_notification === false ?<label className='notificationOff'>Off</label>: <label className='notificationOn'>On</label>): "NA"}</td>
																<td>
																	<div className='d-flex'>
																		<FaEdit
																			className='iconAction-edit'
																			title='Edit'
																			onClick={() =>{
																				history.push({
																					pathname: `/editUser/${Student._id}`,
																					state: { data: Student },
																				});
																				logEvent({category:Categories.student,action:Actions.student_edit_btn});
																			 }
																			}
																		/>
																	</div>
																</td>
															</tr>
														);
													})
												) : (
													<tr>
														<td colSpan='5' style={{ textAlign: 'center' }}>
															{intl.noRecordFound}
														</td>
													</tr>
												)}
											</tbody>
										</table>
									)}
									{showModal ? (
										<AlertModal
											closeModal={closeModal}
											header={intl.deleteHeader}
											body={
												selectedStudents.length > 1
													? intl.bulkDelete
													: intl.deleteBody
											}
											handlerFunction={handleBulkDelete}
										/>
									) : null}
									{(StudentList && StudentList.length) > 0 ? (
										<div className='count_div'>
											<Pagination
												boundaryRange={1}
												ellipsisItem='...'
												firstItem={null}
												lastItem={null}
												activePage={activePage}
												onPageChange={onChange}
												siblingRange={1}
												totalPages={totalPages}
												disabled={disablePagination}
												className={totalPages === 1 ? 'paginationbutton' : null}
											/>
											<div>Total: {count}</div>
										</div>
									) : (
										''
									)}
									<ToastContainer />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showModal2 ? <LogoutModal /> : null}
			{showBulkAddModal && (
				<BulkAddModal
					closeModal={closeBulkAddModal}
					header={'Bulk upload Users'}
					type={'student'}
					columnNames={['first_name', 'last_name', 'mac_address','send_notification']}
					route={'/api/v1/users/register'}
				/>
			)}
		</div>
	);
};

export default withRouter(Student);
