import React, {useEffect, useRef, useState} from 'react'
import './verifyphone.css'
import apiInstance from '../../api'
import {removePhoneNumberInStore } from "../../utils/apiUtils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import Strings from '../../locale/en-US.json';

const VerifyPhone = ({closeModal, currentphno}) => {
    const inputRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
    ];

    const [inputValues, setInputValues] = useState(Array(6).fill(''));
    const [submitted, setSubmitted] = useState(false)
    const [resendSubmitted, setResendSubmitted] = useState(false)
    const [resendloading, setResendLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const timeoutRef = useRef(null)

    useEffect(() => {
        return () => {
          if (timeoutRef.current) { //clear debounce timeout
              clearTimeout(timeoutRef.current);
          }
        };
      }, []);
      
    const handleInputChange = (index, event) => {
        const input = event.target;
        const value = input.value;
    
        // Only allow one number in the input field
        if (value.length > 1) {
          input.value = value.slice(0, 1);
        }

        // Move to the next input if there's a value, unless Tab key is pressed or it's the last input
        // if (value && index < inputRefs.length - 1 && event.keyCode !== 9 /* Tab key */) {
        //     inputRefs[index + 1].current.focus();
        // }
    
        // Move to the next input if there's a value
        if (value && index < inputRefs.length - 1) {
          inputRefs[index + 1].current.focus();
        }
    
        // Update the inputValues state with the new value
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };
    
    const handleBackspace = (index, event) => {
        
        const allowedKeys = [
            'Backspace',
            'Delete',
            'ArrowLeft',
            'ArrowRight',
            'Tab',
        ];

        // Allow Ctrl + V (Control key + V key)
        if (
            event.key === 'v' &&
            (event.ctrlKey || event.metaKey)
        ) {
            // Continue with the default paste behavior
            return;
        }

        if (
            !allowedKeys.includes(event.key) &&
            (isNaN(Number(event.key)) || event.key === ' ')
        ) {
            event.preventDefault();
        }

        if (event.keyCode === 13) {
            submit();
            return;
        }
        
        else if (index > 0 && event.keyCode === 8 && !inputRefs[index].current.value) {
          // If backspace is pressed and the current input is empty, go to the previous input
          inputRefs[index - 1].current.focus();
          return;
        }
    
        // Update the inputValues state after backspace
        const newInputValues = [...inputValues];
        newInputValues[index] = '';
        setInputValues(newInputValues);
    };
    

    const submit = async () => {

        setErrorMsg('');

        if (inputValues.join("").length < 6 ) {
            setErrorMsg("Please enter full code");
            return;
        }

        try {
            setLoading(true);
            let result;
			if (!submitted) {
				setSubmitted(true);
				result = await apiInstance.post(`api/v1/auth/verify-phone/${inputValues.join("")}`)
			}
            if (result && result.status === 200) {
                setLoading(false);
                toast.success('Successfully Verified!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
				});

                removePhoneNumberInStore();
                setTimeout(() => {
					closeModal();
				}, 2000);
            }
           
        } catch (error) {
            let errorMsg = 'Error Occured';
			setLoading(false);
			setSubmitted(false);

            if (error) {
				console.error('------ ', error);
				if (typeof error === 'string') {
					errorMsg = error;
				} else if (
					error.response &&
					error.response.data &&
					error.response.data.statusMessage
				) {
					errorMsg = error.response.data.statusMessage;
				}
			}
			setErrorMsg(errorMsg);
        }

    }

    const resendCode = async() => {
        setErrorMsg("")
        try {
            if (!resendSubmitted) {
                setResendLoading(true)
                setResendSubmitted(true)
                const result = await apiInstance.post('/api/v1/auth/resend-verification-phone')
                setResendLoading(false)
                if (result.status === 200) {
                    toast.success('New Code Sent!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                    });
                }
                timeoutRef.current = setTimeout(() => {
                    setResendSubmitted(false);
                }, 2000);
            }
        } catch (error) {
            let errorMsg = 'Error Occured';
			setResendLoading(false);
			timeoutRef.current = setTimeout(() => {
                setResendSubmitted(false);
            }, 2000);

            if (error) {
				console.error('------ ', error);
				if (typeof error === 'string') {
					errorMsg = error;
				} else if (
					error.response &&
					error.response.data &&
					error.response.data.statusMessage
				) {
					errorMsg = error.response.data.statusMessage;
				}
			}
            toast.error(errorMsg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
            });
        }
       
    }
    
    return (
    <div className='wrapper'>
        <p>{Strings.smsNotification}</p>
        <div className='input_wrapper'>
            {inputRefs.map((ref, index) => (
                <input
                    key={index}
                    ref={ref}
                    type='number'
                    className='number_box'
                    placeholder='-'
                    maxLength={1}
                    onChange={(e) => handleInputChange(index, e)}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    value={inputValues[index]} // Bind the value from state to the input
                />
            ))}
        </div>
        <div className='button_wrapper'>
            <button
                className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
                type='button'
                onClick={() => submit()}
            >
                {loading ? <Spinner size='sm' animation="border" variant="secondary" /> :  Strings.submitButton}
            </button>
            
            {resendloading  ? <div className='mt-1'><Spinner animation="border" variant="secondary" /></div> : <p className="resend_code" onClick={()=> resendCode()}>{Strings.resendCode}</p>}
                <p className='code_send'> {Strings.codeWillBeSent} {currentphno}</p>
            {errorMsg ? <p style={{color:"red", fontSize:'small'}}>{errorMsg}</p>  : null}
        </div>
			<ToastContainer />
    </div>
  )
}

export default VerifyPhone