import { withRouter } from 'react-router-dom';
import React, { useState } from "react";
import apiInstance from "../../api";
import Strings from "../../locale/en-US.json";
import { useRef, useEffect } from "react";
import { getSchoolIdInStore, getSchoolNameInStore } from "../../utils/apiUtils";
import { errorFunction } from "../../utils/errors";
import LogoutModal from "../Modal/LogoutModal"
import intl from '../../locale/en-US.json'
import { blockPageStartPolling, stopPolling } from '../../utils/polling';
const BlockPage = ({history}) => {
	
	const [verificationMessage, setVerificationMessage] = useState("");
	const [contactMessage, setContactMEssage] = useState("");
	const pollingIntervalRef = useRef(null);
	const id = getSchoolIdInStore();
	const schoolName = getSchoolNameInStore();
	const [showModal2, setShowModal2] = useState(false);
  
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
    	const statusCodeFromUrl = searchParams.get("statusCode");
		if (statusCodeFromUrl === "-38") {
			setVerificationMessage(intl.licenseBlock);
			setContactMEssage(intl.blockContactAdmin);
		  } else if (statusCodeFromUrl === "-39") {
			setVerificationMessage(intl.licenseExpire);
			setContactMEssage(intl.expireContactAdmin);
		  } else {
			// Set a default message if statusCode is not -38 or -39
			setVerificationMessage(intl.licenseInactive);
			setContactMEssage(intl.inactiveContactAdmin);
		  }
	  	blockPageStartPolling(id, pollAPI, pollingIntervalRef);
	  return () => {
		stopPolling(pollingIntervalRef);
	  };
	}, []);
  
	useEffect(() => {
		pollAPI();
	  // Check if the browser tab is focused or not
	  const handleVisibilityChange = () => {
		if (document.hidden) {
		  stopPolling(pollingIntervalRef);
		} else {
		  blockPageStartPolling(id, pollAPI, pollingIntervalRef);
		}
	  };
	  document.addEventListener("visibilitychange", handleVisibilityChange);
	  return () => {
		document.removeEventListener("visibilitychange", handleVisibilityChange);
	  };
	}, []);
  
	const pollAPI = async () => {
		try {
		const lowercasePathname = window.location.pathname.toLowerCase();
		if (!lowercasePathname.includes('block')) {
			stopPolling(pollingIntervalRef);
			return;
		}
		  stopPolling(pollingIntervalRef);
		  const response = await apiInstance.get(`/api/v1/schools/${id}/status`)
		  .catch( (error)=> {
			//console.log(error);
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				blockPageStartPolling(id, pollAPI, pollingIntervalRef);
			};
		  })
		  
		  if ( response && response.status === 200) {
			history.push('/dashboard');
			setVerificationMessage("License is now Active!"); // Update the verification message on success
			setContactMEssage(""); // Update the verification message on success
			stopPolling(pollingIntervalRef); // Stop polling after successful API call
		  }
		  else{
			blockPageStartPolling(id, pollAPI, pollingIntervalRef);
		  }
		} catch (error) {
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				blockPageStartPolling(id, pollAPI, pollingIntervalRef);
			};
		}
	};

	return (
		<div className='page-wrapper d-block'>
			<div id='root'>
				<div className='authentications'>
					<div
						className='auth-wrapper align-items-center d-flex'
						style={{
							backgroundImage: "url()",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center center",
						}}
					>
						<div className='ui container'>
								<div className='row'>
								<div className='col-lg-2 col-md-2 col-2'></div>
									<div className='col-lg-8 col-md-8 col-8'>
									<div className='border card mt-8'>
                                    

										<div className='card-body'>
											<div className='p-4'>

												<h1 className='font-medium mb-3 mt-8' align='center'>
												{schoolName ? schoolName : ""}
												</h1>
												<h1 className='font-medium mb-3 mt-8' align='center'>
												{verificationMessage ? verificationMessage : Strings.notVerifiedEmail}
													{/* {Strings.notVerifiedEmail} */}
												</h1>
												
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-12 align-items-center text-center" id="loginpage-links">
												{contactMessage  ?
													<span
														onClick={() => history.push(`/contactus`)}
														align='center'
														className='ui form login_contact blackText contact_msg'
													>
														{contactMessage}
													</span>
													:""
												}
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-2 col-md-2 col-2'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showModal2 ? <LogoutModal /> : null}
		</div>
	);
}

export default withRouter(BlockPage);