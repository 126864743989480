import { useEffect, useState } from 'react';
import {FaTimes,FaTimesCircle} from 'react-icons/fa'
import React from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import {tz} from 'moment-timezone';
import { Spinner } from 'react-bootstrap';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Strings from '../../locale/en-US.json';
import apiInstance from '../../api';
import { ToastContainer } from 'react-toastify';
import {
	deleteEtag,
	getListInStore,
	getEtag,
	setEtag,
	setListInStore,
	PANEL_ETAG_KEY,
	PANEL_LIST_KEY,
	getPageAndCountInStore,
	PANEL_PAGECOUNT_KEY,
	setPageAndCountInStore,
} from '../../utils/apiUtils';
import 'react-toastify/dist/ReactToastify.css';
import { Pagination } from 'semantic-ui-react';
import { maxLength } from '../../utils/apiUtils';
import { LENGTH_MAX } from '../../strings';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import intl from '../../locale/en-US.json';
import { logEvent,Categories,Actions } from "../../analytics";
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';

const LIMIT_OFFSET = 50;

const SuperPanelAlerts = () => {
	const divRef = React.useRef();

	const [panelAlertsList, setPanelAlertsList] = useState([]);
	const [searchString, setSearchString] = useState('');
	const [searchText, setSearchText] = useState('');
	const [dateValueTemp, setDateValueTemp] = useState(['', '']);
	const [dateValue, setDateValue] = useState(['', '']);
	const [timezone, setTimezone] = useState('');
	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showModal2, setShowModal2] = useState(false);
	const [count, setCount] = useState(0);
	const [disablePagination, setDisablePagination] = useState(false);
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [selectedRange, setSelectedRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	});
	const [selectedRangeP, setSelectedRangeP] = useState({
		startDate: null,
		endDate: null,
		key: 'selection',
	});
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const timeoutIdRef = React.useRef(null);
	const pollingIntervalRef = React.useRef(null);
	
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (
				!(event.target.id === 'AddStudent') && !(event.target.id === 'btnApply') && !(event.target.id === 'btnClear') && 
				divRef.current &&
				!divRef.current.contains(event.target)
			) {
				//setShowDatePicker(false);
				if(selectedRangeP.startDate == null){
					const currentDate = new Date();
					setSelectedRange({
						startDate: currentDate,
						endDate: currentDate,
						key: 'selection',
					   });
					 
					   //prevSelectedRangeRef.current = selectedRange;
					   setStartDate('');
					   setEndDate('');
					   setDateValue(['',''])
					   setDateValueTemp(['', '']);
				}
				else{
				setSelectedRange(selectedRangeP);
				//selectedRange = prevSelectedRangeRef.current;
				
				const date = new moment(selectedRange.startDate);
				setStartDate(date.format('MMM DD YYYY'));
				const dateE = new moment(selectedRange.endDate);
				setEndDate(dateE.format('MMM DD YYYY'));
			 }
			 setShowDatePicker(false);
			}
			
			}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [divRef,selectedRangeP]);

	let isMounted = false;
	// const startPolling = () => {
	// 	setIsPolling(true);
	// };

	// const stopPolling = () => {
	// 	setIsPolling(false);
	// };

	useEffect(() => {
		deleteEtag(PANEL_ETAG_KEY);
		setLoading(true);
		setCurrentPage(1);
		fetchData();
		return () => {
			setLoading(false);
		};
	}, [searchString, dateValue]);

	useEffect(() => {
		deleteEtag(PANEL_ETAG_KEY);
		setLoading(true);
		fetchData();
	}, [currentPage]);


	const handlePageChange = (event, { activePage }) => {
		setLoading(true);
		setDisablePagination(true);
		setCurrentPage(activePage);
	};

	// const fetchDataTimeout = () => {
	// 	if (isPolling) {
	// 		if (timeoutIdRef.current) {
	// 			clearTimeout(timeoutIdRef.current);
	// 		}
	// 		timeoutIdRef.current = setTimeout(() => {
	// 			fetchData();
	// 		}, POLL_INTERVAL);
	// 	}
	// };

	const fetchData = async () => {
		try {
			let url = '/api/v1/devices/alerts';
			let queryString = '';

			if (searchString.length > 0) {
				queryString += `search=${searchString}`;
			}
			if (dateValue[0] !== undefined && dateValue[0].toString().length > 0) {
				const startD = moment(dateValue[0].toString(), "ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
				const startDateTime  = startD.utc().format();
				const endD = moment(dateValue[1].toString(), "ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
				const endDateTime  = endD.utc().format();
				const timeZoneMoment = tz.guess();
				if (queryString.length > 0) {
					queryString += '&';
				}
				queryString += `startDateTime=${startDateTime}&endDateTime=${endDateTime}&timezone${timeZoneMoment}`;
			}

			if (queryString.length > 0) {
				url = `/api/v1/devices/alerts?${queryString}`;
			}
			
			const headers = {};
			const storedEtag = getEtag(PANEL_ETAG_KEY);
			if (storedEtag) {
				headers['If-None-Match'] = storedEtag;
			}
			//   headers['cache-control'] = 'public, max-age=3600';
			//console.log('URl', url);
			stopPolling(pollingIntervalRef);
			const res = await apiInstance.get(url, {
				headers,
				params: {
					page: currentPage,
					limit: LIMIT_OFFSET,
				},
			});
			if (res && res.data && res.data.data) {
				setLoading(false);
				setPanelAlertsList(res.data.data.alerts);
				setEtag(PANEL_ETAG_KEY, res.headers.etag);
				setCount(res.data.data.totalAlerts);
				setTotalPages(res.data.data.totalPages); // Set the total number of pages
				setListInStore(PANEL_LIST_KEY, JSON.stringify(res.data.data.alerts));
				// fetchDataTimeout();
				const pageAndCount = {count:res.data.data.totalAlerts,
					totalPages:res.data.data.totalPages}
				setPageAndCountInStore(PANEL_PAGECOUNT_KEY, JSON.stringify(pageAndCount));
        		setDisablePagination(false);
			}
			startPolling(fetchData, pollingIntervalRef);
			isMounted = true;
		} catch (error) {
			startPolling(fetchData, pollingIntervalRef);
			// fetchDataTimeout();
			if (error.response && error.response.status === 304) {
				setLoading(false);
				setErrorMsg('');
				return;
			}
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				// setErrorMessage(result);
			}

			//console.error('Error fetching alerts:', error);
		} finally {
			// setLoading(false);
		}
	};

	useEffect(() => {
		const localTimezone = tz.guess();
        setTimezone(localTimezone);
		const handlePageLoad = async () => {
			deleteEtag(PANEL_ETAG_KEY);
			const storedPanelList = getListInStore(PANEL_LIST_KEY);
			const pageCountList = getPageAndCountInStore(PANEL_PAGECOUNT_KEY)
			if (storedPanelList && pageCountList) {
				setPanelAlertsList(JSON.parse(storedPanelList));
				const data = JSON.parse(pageCountList);
				setTotalPages(data.totalPages);
				setCount(data.count);
			}
		};
		if (!isMounted) {
			handlePageLoad();
		}
		window.onbeforeunload = () => {
			//deleteEtag("badges");
			deleteEtag(PANEL_ETAG_KEY);
		};
	}, []);

	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);
	
	useEffect(() => {
		const handlePageLoad = async () => {
			deleteEtag(PANEL_ETAG_KEY);
			const storedPanelList = getListInStore(PANEL_LIST_KEY);
			const pageCountList = getPageAndCountInStore(PANEL_PAGECOUNT_KEY)
			if (storedPanelList && pageCountList) {
				setPanelAlertsList(JSON.parse(storedPanelList));
				const data = JSON.parse(pageCountList);
				setTotalPages(data.totalPages);
				setCount(data.count);
			}
		};
		if (!isMounted) {
			handlePageLoad();
		}
		window.onbeforeunload = () => {
			//deleteEtag("badges");
			deleteEtag(PANEL_ETAG_KEY);
		};
      

		document.addEventListener('visibilitychange', visibilityChangeHandler);

		return () => {
			stopPolling(pollingIntervalRef);
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, [currentPage, searchString, showDatePicker, dateValueTemp, dateValue]);

	const handleSubmit = (e) => {
		e.preventDefault();
		//fetchData(inputValue);
	};

	const onSearch = async () => {
		let isChanged = false;
		let updatedString = document.getElementById('searchName').value.trim();
		if (updatedString === undefined) {
			updatedString = '';
		}
		const MAX_LENGTH = 255;
		const MIN_LENGTH = 1;
		if (updatedString !== '') {
			if (
				updatedString.length < MIN_LENGTH ||
				updatedString.length > MAX_LENGTH
			) {
				setErrorMsg(Strings.lastName + ' : ' + LENGTH_MAX);
				return;
			}
		}
		if (updatedString !== searchString) {
			isChanged = true;
			setSearchString(updatedString);
		}
		// if (
		// 	dateValue[0].toString() !== dateValueTemp[0].toString() ||
		// 	dateValue[1].toString() !== dateValueTemp[1].toString()
		// ) {
		// 	setDateValue([dateValueTemp[0], dateValueTemp[1]]);
		// 	isChanged = true;
		// }
		if (isChanged) {
			stopPolling(pollingIntervalRef);
			setCurrentPage(1);
			setTotalPages(1);
			setLoading(true);
			setTimeout(() => {
				startPolling(fetchData, pollingIntervalRef);
			}, 100);
		}
		//intervalId = setInterval(fetchData, 10000);
	};
    
	const handleClearRange = () => {
		const currentDate = new Date(); // Get the current date
		setSelectedRange({
			startDate: currentDate,
			endDate: currentDate,
			key: 'selection',
		});
		setSelectedRangeP({
			startDate: null,
			endDate: null,
			key: 'selection',
		});

		//prevSelectedRangeRef.current = selectedRange;
		setStartDate('');
		setEndDate('');
		setDateValue(['', '']);
		setDateValueTemp(['', '']);
		stopPolling(pollingIntervalRef);
		setCurrentPage(1);
		setTotalPages(1);
		setLoading(true);
		setTimeout(() => {
			startPolling(fetchData, pollingIntervalRef);
		}, 100);
	};

	// const handleClearSearch  = ()=>{
	// 	setSearchString('');
	// 	stopPolling(pollingIntervalRef);
	// 	setCurrentPage(1);
	// 	setTotalPages(1);
	// 	setLoading(true);
	// 	setTimeout(() => {
	// 		startPolling(fetchData, pollingIntervalRef);
	// 	}, 100);
		
	// }

	const handleApply  = ()=>{
		stopPolling(pollingIntervalRef);
		setShowDatePicker(false);
		setDateValue([dateValueTemp[0], dateValueTemp[1]]);
		setSelectedRangeP(selectedRange);
		setCurrentPage(1);
		setTotalPages(1);
		setLoading(true);
		startPolling(fetchData, pollingIntervalRef);
	}

	const handleSelect = (ranges) => {
		const selectedStartDate = ranges.selection.startDate;
		const selectedEndDate = ranges.selection.endDate;
		const today = new Date();

 		if (selectedStartDate > today) {
			ranges.selection.startDate = today;
		}

 		if (selectedEndDate > today) {
			ranges.selection.endDate = today;
		}
		
		setSelectedRange(ranges.selection);
		const date = new moment(ranges.selection.startDate);
		setStartDate(date.format('MMM DD YYYY'));
		const dateE = new moment(ranges.selection.endDate);
		setEndDate(dateE.format('MMM DD YYYY'));
		setDateValueTemp([date.startOf('day'),dateE.endOf('day')]);
	};

	const onSearchClear = async () => {
		setSearchString('');
		setSearchText('');
		stopPolling(pollingIntervalRef);
		setTotalPages(1);
		setLoading(true);
		setTimeout(() => {
			startPolling(fetchData, pollingIntervalRef);	
		}, 100); 

		setTimeout(() => {
			setSearchString('');
			setSearchText('');	
		}, 300); 
	}

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Breadcrumb
					links={[{ link: '/notifications', title: Strings.panelAlertList }]}
				/>

				<div className='container-fluid'>
					<div className='row page-titles'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
							<div
								className='card CardNoBorder'
								id='PersonForm'
								style={{ border: '3px solid red', 
								// minWidth: '25em'
							 }}
							>
								<div
									className='card-header card_headertitle'
								>
									<div className='card-title card_head'>
										<div className='row d-flex align-items-center'>
											<div className='col-xl-2 col-lg-2 col-md-12 col-sm-12'>
												<h5 className='card_head CardTitle text-uppercase md-font'>
													{Strings.panelAlert}
												</h5>
											</div>
											
											<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex'>
												
												<input
													type='search'
													id='searchName'
													name='searchName'
													placeholder={intl.searchButton}
													// ref={searchInputRef}
													className='w-100 search-input'
													value={searchText}
													onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearch();
                                                        }
                                                    }}
													style={searchText.length > 1 ? {borderRight: '0px solid red', borderTopRightRadius: '0em', borderBottomRightRadius:'0em'} : null}
													onChange={(e) => {maxLength(e.target.value, 255); setSearchText(e.target.value);
													   setErrorMsg('')}}
												/>
												{ searchText.length > 1 ? <span className='cancel-container' onClick={() =>{ (searchText.length > 1 && onSearchClear());}}>
													<FaTimes id="btnClear" className='cancel-icon' />
												</span> : null }

											</div>

											
											<div
												className='col-xl-1 col-lg-2 col-md-12 col-sm-12 col-12 panel-alert-padding'>
												<div 
												style={{position: 'relative', height:'3em'}} 
												className=''>
													<input
														onClick={() => {onSearch();logEvent({category:Categories.alert,action:Actions.alert_list_search_btn});}}
														type='submit'
														className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed search_button'
														value={Strings.searchButton}
														id='PersonBtn'
													/>
												</div>
											</div>
											<div
												className=' col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12 date-picl-div strip_top_margin'
												style={{ position: 'relative', height: '3em'}}
											>
												<div
													className=' mr-3 date_picker  '
													style={{ position: 'absolute',zIndex: '3' }}
												>
													
													{
														showDatePicker ? (
															<div ref={divRef} id='AddStudent' >
																<DateRange
																	editableDateInputs={true}
																	ranges={[selectedRange]}
																	onChange={handleSelect}
																	showSelectionPreview={true}
																	moveRangeOnFirstSelection={false}
																	retainEndDateOnFirstSelection={true}
																/>
															</div>
													) : (startDate === '' && endDate === '' ) ? (
														<div
															className='rdrCalendarWrapper '
															// id='AddStudent'
															onClick={() => setShowDatePicker(!showDatePicker)}
														>
															<div className='rdrDateDisplayWrapper' style={{  width:'240px'}}>
																<div
																	className='rdrDateDisplay'
																	style={{ color: 'rgb(61,145,255' }}
																>
																	<span className='rdrDateInput rdrDateDisplayItem ' style={{width:'50px'}}>
																		<input
																			placeholder='Start Date'
																			value='All'
																			className='date-input'
																			onChange={() => {}}
																			style={{width:'100px'}}
																		/>
																	</span>			

																</div>
															</div>
														</div>) : <div
															className='rdrCalendarWrapper '
															// id='AddStudent'
															onClick={() => setShowDatePicker(!showDatePicker)}
														>
															<div className='rdrDateDisplayWrapper' style={{  width:'240px'}}>
																<div
																	className='rdrDateDisplay'
																	style={{ color: 'rgb(61,145,255' }}
																>
																	<span className='rdrDateInput rdrDateDisplayItem ' style={{width:'50px'}}>
																		<input
																			placeholder='Start Date'
																			value={startDate}
																			className='date-input'
																			onChange={() => {}}
																			style={{width:'100px'}}
																		/>
																	</span>
																	<span className='rdrDateInput rdrDateDisplayItem' style={{width:'50px'}}>
																		<input
																			placeholder='End Date'
																			value={endDate}
																			className='date-input'
																			onChange={() => {}}
																			style={{width:'100px'}}
																		/>
																	</span>
																</div>
															</div>
															
														</div>
														
														}
														{ !showDatePicker && (startDate !== '' && endDate !== '' )? 
														<FaTimesCircle id = "btnClear" 
															style={{cursor:'pointer'}}
															onClick={() => {handleClearRange();}} className='cancel-button'>
																
															</FaTimesCircle>
															 : null
														}
														{showDatePicker ? 
														<button 
														className='btn ActionButton PersonBtnadd PersonBtnloader rdrDateInput rdrDateDisplayItem' style={{width:'240px'}}
															id='btnApply'
															onClick={() => {handleApply();logEvent({category:Categories.alert,action:Actions.alert_list_apply_btn}); }}
															>{Strings.apply}
														</button>: null
														}
												</div>
											</div>
										</div>
									</div>
								</div>
								{errorMsg && (
							<div className='btn_error'>
								{errorMsg}
							</div>
						)}
								<div className='card-body DataTable PersonGrid'>
									{loading && panelAlertsList ? (
										<div className="d-flex justify-content-center align-items-center">
											<Spinner />
										</div>
									) : (
										<div className='table-responsive'>
											<table className='table table-bordered'>
											{/* <colgroup> //style={{ tableLayout: "fixed" }} inside table
       										 <col style={{ width: "15%" }} />
       										 <col style={{ width: "15%" }} />
       										 <col style={{ width: "35%" }} />
       										 <col style={{ width: "15%" }} />
      										</colgroup> */}
												<thead>
													<tr>
														<th scope='col' style={{ width: "15%" }}>{Strings.ownerPanel}</th>
														<th scope='col' style={{ width: "15%" }}>{Strings.eventDateTimePanel}</th>
														<th scope='col' style={{ width: "35%" }} >{Strings.recipientsPanel}</th>
														<th scope='col' style={{ width: "20%" }}>{Strings.schoolName}</th>
														<th scope='col' >{Strings.devicePanel}</th>
													</tr>
												</thead>
												<tbody>
													{panelAlertsList && panelAlertsList.length > 0 ? (
														panelAlertsList.map((panelAlert, index) => {
															return (
																<tr key={index}  >
																	<td>
																		<p>{panelAlert.owner_name}</p>
																		<p style={{ fontSize: '0.66em', color: 'grey', marginTop:'-10px'}}>{panelAlert.mac_address}</p>
																	</td>
																	<td>
																		<p>{moment(panelAlert.timestamp)
																			.tz(timezone)
																			.format('DD MMM YYYY, HH:mm:ss')}</p>
																			<p style={{ fontSize: '0.66em', marginTop:'-10px'}}><strong>RSSI </strong>{panelAlert.rssi}{' '} <strong>TxPower </strong>{panelAlert.ibeacon_tx_power}</p>
																	</td>
																	<td>
																		{panelAlert.recipients
																			? panelAlert.recipients.map(
																					(recipient, index) => (
																						<div key={index}>
																							<p style={{ paddingRight: '10px' }}>{`${recipient.name}`}</p>
																							<p style={{ fontSize: '0.66em', color: 'grey', left: '10px', marginTop: '-10px' }}>
																								{`${recipient.email.address} `}
																								<span style={{ fontWeight: '500', color: 'black' }}>
																									{recipient.email.status.toUpperCase()}
																								</span>
																								{`, ${recipient.phone.no} `}
																								<span style={{ fontWeight: '500', color: 'black' }}>
																									{recipient.phone.status.toUpperCase()}
																								</span>
																								{recipient.voice  && 
																									<>
																										{', Voice Call '}
																										<span style={{ fontWeight: '500', color: 'black' }}>
																										{recipient.voice.status.toUpperCase()}
																										</span>
																									</>
																								}
																							</p>													</div>

																					)
																			)
																			: null} 
																	</td>
                                                                    <td>
                                                                        {panelAlert.school.name}
                                                                    </td>
																	<td><p>{panelAlert.device_type}</p>
																		<p style={{ fontSize: '0.8em', color: 'grey', marginTop:'-10px'}}>{panelAlert.device_number}</p>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td colSpan='6' style={{ textAlign: 'center' }}>
																{intl.noRecordFound}
															</td>
														</tr>
													)}
												</tbody>
											</table>
											{panelAlertsList && panelAlertsList.length > 0 ?
											<div className='count_div'> 
												<Pagination
													boundaryRange={1}
													ellipsisItem="..."
													firstItem={null}
													lastItem={null}
													activePage={currentPage}
													totalPages={totalPages}
													siblingRange={1}
													disabled={disablePagination}
													onPageChange={handlePageChange}
													className={totalPages === 1 ? "paginationbutton" : null}
												/>
												<div>Total: {count}</div>
											</div>
											: ''}
											<ToastContainer />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
			{showModal2 ? <LogoutModal /> : null}
		</div>
	);
};

export default SuperPanelAlerts;
