import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

import { Spinner } from 'react-bootstrap';
import apiInstance from "../../api";
import AlertModal from "../Modal/AlertModal";
import intl from "../../locale/en-US.json";
import { deleteEtag, 
	getListInStore, 
	getEtag, 
	setEtag, 
	setListInStore, 
	DEVICES_ETAG_KEY, 
	DEVICES_LIST_KEY, 
	getPageAndCountInStore,
	DEVICES_PAGECOUNT_KEY,
	setPageAndCountInStore
} from "../../utils/apiUtils";
import { toast, ToastContainer  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Pagination} from 'semantic-ui-react';
import { errorFunction } from '../../utils/errors'
import LogoutModal from '../Modal/LogoutModal'
import { FaEdit } from "react-icons/fa";
import { Actions, Categories, logEvent } from '../../analytics';
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';
const OFFSET_PAGE = 15;
	  
const DevicesList = ({ history, searchTerm, currentPage, changePage, bulkModal, handleDelete, setHandleDelete, clearSearch, checkUserLength }) => {
	const [deviceList, setDeviceList] = React.useState([]);
	const [showModal, setShowModal] = React.useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(1);
	const [count, setCount] = useState(0);
	const [errorMessage, setErrorMessage] = React.useState('');
	const timeoutIdRef = React.useRef(null);
	const [showHyphen, setShowHyphen] = useState(false);
	const [disablePagination, setDisablePagination] = useState(false);
	const [disableDelete, setDisableDelete] = useState(false);
	const [selectedDevices, setSelectedDevices] = useState([]); // for bulk delete
	const [bulkDelete, setBulkDelete] = useState(false); // for showing selected message and select all option
	const [deleteAll, setDeleteAll] = useState(false); // for Total records option
	const [deleteAllCheckbox, setDeleteAllCheckbox] = useState([]); // for storing state of top checkbox on different pages
	const pollingIntervalRef = React.useRef(null);
	const [tempSearchTerm, setTempSearchTerm] = useState(''); 
	//  const startPolling = () => {
	// 	if (!pollingIntervalRef.current) {
	// 	  pollingIntervalRef.current = setInterval(fetchData, 10000);
	// 	}
	//   };
	
	//   const stopPolling = () => {
    //   if (pollingIntervalRef.current) {
    //     clearInterval(pollingIntervalRef.current);
    //     pollingIntervalRef.current = null;
    //   }
    //   };

	  useEffect(() => {
		selectedDevices.length >= 1 && setShowModal(true)
		setHandleDelete();
	  },[handleDelete]);

	const handlePageChange = (event, { activePage }) => {
		// reset if page change when total records are selected
		if(deleteAll) {
			setBulkDelete(false);
			setDeleteAll(false);
			setSelectedDevices([]);
			checkUserLength([])
			setDeleteAllCheckbox([])
		}
		setIsLoading(true);
		setDisablePagination(true);
		changePage(activePage)
	};

	// useEffect(() => {
	// 	stopPolling(pollingIntervalRef);
	// 	setCurrentPage(1);
	// 	startPolling(fetchData, pollingIntervalRef);
	// 	setIsLoading(true)
	//   }, [searchTerm]);
	
	  const fetchData = async () => {
		try {
			const lowercasePathname = window.location.pathname.toLowerCase();
			if (!lowercasePathname.includes('device')) {
			  stopPolling(pollingIntervalRef);
			  return;
			}
		  const headers = {};
		  const storedEtag = getEtag(DEVICES_ETAG_KEY);
		  if (storedEtag) {
			headers["If-None-Match"] = storedEtag;
		  }
		  //headers['cache-control'] = 'public, max-age=3600';
		  const params = {
			  page: currentPage,
			  limit: OFFSET_PAGE,
			};
		
		  if (searchTerm) {
			  params.search = searchTerm;
		  }
		  stopPolling(pollingIntervalRef);
		  const res = await apiInstance.get('/api/v1/devices', {
		  headers,
		  params,
			});
		setIsLoading(false)
		startPolling(fetchData, pollingIntervalRef);
		  if (res.status === 304) {
			setErrorMessage('');
			return;
		  }
		  if (res && res.data && res.data.data) {
			setDeviceList(res.data.data.devices);
			setCount(res.data.data.totalDevices);
			setEtag(DEVICES_ETAG_KEY,res.headers.etag);
			setTotalPages(res.data.data.totalPages); // Set the total number of pages
			setListInStore(DEVICES_LIST_KEY, JSON.stringify(res.data.data.devices));
			const pageAndCount = {count:res.data.data.totalDevices,
				totalPages:res.data.data.totalPages}
			setPageAndCountInStore(DEVICES_PAGECOUNT_KEY, JSON.stringify(pageAndCount));
			setDisablePagination(false);
		  }
		} catch (error) {
		  startPolling(fetchData, pollingIntervalRef);
		  if (error.response && error.response.status === 304) {
			setErrorMessage("")
			  return;
		  }
		  const result = errorFunction(error)
		  setErrorMessage(result);

		  console.log("result-------- ", result)
		  if (typeof result === 'boolean') {
			  setShowModal2(true);
		  } else {
			  // setErrorMessage(result);
		  }

		  //console.error("Error fetching device:", error);
		} finally {
		  setIsLoading(false);
		}
	  };

	useEffect(() => {
		deleteEtag(DEVICES_ETAG_KEY);
		const storedDevicelist = getListInStore(DEVICES_LIST_KEY);
		const pageCountList = getPageAndCountInStore(DEVICES_PAGECOUNT_KEY)
		if (storedDevicelist && pageCountList) {
			setDeviceList(JSON.parse(storedDevicelist));
			const data = JSON.parse(pageCountList);
			setTotalPages(data.totalPages);
			setCount(data.count);	
		}
		fetchData();
		
	  }, [currentPage, searchTerm, bulkModal]);

	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);

	useEffect(() =>	{
		// const handleVisibilityChange = () => {
		//   if (document.visibilityState === "visible") {
			
		// 	if(timeoutIdRef.current){
		// 		clearTimeout(timeoutIdRef.current);
		// 	}
		// 	startPolling(fetchData, pollingIntervalRef);
		//   } else {
		// 	stopPolling(pollingIntervalRef);
		//   }
		// };

		if (searchTerm !== '') {
			resetStates();
			setTempSearchTerm(searchTerm);
		}
	
		// when search is cancelled.
		if (searchTerm === '' && tempSearchTerm !== '') {
			resetStates();
			setTempSearchTerm('');
		}
	  
		document.addEventListener("visibilitychange", visibilityChangeHandler);
		return () => {
		  stopPolling(pollingIntervalRef);
		  setIsLoading(false);
		  if(timeoutIdRef.current){
			clearTimeout(timeoutIdRef.current);
		  }
		  document.removeEventListener("visibilitychange", visibilityChangeHandler);
		};

	},[searchTerm, currentPage]
	)

	// useEffect(() => {
	// 	if(!bulkModal){
	// 		fetchData();
	// 	}
	// }, [bulkModal]);

	const closeModal = () => setShowModal(false);
	 // checkboxes handling
	 const handleDeleteCheckbox = (e) => {
			const { value, checked } = e.target;
			if (!checked) {
				setBulkDelete(false);
				setDeleteAll(false);
				setDeleteAllCheckbox(
					deleteAllCheckbox.filter((number) => number !== currentPage)
				);
				checkUserLength(selectedDevices.filter((id) => id !== value));
				setSelectedDevices(selectedDevices.filter((id) => id !== value));
				selectedDevices.length <= 1 ? setShowHyphen(false) : setShowHyphen(true);
			} else {
				setSelectedDevices([...selectedDevices, value]);
				checkUserLength([...selectedDevices, value]);
				if (areAllCheckboxesChecked([...selectedDevices, value])) {
					let checkBox = deleteAllCheckbox;
					checkBox.push(currentPage);
					setDeleteAllCheckbox([...checkBox]);
					setShowHyphen(false);
				}
				else {
					setShowHyphen(true);
				}
			}
			// console.log([...selectedStaff, value])
		};

	 const areAllCheckboxesChecked = (arr) => {
		return deviceList.every((device) => arr.includes(device._id));
	};

	const handleBulkDelete = async () => {
		closeModal();
		setShowHyphen(false);
		logEvent({category:Categories.device,action:Actions.device_delete_btn});											
		setDisableDelete(true);
		const requestData = {deviceIds: selectedDevices}
		try {
			setSelectedDevices([]);
			checkUserLength([])
			if (selectedDevices.length !== 0) {
				
				let result;
				setIsLoading(true);

				if (deleteAll) {
					result = await apiInstance.delete(`/api/v1/devices/?deleteAll=true`);
				} else {
					result = await apiInstance.delete(`/api/v1/devices/?deleteAll=false`, {data: requestData});
				}	
				if (result && result.status === 200) {
					setIsLoading(false);
					logEvent({category:Categories.device,action:Actions.device_delete_success});											
					toast.success('Successfully Deleted!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 1000,
					});
					setDisableDelete(false);
					setBulkDelete(false);
					setDeleteAllCheckbox([])
					setDeleteAll(false);
					// when at last page and all are selected
					if (currentPage === totalPages && selectedDevices.length === deviceList.length) {
						// console.log("currentPage === totalPages: ", currentPage === totalPages);
						// console.log("selectedStaff.length === staffList.length: ", selectedDevices.length === deviceList.length);
            			if (searchTerm !== '') {
							clearSearch();
							setDeleteAllCheckbox([]);
						} else {
							changePage(1);
              				if (totalPages ===  1) fetchData();
							// fetchData();
						}
					}
					// when all selected OR more are selected, only one is remaining on last page
					else if (selectedDevices.length >= OFFSET_PAGE || selectedDevices.length === deviceList.length ) {
						// console.log("selectedBadges.length >= PAGE_OFFSET ", selectedDevices.length >= OFFSET_PAGE);
						// console.log("selectedBadges.length === badgeList.length ", selectedDevices.length === deviceList.length);
						// console.log("selectedBadges.length - 1 === 0 ", selectedDevices.length - 1 === 0);
            			// fetchData();
						currentPage === 1 ? fetchData() : changePage(1);
					}
					// when 1 or more selected 
					else fetchData();
				}
			}
		} catch (error) {
			setDeleteAll(false);
			setIsLoading(false);
			setDeleteAllCheckbox([])
			setDisableDelete(false);
			setBulkDelete(false);
			const result = errorFunction(error);
			console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}
		}
		setShowModal(false);
	}

  const handleDeleteAllOnOnePage = (e) => {
		const { checked } = e.target;
		if (deviceList.length !== 0) setBulkDelete(true);
		let staffArr = [];
		deviceList.forEach((student) => staffArr.push(student._id));
		if (!checked) {
			setDeleteAll(false);
			setBulkDelete(false);
			setDeleteAllCheckbox(
				deleteAllCheckbox.filter(
					(deletePageNumber) => deletePageNumber !== currentPage
				)
			);
			checkUserLength(selectedDevices.filter((staff) => !staffArr.includes(staff)));
			setSelectedDevices(
				selectedDevices.filter((staff) => !staffArr.includes(staff))
			);
			selectedDevices.length === 0 && setBulkDelete(false);
		} else {
			// for making the top checkbox remain checked in all pages where it is selected
			let checkBox = deleteAllCheckbox;
			checkBox.push(currentPage);
			setDeleteAllCheckbox([...checkBox]);

			staffArr = staffArr.filter((staff) => !selectedDevices.includes(staff));
			setSelectedDevices([...selectedDevices, ...staffArr]);
			checkUserLength([...selectedDevices, ...staffArr]);
		}
	};

	const resetStates = () => {
		setSelectedDevices([]);
		setDeleteAllCheckbox([])
		setDisableDelete(false);
		setBulkDelete(false);
		setDeleteAll(false);
	}

	return (
		<div className='table-responsive'>
			{bulkDelete && searchTerm === '' && totalPages > 1 ? (
				<div
					className='d-flex justify-content-center align-items-center'
					style={{ marginBottom: '0.5em' }}
				>
					<h6 className='' style={{ padding: '0.5em', marginLeft: '0.7em' }}>
						{deleteAll
							? `All ${count} records are selected`
							: ` ${selectedDevices.length} records are selected`}
					</h6>
					{!deleteAll ? (
						<h6 className='ml-4 delete-all ' onClick={() => setDeleteAll(true)}>
							Select all {count} records
						</h6>
					) : (
						<h6
							onClick={() => {
								setDeleteAll(false);
								setSelectedDevices([]);
								checkUserLength([])
								setBulkDelete(false);
								setDeleteAllCheckbox([]);
							}}
							className='delete-all'
						>
							Clear selection
						</h6>
					)}
				</div>
			):null}
			{errorMessage && (
				<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
					{errorMessage}
				</div>
			)}
			{isLoading ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner animation="border" variant="secondary" />
				</div>
			) : (
				<table className='table table-bordered'>
					<thead>
						<tr>
							<th
								style={{ paddingBottom: 0 }}
								className='d-flex align-items-center justify-content-center'
							>
								<div className="input-label-container">
								<input
									type='checkbox'
									id='selectAll'
									name='selectAll'
									disabled={(deviceList.length === 0 && true) || disableDelete}
									value='selectAll'
									onChange={(e) => {
										setShowHyphen(false);
										handleDeleteAllOnOnePage(e)}
									}
									checked={deleteAll || deleteAllCheckbox.includes(currentPage)}
								/>
								<label htmlFor='selectAll'></label>
								{showHyphen ? (<span onClick={(e) => {
										setShowHyphen(false);
										handleDeleteAllOnOnePage(e)}
									} className="hyphen hover-pointer ">-</span>): ""}
								</div>
							</th>
							<th>{intl.deviceName}</th>
							<th>{intl.deviceNumber}</th>
							<th>{intl.deviceType}</th>
							<th style={{ width: '10px' }}>{intl.actions}</th>
						</tr>
					</thead>
					<tbody>
						{deviceList && deviceList.length ? (
							deviceList.map((device, i) => (
								<Table.Row key={i}  >
									<Table.Cell
										className='pb-0 px-0 d-flex align-items-center justify-content-center'
										style={{
											border: 'none',
											borderColor: 'none',
											paddingTop: '12px'
										}}
									>
										<input
											type='checkbox'
											id={device._id}
											name={device._id}
											value={device._id}
											disabled={disableDelete}
											onChange={(e) => handleDeleteCheckbox(e)}
											checked={deleteAll ||(selectedDevices.includes(device._id) && true)}
										/>
										<label htmlFor={device._id}></label>
									</Table.Cell>
									<Table.Cell>{device.device_name}</Table.Cell>
									<Table.Cell>{device.device_number}</Table.Cell>
									<Table.Cell>{device.device_type}</Table.Cell>
									<Table.Cell>
										<div className='d-flex'>
											<FaEdit
												className='iconAction-edit'
												title='Edit'
												onClick={() =>{
													history.push({
														pathname: '/adddevice',
														state: { data: device },
												
													});
													logEvent({category:Categories.badge,action:Actions.badge_edit_btn});
					
												}
												}
											/>
										</div>
									</Table.Cell>
								</Table.Row>
							))
						) : (
							<tr>
								<td colSpan='4' style={{ textAlign: 'center' }}>
									{intl.noRecordFound}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}
			{showModal2 ? <LogoutModal /> : null}
			{showModal ? (
				<AlertModal
					closeModal={closeModal}
					header={intl.deleteHeader}
					body={selectedDevices.length > 1 ? intl.bulkDelete : intl.deleteBody}
					handlerFunction={handleBulkDelete}
				/>
			) : null}
			{(deviceList && deviceList.length) > 0 ? (
				<div className='count_div'>
					<Pagination
						boundaryRange={1}
						ellipsisItem='...'
						firstItem={null}
						lastItem={null}
						activePage={currentPage}
						totalPages={totalPages}
						siblingRange={1}
						onPageChange={handlePageChange}
						disabled={disablePagination}
						className={totalPages === 1 ? 'paginationbutton' : null}
					/>
					<div>Total: {count}</div>
				</div>
			) : (
				''
			)}
			<ToastContainer />
		</div>
	);
};

export default withRouter(DevicesList);
