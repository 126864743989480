import React from "react";
import Modal from "react-bootstrap/Modal";
import intl from "../../locale/en-US.json";
import {clearStorage, getLogoutMessageInStore} from '../../utils/apiUtils';
import { Spinner } from "react-bootstrap";

const LogoutModal = () => {

	const [loading, setLoading] = React.useState(false)
	const [message, setMessage] = React.useState("");

	React.useEffect(() => {
		const logoutMessage = getLogoutMessageInStore();
		setMessage(logoutMessage)
	},[]);

	const logout = () =>{
		setLoading(true)
		clearStorage();
		setLoading(false)
		window.location.href = '/login';
	}
	return (
		<div
			className='modal show'
			style={{ display: "block", position: "initial" }}
		>
			<Modal show={true}>
				<Modal.Body>
					<h2>{message ? intl.duplicateAccess : intl.expiredSession}</h2>
				</Modal.Body>
				<Modal.Footer>
					<div className='float_left'>
						<button
							onClick={() => logout()}
							type='submit'
							className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
							id='PersonBtn'
						>{loading? <Spinner size='sm' animation="border" variant="secondary" /> : intl.okButton}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default LogoutModal;
