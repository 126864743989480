import React from 'react';
import { adminItems, menuItems } from './MenuItems';
import { withRouter } from 'react-router-dom';
import {
	getRoleInStore,
} from '../../utils/apiUtils';
import MenuItemsDisplay from './MenuItemsDisplay';
const SideBar = (props) => {
	const { history } = props;
	const sidebarClass = props.isOpen ? 'sidebar open ' : 'sidebar';
	const role = getRoleInStore();
	// const priorityId = getPriorityUserIdInStore();
	// const priorityVerification = getPriorityVerificationStatus() === "true" ? true : false ;
	// let isPriorityContactAdded = (priorityId && priorityId !== undefined && priorityId !== 'undefined' ? true : false) && priorityVerification;

	return (
		<div className={sidebarClass}>
			<div>
				<div
					className='move-right'
					onClick={(event) => {
						props.toggleSidebar(event);
					  }}
					  
					id='hamburger'
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 24 24'
						className='iconify sidebarClose rotate-left'
						width='1em'
						height='1em'
						preserveAspectRatio='xMidYMin'
					>
						<g fill='currentColor'>
							<path d='M17 11H9.41l3.3-3.29a1 1 0 1 0-1.42-1.42l-5 5a1 1 0 0 0-.21.33a1 1 0 0 0 0 .76a1 1 0 0 0 .21.33l5 5a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42L9.41 13H17a1 1 0 0 0 0-2Z' />
						</g>
					</svg>
				</div>
				<div style={{ padding: '10px', position: 'absolute' }}>
					{role === 'SuperAdmin'
						? adminItems.map((item, i, array) => {
								return (
									<React.Fragment key={i}>
										<MenuItemsDisplay
											history={history}
											item={{
												...item,
												toggleSidebar: props.toggleSidebar,
											}}
										/>
									</React.Fragment>
								);
						  })
						: menuItems.map((item, i, array) => {
								return (
									<React.Fragment key={i}>
										<MenuItemsDisplay
											item={{
												...item,
												toggleSidebar: props.toggleSidebar,
												history: props.history,
											}}
										/>
									</React.Fragment>
								);
						  })}
				</div>
				{/* {role === 'SchoolAdmin' && <div className=' d-flex sidebar-notification nav-link waves-effect waves-dark active'>
					<label 
						style={{ fontSize:"12px", minWidth: '11em', cursor: 'pointer', color: 'black', paddingLeft:'11px', paddingBottom:'0.5em' }} className='form-label'
						onClick={() => {
							history.push('/settings');
							props.toggleSidebar();
						}}
					>
						{intl.receiveNotification}{isPriorityContactAdded?"(Alternate)":"(Admin)"}:{' '}
						<span
							className={
								props.notificationStatus === 'false'
									? 'notificationOff'
									: 'notificationOn'
							}
						>
							{props.notificationStatus === 'true'
								? 'On'
								: props.notificationStatus === 'false'
								? 'Off'
								: 'On'}
						</span>
					</label>
				</div>} */}
			</div>
		</div>
	);
};
export const baseurl =
	process.env.NODE_ENV === 'production'
		? `${window.location.origin}`
		: 'https://localhost:3001';
export const redirectUrl = window.location.origin;
export default withRouter(SideBar);
