import { Form, Image } from 'semantic-ui-react';
import AllImages from '../../assets/images/images.js';
//import AllImages from '../../assets/images/images.js';
import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import apiInstance from '../../api';
import Strings from '../../locale/en-US.json';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {
	MISMATCH_PASSWORD,
	ERROR_OCCURED,
	PASSWORD_ERROR,
} from '../../strings';
import { maxLength } from '../../utils/apiUtils';
import Spinner from 'react-bootstrap/Spinner';
import { errorFunction } from '../../utils/errors';
import { version } from '../../../package.json';

//import { version } from '../../../package.json';

const ResetPassword = ({ history, match }) => {
	const { token } = match.params;

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const checkTokenValidity = async () => {
			try {
				const currentUrl = window.location.href.split('/').pop();
				await apiInstance.get(`/api/v1/auth/check-reset-token/${currentUrl}`);
			} catch (error) {
				const result = errorFunction(error);
				if (result === 'Expired password reset token') {
					setErrorMsg('Expired Token. Please click Resend Email');
				}
			}
		};

		checkTokenValidity();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrorMsg('');
		setPasswordError('');

		if (password !== confirmPassword) {
			setPasswordError(MISMATCH_PASSWORD);
			return;
		} else setPasswordError('');

		if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,40}$/.test(password)) {
			setErrorMsg(PASSWORD_ERROR);
			return;
		}

		try {
			let response;
			if (!submitted) {
				setLoading(true);
				setSubmitted(true);
				response = await apiInstance.post(
					`/api/v1/auth/reset-password/${token}`,
					{ new_password: password }
				);

				if (response.status === 200) {
					setLoading(false);
					// setSubmitted(false)

					toast.success('Successfully Changed!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000, // Toast message will be shown for 2 seconds
					});

					setTimeout(() => {
						history.push('/login');
					}, 2000);
				}
			}
		} catch (error) {
			setSubmitted(false);
			setLoading(false);
			let errorMsg = ERROR_OCCURED;
			if (error) {
				//console.error('------ ', error);
				if (typeof error === 'string') {
					errorMsg = error;
				} else if (
					error.response &&
					error.response.data &&
					error.response.data.statusMessage
				) {
					errorMsg = error.response.data.statusMessage;
				}
			}
			setErrorMsg(errorMsg);
		}
	};

	const resendEmail = async () => {
		setErrorMsg('');
		setPasswordError('');

		setLoading(true);
		try {
			let response;

			if (!submitted) {
				setSubmitted(true);
				response = await apiInstance.post('/api/v1/auth/reset-password', {
					reset_token: token,
				});
				if (response.status === 200) {
					setLoading(false);
					setSubmitted(false);
					toast.success('Successfully Email sent!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
					});
				}
			}
		} catch (error) {
			setSubmitted(false);
			setLoading(false);
			let errorMsg = ERROR_OCCURED;
			if (error) {
				console.error('------ ', error);
				if (typeof error === 'string') {
					errorMsg = error;
				} else if (
					error.response &&
					error.response.data &&
					error.response.data.statusMessage
				) {
					errorMsg = error.response.data.statusMessage;
				}
			}
			setErrorMsg(errorMsg);
		}
	};

	return (
		<div className='page-wrapper d-block'>
			<div className='ui container'>
				<div className='row'>
					<div className='col-lg-4 col-md-1 col-1'></div>
					<div className='col-lg-4 col-md-10 col-10'>
						<div className='logo_top' align='center'>
							<Image
								src={AllImages['logo']}
								alt='Logo'
								className='logo_class'
							/>
							<p>v {version}</p>
						</div>
					</div>
					<div className='col-lg-4 col-md-10 col-1'></div>
				</div>
			</div>
			<div id='root'>
				<div className='authentications'>
					<div
						className='auth-wrapper align-items-center d-flex'
						style={{
							backgroundImage: 'url()',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center center',
						}}
					>
						<div className='ui container'>
								<div className='row'>
								<div className='col-lg-3 col-md-2 col-1'></div>
									<div className='col-lg-6 col-md-8 col-10'>
									<div className='border card'>
                                    

										<div className='card-body'>
											<div className='p-4'>
												<div>
													{errorMsg && (
														<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
															{errorMsg}
														</div>
													)}
												</div>

												<h3 className='font-medium mb-3' align='center'>
													{Strings.resetpassword}
												</h3>
												<Form
													onSubmit={handleSubmit}
													encType='multipart/form-data'
												>
													<Form.Field className='double-height'>
														<label>{Strings.password}</label>
														<input
															id='password'
															name='password'
															placeholder={Strings.password}
															type={showPassword ? 'text' : 'password'}
															value={password}
															onChange={(e) =>
																setPassword(maxLength(e.target.value, 40))
															}
															required
														/>
													</Form.Field>
													<Form.Field className='double-height'>
														<label>{Strings.confirmPassword}</label>
														<input
															id='confirmPassword'
															name='confirmPassword'
															placeholder={Strings.confirmPassword}
															type={showPassword ? 'text' : 'password'}
															value={confirmPassword}
															onChange={(e) =>
																setConfirmPassword(
																	maxLength(e.target.value, 40)
																)
															}
															required
														/>
													</Form.Field>
													<Form.Field className='double-height'>
														<div className='form-group'>
															<input
																type='checkbox'
																id='vehicle1'
																checked={showPassword}
																onChange={() => setShowPassword(!showPassword)}
															/>
															<label htmlFor='vehicle1'>
																<p className='ml-2'>{Strings.showPassword}</p>
															</label>
															<br />
														</div>
													</Form.Field>

													<div>
														{passwordError && (
															<p className='error-message'>{passwordError}</p>
														)}
													</div>

													<div align='center'>
														<button
															className='btn btn-danger btn-lg btn-block loginloader LoginBtnLoader mb-2'
															type='submit'
														>
															{loading ? (
																<Spinner
																	size='sm'
																	animation='border'
																	variant='secondary'
																/>
															) : (
																Strings.submitButton
															)}
														</button>
													</div>
													<div className='row'>
														<div
															className='col-lg-12 col-md-12 col-sm-12 col-12 blackText '
															id='loginpage-links'
															onClick={() => resendEmail()}
														>
															<p
																className=' login_contact float-right blackText'
																style={{ cursor: 'pointer' }}
															>
																{Strings.resendEmail}
															</p>
														</div>
													</div>
												</Form>
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-3 col-md-2 col-1'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
		</div>
	);
};

export default withRouter(ResetPassword);
