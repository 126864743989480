import React from "react";
import {getRoleInStore} from '../../utils/apiUtils';
import {withRouter} from "react-router-dom";
import {getUserBlockedStore} from '../../utils/apiUtils'
import {logEvent } from "../../analytics";



const Breadcrumb = ({ history, links , eventToSend}) => {
	let userRole = getRoleInStore();
	React.useEffect(() => {
	}, []);

	const handleBreadcrumbClick = () => {
		if(!getUserBlockedStore()){
			if(eventToSend && eventToSend.category && eventToSend.action){
				//logEvent({category:eventToSend.category,action:eventToSend.action,role:userRole});
			}

			const path = userRole === 'SchoolAdmin' ? '/dashboard' : '/organizations';
			history.push(path);
		}
	};

	const handleLinkClick = (link, eventToSend) => {
		if(link && link !== '') {
			if(eventToSend && eventToSend!==''&& eventToSend.category && eventToSend.action){
				logEvent({category:eventToSend.category,action:eventToSend.action});
			}
			history.push(link);
		}
	  };

	return (
		<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
			
			<ol className='cd-breadcrumb custom-separator Breadcrumb_ol  mt-1'>
				<li>
					 <span
					 className="hover-pointer"
						onClick={handleBreadcrumbClick}
						>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							aria-hidden='true'
							focusable='false'
							width='1em'
							height='1em'
							className='iconify breadcrumb_icon sidebar-icons'
							preserveAspectRatio='xMidYMid meet'
							viewBox='0 0 24 24'
							data-icon='uil:home-alt'
							data-inline='false'
							style={getUserBlockedStore() && {cursor:'no-drop'}}
						>
							<path
								fill='currentColor'
								d='m20 8l-6-5.26a3 3 0 0 0-4 0L4 8a3 3 0 0 0-1 2.26V19a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-8.75A3 3 0 0 0 20 8Zm-6 12h-4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1Zm5-1a1 1 0 0 1-1 1h-2v-5a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v5H6a1 1 0 0 1-1-1v-8.75a1 1 0 0 1 .34-.75l6-5.25a1 1 0 0 1 1.32 0l6 5.25a1 1 0 0 1 .34.75Z'
							></path>
						</svg>
					</span>
				</li>
				{links.map((link, i) => (
					<li key={i}>
						<span className="hover-pointer" onClick={() => handleLinkClick(link.link,link.eventToSend)}>{link.title}</span>
					</li>
				))}
			</ol>
		</div>
	);
};

export default withRouter(Breadcrumb);
