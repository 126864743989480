import React, { useState, useEffect, useRef } from 'react';

import { withRouter } from 'react-router-dom';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import apiInstance from '../../api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { maxLength } from '../../utils/apiUtils';
import { Spinner } from 'react-bootstrap';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import Strings from '../../locale/en-US.json';
import useInputFocus from '../../utils/customHooks/useInputFocus'
import AlertModal from '../Modal/AlertModal';
import{Actions, Categories, logEvent} from "../../analytics";
import QRScanModal from '../Modal/QRScanModal';
import { getCameraForQrScan } from '../../utils/common';
import { FaCamera } from 'react-icons/fa';

const AddBadge = ({ history, location }) => {
	const [macAddress, setMacAddress] = useState('');
	const macAddressRef = useRef(macAddress);
	const initialEditMacAddress = useRef('');
	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const isAnyInputFocused = useInputFocus();
	const [showQRModal, setShowQRModal] = useState(false);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);

	useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);

	useEffect(() => {
		if (location.state && location.state.data) {
			const { mac_address } = location.state.data;
			setMacAddress(mac_address);
			initialEditMacAddress.current= mac_address;
		}
	}, [location.state]);

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
		  	}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);
	  
	const handleScan = (data) => {
	if (!!data) {
		if (data.trim().length < 12 || data.trim().length > 12) {
			closeQRModal();
			setErrorMessage("QR Scanned Badge Mac should be a valid MAC address.");
			return;
		}
		setMacAddress(data.toUpperCase());
		closeQRModal();
		setErrorMessage("");
	}
	};

	const handleStartQrScan   = async ()  => {
		try {
			const cameraRequestResult = await getCameraForQrScan();
			console.log(cameraRequestResult);
		
			if (cameraRequestResult === true) {
			  openQRModal();
			} else {
			  setErrorMessage(cameraRequestResult);
			}
		  } catch (error) {
			setErrorMessage(error);
		  }

	  };

	const closeModal = () => setShowModal(false);
	const openQRModal = () => setShowQRModal(true);
	const closeQRModal = () => setShowQRModal(false);
	
	const checkUnsavedChanges = () => {
		if (macAddressRef.current) {
			if (!(initialEditMacAddress.current && initialEditMacAddress.current === macAddressRef.current)) {
				return false;
			}
		}
		return true;
	};
	useEffect(() => {
		macAddressRef.current = macAddress;
	}, [macAddress]);

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		}
		else {
			setShowModal(true);
			return false;
		}
	};

	  const handleNavigationConfirmation = () => {
		setShowModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	  };

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);
		return () => {
		  unblock();
		};
	  }, [history]);


	const handleSubmit = async (event) => {
		event.preventDefault();
		if (macAddress) {
			if (macAddress.trim().length < 12 || macAddress.trim().length > 12) {
				setErrorMessage("Badge Mac should be a valid MAC address.");
				return;
			}
			try {		
				if (location.state && location.state.data) {
					logEvent({category:Categories.badge,action:Actions.badge_edit_submit_btn});
                 	const { _id } = location.state.data;
					if (!submitted) {
						setLoading(true);
						try {
							setSubmitted(true);
							const addBadgeData = new FormData();
							addBadgeData.append('mac_address', macAddress.trim());
							setMacAddress(macAddress.trim());
							const response = await apiInstance.put(
								`/api/v1/badges/${_id}`,
								addBadgeData
							);
							setLoading(false);
							if (response && response.status === 200) {
								logEvent({category:Categories.badge,action:Actions.badge_edit_success});
								allowNavigationRef.current = true;
								toast.success('Successfully Saved!', {
									position: toast.POSITION.TOP_CENTER,
									autoClose: 2000,
								});

								setTimeout(() => {
									history.push('/badge');
								}, 2000);
							} else {
								setSubmitted(false);
							}
						} catch (error) {
							setSubmitted(false);
							setLoading(false);
							const result = errorFunction(error);
							//console.log('result-------- ', result);
							if (typeof result === 'boolean') {
								setShowModal2(true);
							} else {
								setErrorMessage(result);
							}
							// setErrorMessage(error.response ? error.response.data.statusMessage : 'Unknown error occurred');
						}
					}
				} else {
					if (!submitted) {
						logEvent({category:Categories.badge,action:Actions.badge_add_submit_btn});
                 		setLoading(true);
						try {
							setSubmitted(true);
							const badgeDataArray = [
								{
								  mac_address: macAddress.trim()
								}
							];
							const response = await apiInstance.post(
								'/api/v1/badges/',
								badgeDataArray
							);
							setLoading(false);
							if (response && response.status === 200) {
								allowNavigationRef.current = true;
								logEvent({category:Categories.badge,action:Actions.badge_add_success});
                 				toast.success('Successfully Saved!', {
									position: toast.POSITION.TOP_CENTER,
									autoClose: 2000,
								});

								setMacAddress("")
								setTimeout(() => {
									history.push('/badge');
								}, 2000);
							} else {
								setSubmitted(false);
							}
						} catch (error) {
							setLoading(false);
							setSubmitted(false);
							const result = errorFunction(error);
							//console.log('result-------- ', result);
							if (typeof result === 'boolean') {
								setShowModal2(true);
							} else {
								setErrorMessage(result);
							}
						}
					}
				}
			} catch (error) {
				setLoading(false);
				setSubmitted(false);
				const result = errorFunction(error);
				if (error.response && error.response.status === 304) {
					setErrorMessage("")
					return;
				  }
				if (typeof result === 'boolean') {
					setShowModal2(true);
				} else {
					setErrorMessage(result);
				}
				//console.log(error);
			}
		}
	};

	return (
		<>
			<div>
				<Breadcrumb
					links={[
						{ link: '/badge', title: intl.badgeList},
						{
							link: '',
							title:
								location.state && location.state.data
									? intl.updateBadgeButton
									: intl.addBadgeButton,
									
							
						}
					]}
				/>

				<div className='card-header card_headertitle'>
					<div className='card-title card_head'>
						<h4 className='card_head CardTitle text-uppercase component-heading  '>
							{location.state && location.state.data
								? intl.updateBadgeButton
								: intl.addBadgeButton}
						</h4>
					</div>
					{errorMessage && (
						<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
							{errorMessage}
						</div>
					)}
				</div>
				<div className='card-body' style={{ background: '#fff' }}>
					<form id='PersonSaveForm' onSubmit={handleSubmit} action=''>
						<div className='row'>
							<div className='d-flex col-lg-8 col-md-12 col-sm-12 col-12'>
								<div className='col-lg-9 col-md-9 col-sm-8 col-7'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.badgeAddress}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											className='form-control'
											name='Mac'
											value ={macAddress}
											onChange={(event) =>
												setMacAddress(
													maxLength(event.target.value.toLocaleUpperCase(), 20)
												)
											}
											required
											autoFocus
										/>
									</div>

								</div>
								<div style={{paddingLeft: '0px !important', maxHeight: '2.3em'}} className='mt-5 col-lg-3 col-md-3 col-sm-4 col-4 float_left d-flex'>
												<span												
														title='Qr Scan Device Number'
														className='btn AddButton btn-sm bulk_buttons mt-2'
														onClick={(event) => 
															{event.preventDefault();
															logEvent({category:Categories.device,action:Actions.device_Qr_scan_btn});
															handleStartQrScan()}
														}
													>
														<i>
															<FaCamera />
														</i>{' '}
														QR Scan
													</span>
												</div>
								
							</div>
							{/* {loading ? <Spinner animation="border" variant="secondary" /> : ""} */}
						</div>

						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float_right mr-3'>
									<div align='center'>
										<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading ? <Spinner size='sm' animation="border" variant="secondary" /> : Strings.submitButton}
										</button>
									</div>
								</div>

								<div className='float_right mr-3'>
									<button
										className='btn btn-secondary cancel-btn button-fixed'
										onClick={(event) => {
											event.preventDefault();
											history.push('/Badge')
											if(location.state && location.state.data){
												logEvent({category:Categories.badge,action:Actions.badge_edit_cancel_btn});
                 			
											}else{
												logEvent({category:Categories.badge,action:Actions.badge_add_cancel_btn});
                 			
											}
										}
										}
									>
										{intl.cancelButton}
									</button>
								</div>
							</div>
						</div>
					</form>
					{showQRModal ?(
					<QRScanModal
						closeModal={closeQRModal}
						header={Strings.scanQrDeviceNumber}
						showQRScanner={true}
						handleScanResult={handleScan}
					/>):""}
					{showModal ? (
						<AlertModal
							closeModal={closeModal}
							header={Strings.unsavedChanges}
							body={Strings.unsavedChangesText}
							handlerFunction={handleNavigationConfirmation}
						/>
					) : null}
				</div>
				{showModal2 ? <LogoutModal /> : null}
				<ToastContainer />
			</div>
		</>
	);
};
export default withRouter(AddBadge);
