import axios from 'axios';
import { checkInternetAvailability } from './utils/common';
import { INTERNET_AVAI } from './strings';
import {
	getAccessTokenInStore,
	getRefreshTokenInStore,
	getApiLogInStore,
	setAccessTokenInStore,
	setApiLogInStore,
	removeApiLogInStore,
	getUserBlockedStore,
	removeUserBlockedInStore,
	setUserBlockedInStore,
	getRoleInStore,
	getBlockedByAdminStore,
	setBlockedByAdminStore,
	removeBlockedByAdminInStore,
	setRenewAccessTokenInProgress,
	getRenewAccessTokenInProgress,
	removeRenewAccessTokenInProgress
} from './utils/apiUtils';

const ERROR_BLOCKED_LICENSE = -38;
const ERROR_EXPIRED_LICENSE = -39;
const ERROR_EXPIRED_ACCESS_TOKEN = -4;
const ERROR_EMAIL_NOT_VERIFIED = -37;
const REQ_TIMEOUT = 60000;
const role = getRoleInStore();
function createInstance(baseURL) {
	const instance = axios.create({
		baseURL,
	});

	const MAX_RETRY_ATTEMPTS = 3;
	const RETRY_DELAY = 1000; // milliseconds

	// Request interceptor to add authorization header
	instance.interceptors.request.use(
		(config) => {
			const apiCallLog = JSON.parse(getApiLogInStore()) || [];
			const refreshToken = getRefreshTokenInStore();
			//const inProgress = getRenewAccessTokenInProgress();
			if (!refreshToken) {
				removeApiLogInStore();
				removeRenewAccessTokenInProgress();
			}
			if (refreshToken && !config.url.includes('refresh-token') && apiCallLog && apiCallLog.length !== 0) {
				console.log(" call bounced due to expiry")
				console.log(config.url)
				// if (!inProgress) {// if in a state where apicalllog but there is no refresh call going on instead of stuck send for a refresh call so it wont get stuck
				// 	const error = {
				// 		message: TOKEN_EXPIRED,
				// 		response: {data: {}, request:{responseURL:config.url}}				  
				// 	};
				// 	error.response.data.statusCode = -4;
				// 	return Promise.reject(error);
				// }
				return Promise.reject();
			}
			if (!checkInternetAvailability()) {
				return Promise.reject(INTERNET_AVAI);
			}
			if (Array.isArray(config.data)) {
				const additionalTimeout = config.data.length * 4000;// add 50 ms per entry in bulk upload array
				config.timeout = REQ_TIMEOUT + additionalTimeout;
			  } else {
				config.timeout = REQ_TIMEOUT;
			}
			
			const token = getAccessTokenInStore();
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	// Response interceptor to handle authorization errors and implement retry logic
	instance.interceptors.response.use(
		(response) => {
			if (getUserBlockedStore() || getBlockedByAdminStore()){
				if  (!response.config.url.includes('status') && (response.config.url.includes('contactus') || response.config.url.includes('users')  || (response.config.url.includes('auth')) ) ) {
				  return response;
				}
				else {
					removeUserBlockedInStore();
					removeBlockedByAdminInStore();
				}
			  }
			  return response;
		},
		async (error) => {
			if (axios.isCancel(error)) {
				console.log('Request cancelled \n\n');
				return Promise.reject(error);
            } 
			let originalRequest;
			var targetUrl;
			if (error.response &&  error.response.data && error.response.data.statusCode !== ERROR_BLOCKED_LICENSE) {
				removeBlockedByAdminInStore();
			}
			if (error.response && error.response.data && error.response.data.statusCode === ERROR_EMAIL_NOT_VERIFIED) {
				if (!getUserBlockedStore()) {
					setUserBlockedInStore(true);
				}
				targetUrl = redirectUrl + "/verifyEmailBlock";
				if (window.location.href !== targetUrl && !window.location.pathname.includes('organizationSettings') 
				&& !window.location.pathname.includes('contactus') && !window.location.pathname.includes('settings')) {
					window.location.href = targetUrl;
				}
			}
			if ((error.response && error.response.data && (error.response.data.statusCode === ERROR_BLOCKED_LICENSE || error.response.data.statusCode === ERROR_EXPIRED_LICENSE))
			     && !(window.location.pathname.includes('contactus') || window.location.pathname.includes('organizationSettings')
				 || window.location.pathname.includes('settings')
				 )
				) {
				if (error.response.data.statusCode === ERROR_BLOCKED_LICENSE && !getBlockedByAdminStore()) {
					setBlockedByAdminStore(true);
					setUserBlockedInStore(true);
				}
				if (error.response.data.statusCode === ERROR_EXPIRED_LICENSE && !getUserBlockedStore()) {
					setUserBlockedInStore(true);
				}
				if(role !== "SuperAdmin") {
					targetUrl = redirectUrl + "/blocked?statusCode=" + error.response.data.statusCode;
					if (window.location.href !== targetUrl) {
					window.location.href = targetUrl;
					}
				}
			  }
			
			if (error.response && error.response.data && error.response.data.statusCode === ERROR_EXPIRED_ACCESS_TOKEN) {
				const accessToken = getAccessTokenInStore();
				const refreshToken = getRefreshTokenInStore();
				const apiCallLog = JSON.parse(getApiLogInStore()) || [];
				const inProgress = getRenewAccessTokenInProgress();
				if ((apiCallLog && apiCallLog.length === 0) || !inProgress) {
					apiCallLog.push(error.response.request.responseURL);
					setApiLogInStore(JSON.stringify(apiCallLog));
					setRenewAccessTokenInProgress(true);
					try {
						originalRequest = error.config;
						const response = await instance.post(
							'/api/v1/auth/refresh-token',
							{
								refresh_token: refreshToken,
							},
							{
								headers: {
									Authorization: `Bearer ${accessToken}`,
								},
							}
						);

						const newAccessToken = response.data.user.accessToken;
						setAccessTokenInStore(newAccessToken);
						removeApiLogInStore();
						removeRenewAccessTokenInProgress();

						instance.defaults.headers.common[
							'Authorization'
						] = `Bearer ${newAccessToken}`;
						originalRequest && ( originalRequest.headers[
							'Authorization'
						] = `Bearer ${newAccessToken}`);

						return instance(originalRequest);
					} catch (error) {
						console.log("remove inprogress due to crash")
						removeRenewAccessTokenInProgress();
						return Promise.reject(error);
					}
				} else {
					return;
				}
			}
			if (error.response && error.response.status) {
				return Promise.reject(error);
			}
			 
			originalRequest = error.config;
			let retryCount = originalRequest && (originalRequest.retryCount || 0);
			if (originalRequest && retryCount < MAX_RETRY_ATTEMPTS) {
				originalRequest.retryCount = retryCount + 1;
				try {
					console.log('Retry ', retryCount);
					await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
					return instance(originalRequest);
				} catch (error) {
					console.log('Rejected');
					return Promise.reject(error);
				}
			}

			return Promise.reject(error);
		}
	);

	return instance;
}

// Create the initial instance
export const baseurl = (process.env.NODE_ENV === 'production' ? `${window.location.origin}` : 'https://localhost:3001');
export const redirectUrl = window.location.origin;
export let apiInstance = createInstance(baseurl);
export default apiInstance;

