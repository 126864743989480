import {setLogoutMessageInStore} from '../utils/apiUtils'
const ERROR_REVOKED_ACCESS_TOKEN = -5;
const ERROR_ACCESS_TOKEN = -6;
const ERROR_REFRESH_TOKEN = -7;
const ERROR_INVALID_ISSUER = -14;
const ERROR_INVALID_AUDIENCE = -15;
const ERROR_REVOKED_REFRESH_TOKEN = -11;
//const ERROR_BLOCKED_LICENSE = -38;
//const ERROR_EXPIRED_LICENSE = -39;
//const ERROR_EXPIRED_ACCESS_TOKEN = -4;
//const ERROR_EMAIL_NOT_VERIFIED = -37;
const ERROR_INVALID_USER = -16;
export const errorFunction = (error) =>{
    //other than 5 and 11 invalid token access

    //when passport sends 401 invalid token without any other clue or statuscode
    if (error.response && error.response.status && error.response.status === 401 && error.response.statusText === 'Unauthorized' && !error.response.data.statusCode) {
        return true
    }
    //when user not exists
    if (error.response && error.response.data && error.response.data.statusCode === ERROR_INVALID_USER) return true
    
    //when invalid audience
    if (error.response && error.response.data && error.response.data.statusCode === ERROR_INVALID_AUDIENCE) return true

    //when invalid issuer
    if (error.response && error.response.data && error.response.data.statusCode === ERROR_INVALID_ISSUER) return true
    
    //when revoked access token
    if (error.response && error.response.data && error.response.data.statusCode === ERROR_REVOKED_ACCESS_TOKEN) {
        setLogoutMessageInStore(true);
        return true
    }
    
    // when no access token
    if (error.response && error.response.data && error.response.data.statusCode === ERROR_REFRESH_TOKEN) return true

    // when no refresh token
    if (error.response && error.response.data && error.response.data.statusCode === ERROR_ACCESS_TOKEN) return true
    
    // when logged in other device and refresh token is different
    if (error.response && error.response.data && error.response.data.statusCode === ERROR_REVOKED_REFRESH_TOKEN) {
        setLogoutMessageInStore(true);
        return true
    }
    
    let errorMsg = 'Error Occured';
    const allowedErrorMessages = ['phone_no is already taken', 'email is already taken', 'There can only be one plan set as default']
    if (error) {
        console.log('------ ', error.response && error.response.data &&   error.response.data.statusMessage);
         // DB error
        if (error.response && error.response.data && error.response.data.statusCode === -10 && error.response.data.statusMessage.includes('connect ECONNREFUSED')) return 'Error Occured';
        // 500 error
        if (error.response && error.response.status === 500 && !allowedErrorMessages.includes(error.response.data.statusMessage)) 
            errorMsg = 'Internal Server Error';
        else if (
            error.response &&
            error.response.data &&
            error.response.data.statusMessage
        ) {
            errorMsg = error.response.data.statusMessage;
        }
    }
    return errorMsg;
}

