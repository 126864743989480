import React, { useEffect, useState, useRef } from 'react';
import {
	FaTimes,
	FaPlus,
	FaTrashAlt,
	FaEdit,
	FaUserPlus,
} from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
// import StaffList from './StaffList';

import apiInstance from '../../api';
import { Spinner } from 'react-bootstrap';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import AlertModal from '../Modal/AlertModal';
import { Pagination } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {
	deleteEtag,
	getListInStore,
	setListInStore,
	getEtag,
	setEtag,
	STAFF_ETAG_KEY,
	STAFF_LIST_KEY,
	maxLength,
	getPageAndCountInStore,
	STAFF_PAGECOUNT_KEY,
	setPageAndCountInStore,
} from '../../utils/apiUtils';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import BulkAddModal from "../Modal/BulkAddModal";
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { logEvent,Categories,Actions } from "../../analytics";
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';
const PAGE_OFFSET = 15;

const Staff = ({ history }) => {
	const [staffList, setStaffList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const [showBulkAddModal, setShowBulkAddModal2] = useState(false);
	const [disableDelete, setDisableDelete] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [count, setCount] = useState(0);
	const timeoutIdRef = useRef(null);
	const [showHyphen, setShowHyphen] = useState(false);
	const pollingIntervalRef = React.useRef(null);
	const [disablePagination, setDisablePagination] = useState(false);
	const isAnyInputFocused = useInputFocus();
	const [selectedStaff, setSelectedStaff] = useState([]); // users for bulk delete
	const [bulkDelete, setBulkDelete] = useState(false); // for showing selected message and select all option
	const [deleteAll, setDeleteAll] = useState(false); // for Total records option
	const [deleteAllCheckbox, setDeleteAllCheckbox] = useState([]); // for storing state of top checkbox on different pages (stores pages)
	
	useEffect(() => {
		deleteEtag(STAFF_ETAG_KEY);
		setLoading(true);
		fetchData();
		return () => {
			setLoading(false);
		};
	}, [searchTerm, activePage]);


	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);
  
	useEffect(() => {
		deleteEtag(STAFF_ETAG_KEY);
		const storedList = getListInStore(STAFF_LIST_KEY);
		const pageCountList = getPageAndCountInStore(STAFF_PAGECOUNT_KEY);
		if (storedList && pageCountList) {
			const data = JSON.parse(pageCountList);
			setTotalPages(data.totalPages);
			setCount(data.count);
			setStaffList(JSON.parse(storedList));
		}

		visibilityChangeHandler()

		document.addEventListener('visibilitychange', visibilityChangeHandler);
		return () => {
			stopPolling(pollingIntervalRef);
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, [activePage, searchTerm]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	const fetchData = async (refreshAfterDelete) => {
		const lowercasePathname = window.location.pathname.toLowerCase();
			if (!lowercasePathname.includes('responder')) {
			  stopPolling(pollingIntervalRef);
			  return;
			}
		try {
			const headers = {};
			const storedEtag = getEtag(STAFF_ETAG_KEY);
			if (storedEtag) {
				headers['If-None-Match'] = storedEtag;
			}
			const url = `/api/v1/users?role=${intl.roleTeacher}`;
			const params = {
				page: refreshAfterDelete === 1 ? refreshAfterDelete : activePage,
				limit: PAGE_OFFSET,
			};

			if (searchTerm) {
				params.search = searchTerm;
			}
			stopPolling(pollingIntervalRef);
			const res = await apiInstance.get(url, {
				headers,
				params,
			});
			//const res = await apiInstance.get(url, config);
			if (res.status === 304) {
				setLoading(false);
				setDisablePagination(false);
				setErrorMessage("")
				return;
			}

			if (res && res.status === 200) {
				setStaffList(res.data.data.users);
				setEtag(STAFF_ETAG_KEY, res.headers.etag);
				setCount(res.data.data.totalUsers);
				setTotalPages(res.data.data.totalPages);
				setLoading(false);
				showError('');
				const pageAndCount = {count:res.data.data.totalUsers,
					totalPages:res.data.data.totalPages}
				setPageAndCountInStore(STAFF_PAGECOUNT_KEY, JSON.stringify(pageAndCount));
				setListInStore(STAFF_LIST_KEY, JSON.stringify(res.data.data.users));
				setDisablePagination(false);
				setActivePage(refreshAfterDelete && refreshAfterDelete ===1 ? 1:activePage);
			}

			startPolling(fetchData, pollingIntervalRef);
		} catch (error) {
			setLoading(false);
			startPolling(fetchData, pollingIntervalRef);
			if (error.response && error.response.status === 304) {
				setErrorMessage("")
				return;
			}
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	const closeModal = () => setShowModal(false);

	const closeBulkAddModal = () => {
		setShowBulkAddModal2(false);
		fetchData();
		logEvent({category:Categories.staff,action:Actions.staff_bulk_upload_cancel_btn});    
	}

	// const handleDeleteStaff = async () => {
	// 	setLoading(true);
	// 	setShowModal(false);
	// 	try {
	// 		const result = await apiInstance.delete(`/api/v1/users/${staffID}`);
	// 		if (result.status === 200) {
	// 			setLoading(false);
	// 			toast.success('Successfully Deleted!', {
	// 				position: toast.POSITION.TOP_CENTER,
	// 				autoClose: 1000,
	// 			});
	// 			setStaffList(staffList.filter((staff) => staff._id !== staffID));
	// 			setCount((prevValue) => (prevValue -= 1));
	// 			if (staffList.length - 1 === 0) {
	// 				activePage === 1 ? fetchData(1) : setActivePage(1);
	// 			}
	// 		}
	// 	} catch (error) {
	// 		setLoading(false);
	// 		const result = errorFunction(error);
	// 		if (typeof result === 'boolean') {
	// 			setShowModal2(true);
	// 		} else {
	// 			showError(result);
	// 		}
	// 	}
	// };

	const onChange = (e, pageInfo) => {
		// reset if page change when total records are selected
		if(deleteAll) {
			setBulkDelete(false);
			setDeleteAll(false);
			setSelectedStaff([]);
			setDeleteAllCheckbox([])
		}
		setActivePage(pageInfo.activePage);
		setLoading(true);
		setDisablePagination(true);
	};

	const showError = (result) => {
		setErrorMessage(result);
		if (result) {
			window.scrollTo(0, 0);
		}
	};


	const onSearchClear = async () => {
		setSearchTerm('');
		setSearchText('');
		setDeleteAllCheckbox([])
	};

	// checkboxes handling
	const handleDeleteCheckbox = (e) => {
		// setBulkDelete(true);
		const { value, checked } = e.target;
		if (!checked) {
			setBulkDelete(false);
			setDeleteAll(false);
			setDeleteAllCheckbox(deleteAllCheckbox.filter(number => number !== activePage));
			setSelectedStaff(selectedStaff.filter(id => id !== value));
			selectedStaff.length <= 1 ? setShowHyphen(false) : setShowHyphen(true);
		}
		else {
			setSelectedStaff([...selectedStaff, value]);
			// making the top chekbox checked
			if (areAllCheckboxesChecked([...selectedStaff, value])) {
				let checkBox = deleteAllCheckbox;
				checkBox.push(activePage);
				setShowHyphen(false);
				setDeleteAllCheckbox([...checkBox]);
			}
			else {
				setShowHyphen(true);
			}
		}
		// console.log([...selectedStaff, value])
 	}

	const areAllCheckboxesChecked = (arr) => {
		return staffList.every((Staff) => arr.includes(Staff._id));
	};

	const convertDeleteData = (users) => {
		return users.map(user => {
			return {id:user, role:intl.roleTeacher}
		});
	}

	const handleBulkDelete = async () => {
		closeModal();
		setShowHyphen(false);
		logEvent({category:Categories.staff,action:Actions.staff_delete_submit_btn});
		setDisableDelete(true);
		const requestData = convertDeleteData(selectedStaff); 
		try {
			setSelectedStaff([]);
			if (selectedStaff.length !== 0) {
				let result;
				setLoading(true);

				if (deleteAll) {
					result = await apiInstance.delete(`/api/v1/users?deleteAll=true&role=${intl.roleTeacher}`);
				} else {
					result = await apiInstance.delete(`/api/v1/users/?deleteAll=false`, {data: requestData});
				}		

				if (result && result.status === 200) {
					logEvent({category:Categories.staff,action:Actions.staff_delete_success});
					toast.success('Successfully Deleted!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 1000,
					});
					setDisableDelete(false);
					setBulkDelete(false);
					setDeleteAllCheckbox([])
					setDeleteAll(false);
					// when at last page and all are selected
					if (activePage === totalPages && selectedStaff.length === staffList.length) {
						// console.log("activePage === totalPages: ", activePage === totalPages);
						// console.log("selectedStaff.length === staffList.length: ", selectedStaff.length === staffList.length);
						if (searchTerm !== '' || searchText !== '') {
							setSearchTerm('');
							setSearchText('');
						} else {
							// setActivePage(1);
							fetchData(1);
						}
					}
					// when all selected OR more are selected, only one is remaining on last page
					else if (selectedStaff.length >= PAGE_OFFSET || selectedStaff.length === staffList.length ) {
						// console.log("selectedStaff.length >= PAGE_OFFSET ", selectedStaff.length >= PAGE_OFFSET);
						// console.log("selectedStaff.length === staffList.length ", selectedStaff.length === staffList.length);
						// console.log("selectedStaff.length - 1 === 0 ", selectedStaff.length - 1 === 0);
						activePage === 1 ? fetchData() : setActivePage(1);

						// setActivePage(1);
					}
					// when 1 or more selected 
					else fetchData();

					setLoading(false);
				}
			}
		} catch (error) {
			setLoading(false);
			setDeleteAllCheckbox([])
			setDeleteAll(false);
			setBulkDelete(false);
			setDisableDelete(false);
			const result = errorFunction(error);
			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}
		}
		setShowModal(false);
	}

	const handleDeleteAllOnOnePage = (e) => {
		const { checked } = e.target;
		if (staffList.length !== 0) setBulkDelete(true);
		let staffArr = [];
		staffList.forEach((student) => staffArr.push(student._id));
		if (!checked) {
			setDeleteAll(false);
			setBulkDelete(false);
			setDeleteAllCheckbox(
				deleteAllCheckbox.filter(
					(deletePageNumber) => deletePageNumber !== activePage
				)
			);
			setSelectedStaff(
				selectedStaff.filter((staff) => !staffArr.includes(staff))
			);
			selectedStaff.length === 0 && setBulkDelete(false);
		} else {
			// for making the top checkbox remain checked in all pages where it is selected
			let checkBox = deleteAllCheckbox;
			checkBox.push(activePage);
			setDeleteAllCheckbox([...checkBox]);

			staffArr = staffArr.filter((staff) => !selectedStaff.includes(staff));
			setSelectedStaff([...selectedStaff, ...staffArr]);
		}
	};

	const resetStates = () => {
		setSelectedStaff([]);
		setDeleteAllCheckbox([])
		setDisableDelete(false);
		setBulkDelete(false);
		setDeleteAll(false);
	}

	return (
		<>
			<Breadcrumb links={[{ link: '/responder', title: intl.staffList }]} />

			<div className='container-fluid'>
				<div className='row page-titles'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
						<div className='card CardNoBorder' id='PersonForm'>
							<div className='card-header card_headertitle'>
								<div className='card-title card_head'>
									<div className='row d-flex align-items-center'>
										<div className=' col-lg-2 col-md-2 col-sm-6 col-6 '>
											<h4 className='mt-2 card_head CardTitle text-uppercase component-heading  '>
												{intl.staff}
											</h4>
										</div>
										<div className=' col-lg-5 col-md-6 col-sm-8 col-8 d-flex'>
											<input
												id='searchName'
												type='search'
												placeholder={intl.searchButton}
												// ref={searchInputRef}
												onKeyDown={(e) => {
													if (e.keyCode === 13) {
														setSearchTerm(searchText);
														setActivePage(1);
														resetStates();
													}
												}}
												className='w-100 search-input'
												style={
													searchText.length > 1
														? {
																borderRight: '0px solid red',
																borderTopRightRadius: '0em',
																borderBottomRightRadius: '0em',
														  }
														: null
												}
												value={searchText}
												onChange={(e) => {
													maxLength(e.target.value, 255);
													showError('');
													setSearchText(e.target.value);
												}}
											/>
											{searchText.length > 1 ? (
												<span
													className='cancel-container'
													onClick={() => {
														onSearchClear();
														resetStates();
													}}
												>
													<FaTimes id='btnClear' className='cancel-icon' />
												</span>
											) : null}
										</div>
										<div className='col-lg-1 col-md-2 col-sm-4 col-4'>
											<div className='float_left mr-3'>
												<input
													type='button'
													onClick={() => {
														setSearchTerm(searchText); 
														setActivePage(1); 
														logEvent({category:Categories.staff,action:Actions.staff_search_btn});
														resetStates();
													}}
													className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
													value={intl.searchButton}
													id='PersonBtn'
												/>
											</div>
										</div>
										<div className=' col-lg-4 col-md-8 col-sm-12 col-12 bulk_buttons_div '>
											<span title='Add Responder'
												className='btn AddButton btn-sm bulk_buttons'
												onClick={() => {history.push('/addResponder');logEvent({category:Categories.staff,action:Actions.staff_add_btn})}}
											>
												<i>
													<FaPlus />
												</i>{' '}
												{intl.addStaffButton}
											</span>
											<span												title='Add Responders'
												className='btn AddButton btn-sm bulk_buttons ml-1 mr-1'
												onClick={() => {logEvent({category:Categories.staff,action:Actions.staff_bulk_upload_btn});setShowBulkAddModal2(true)}}

											>
																					
												<i>
													<FaPlus />
												</i>{' '}
												{intl.bulkuploadButton}
											</span>
											
											{selectedStaff.length > 0 && <span title='Delete'
												className='btn AddButton btn-sm bulk_buttons'
												onClick={() =>
													selectedStaff.length >= 1 && setShowModal(true)
												}
											>
												<i>
													<FaTrashAlt />
												</i>{'  '}
												{intl.deleteButton}
											</span>}


											{/* <span												title='Add Staff'
																								className='float_right mr-3 mt-1 d-flex align-items-center'
												onClick={() =>
													selectedStaff.length >= 1 && setShowModal(true)
												}
												// onClick={() => alert('lalalal')}
											>
												<FaTrashAlt
													className='iconAction-danger'
													title='Delete'
													onClick={() => {
														// setDeviceId(device._id);
														// setShowModal(true);
													}}
												/>
											</span> */}
										</div>
									</div>
								</div>
							</div>
							{bulkDelete && searchTerm === '' && searchText === '' && totalPages > 1 ? (
								<div
									className='d-flex justify-content-center align-items-center pt-4'
									style={{ marginBottom: '-0.5em' }}
								>
									<h6
										className=''
										style={{ padding: '0.5em', marginLeft: '0.7em' }}
									>
										{deleteAll
											? `All ${count} records are selected`
											: ` ${selectedStaff.length} records are selected`}
									</h6>
									{!deleteAll ? (
										<h6
											className='ml-4 delete-all '
											onClick={() => setDeleteAll(true)}
										>
											Select all {count} records
										</h6>
									) : (
										<h6
											onClick={() => {
												setDeleteAll(false);
												setSelectedStaff([]);
												setBulkDelete(false);
												setDeleteAllCheckbox([]);
											}}
											className='delete-all'
										>
											Clear selection
										</h6>
									)}
								</div>
							): null}
							<div></div>
							<div className='card-body DataTable PersonGrid'>
								<div className='table-responsive' style={{overflowX:'auto'}}>
									{errorMessage && (
										<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
											{errorMessage}
										</div>
									)}
									{loading ? (
										<div className="d-flex justify-content-center align-items-center">
											<Spinner animation="border" variant="secondary" />
										</div>
									) : (
										<table className='table table-bordered'>
											<thead>
												<tr>
													<th >
														<div className="input-label-container">
														<input
															type='checkbox'
															id='selectAll'
															name='selectAll'
															value='selectAll'
															disabled={(staffList.length === 0 && true) || disableDelete}
															onChange={(e) => {
																setShowHyphen(false);
																handleDeleteAllOnOnePage(e)}
															}
															checked={deleteAll || deleteAllCheckbox.includes(activePage)}
														/>
														<label htmlFor='selectAll' style={{height:'10px'}}
														className=''
														></label>

														{showHyphen ? 
														<span 
															onClick={(e) => {
																setShowHyphen(false);
																handleDeleteAllOnOnePage(e)}
															} 
															className="hyphen hover-pointer ">
															-
														</span>:
														 ""}
														</div>
													</th>
													<th scope='col'>{intl.Name}</th>
													<th scope='col' style={{minWidth:'5em'}}>{intl.contact}</th>
													{/* <th scope='col'>{intl.gender}</th> */}
													<th scope='col'>{intl.badge}</th>
													<th scope='col'>{intl.studentsCount}</th>
													<th scope='col'>{intl.notifications} (Receive/Send)</th>
													<th scope='col' className='sticky-column'>{intl.actions}</th>
												</tr>
											</thead>
											<tbody>
												{staffList && staffList.length ? (
													staffList.map((Staff, i) => {
														return (
															<tr key={i}>
																<td
																	
																>
																	<div
																	style={{
																		border: 'none',
																		borderTop: 'none',
																		borderColor: 'none',
																		paddingBottom:'-10px',
																		display:'flex',
																		alignItems: 'center',
																	}}>
																		<input
																			type='checkbox'
																			id={Staff._id}
																			name={Staff._id}
																			value={Staff._id}
																			disabled={disableDelete}
																			onChange={(e) => handleDeleteCheckbox(e)}
																			checked={deleteAll || (
																				selectedStaff.includes(Staff._id) && true
																				)
																			}
																		/>
																		<label htmlFor={Staff._id} style={{height:'10px'}}></label>
																	</div>
																</td>
																<td style={{minWidth:'10em', }}>
																	{Staff.first_name} {Staff.last_name} {Staff.email_verification_code || Staff.phone_verification_code ? " (Unverified)":""}
																</td>
																<td style={{}}>{Staff.email} <br /> {Staff.phone_no}
																</td>
																{/* <td >{Staff.gender}</td> */}
																<td >
																	{Staff.mac_address ? Staff.mac_address : '-'}
																</td>
																<td >{Staff.student_count}</td>																
																<td >({Staff.student_count > 0 ? (Staff.receive_notification === false ?<label className='notificationOff'>Off</label>: <label className='notificationOn'>On</label>):"NA"} / { Staff.mac_address ? (Staff.send_notification === false ?<label className='notificationOff'>Off</label>: <label className='notificationOn'>On</label>): "NA"})</td>

																<td  className='sticky-column'>
																	<div className='d-flex justify-content-center'>
																		<FaEdit
																			className='iconAction-edit'
																			title='Edit'
																			onClick={() => {
																				logEvent({category:Categories.staff,action:Actions.staff_edit_btn});
																				window.history.replaceState(
																					{},
																					document.title,
																					'/responder',
																					`staffdetails/${Staff._id}`
																				);
																				return history.push({
																					pathname: `/responderdetails/${Staff._id}`,
																					state: { data: Staff },
																				});
																			}}
																		/>
																		<FaUserPlus
																			className='iconAction-edit'
																			title='Assign Users'
																			onClick={() =>{
																				logEvent({category:Categories.staff,action:Actions.staff_assign_btn});
																				history.push({
																					pathname: `/assignusers/${Staff._id}`,
																					state: { data: Staff },
																			   })
																			}}
																		/>
																	</div>
																</td>
															</tr>
														);
													})
												) : (
													<tr>
														<td colSpan='8' style={{ textAlign: 'center' }}>
															{intl.noRecordFound}
														</td>
													</tr>
												)}
											</tbody>
										</table>
									)}
									{showModal ? (
										<AlertModal
											closeModal={closeModal}
											header={intl.deleteHeader}
											body={
												selectedStaff.length > 1
													? intl.bulkDelete
													: intl.deleteBody
											}
											handlerFunction={handleBulkDelete}
										/>
									) : null}
									{(staffList && staffList.length) > 0 ? (
										<div className='count_div '>
											<Pagination
												boundaryRange={1}
												ellipsisItem='...'
												firstItem={null}
												lastItem={null}
												activePage={activePage}
												onPageChange={onChange}
												siblingRange={1}
												totalPages={totalPages}
												disabled={disablePagination}
												className={totalPages === 1 ? 'paginationbutton' : null}
											/>
											<div>Total: {count}</div>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				{showModal2 ? <LogoutModal /> : null}
				{showBulkAddModal && (
					<BulkAddModal
						closeModal={closeBulkAddModal}
						header={'Bulk upload Responder'}
						type={'staff'}
						columnNames={[
							'first_name',
							'last_name',
							'email',
							'phone_no',
							'mac_address',
							'receive_notification',
							'send_notification',
							'receive_call_notification'
						]}
						route={'/api/v1/users/register'}
					/>
				)}
			</div>
			<ToastContainer />
		</>
	);
};
export default withRouter(Staff);
