const sampleData = {
    'staff':[
        ['John1','Doe1','john.doe1@example.com','(1)1234567890','AC2331234580', true, true, false],
        ['John2','Doe2','john.doe2@example.com','(1)1234567891','AC2331234581', false, false, false],
        ['John3','Doe3','john.doe3@example.com','(1)1234567892','AC2331234582', true, false, false],
        ['John4','Doe4','john.doe4@example.com','(1)1234567893','AC2331234583', false, true, false],
        ['John5','Doe5','john.doe5@example.com','(1)1234567894','AC2331234584', false, '', false],
        ['John6','Doe6','john.doe6@example.com','(1)1234567895','AC2331234585','' , true, false],
        ['John7','Doe7','john.doe7@example.com','(1)1234567896','', false,'', false],
        ['John8','Doe8','john.doe8@example.com','(1)1234567897','','' , true, false],
        ['John9','Doe9','john.doe9@example.com','(1)1234567898','AC2331234586', true,'', false],
        ['John10','Doe10','john.doe10@example.com','(1)1234567899','AC2331234587','' , true, false],
        ['John11','Doe11','john.doe11@example.com','(1)1234567810','','' , '', false],
    ],
    'student':[
        ['Michael1','Johnson1', 'AC2331234580',true],
        ['Michael2','Johnson2', 'AC2331234581',false],
        ['Michael3','Johnson3', '',true],
        ['Michael4','Johnson4', 'AC2331234577',],
        ['Michael5','Johnson5', '',false],
        ['Michael6','Johnson6', '',]
    ],
    'badge': [
        ['AC2331234789'],
    ],
    'device': [
        ['device_1','\'10000000000000000002\'','Gateway'],
        ['device_2', '\'10000000000000000003\'', 'Panel']
    ]
}

export default sampleData;