import { Form } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';
import React, { useState } from "react";
import apiInstance from "../../api";
import Strings from "../../locale/en-US.json";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Spinner from "react-bootstrap/Spinner";
import { useRef, useEffect } from "react";
import { getRoleInStore, getSchoolIdInStore, getSchoolNameInStore, removeUserBlockedInStore, removeBlockedByAdminInStore} from "../../utils/apiUtils";
import { errorFunction } from "../../utils/errors";
import LogoutModal from "../Modal/LogoutModal";
import { Actions, Categories, logEvent } from "../../analytics";
import { blockPageStartPolling, stopPolling } from '../../utils/polling';

const VerifyEmailBlock = ({history, match}) => {

    const { token } = match.params;
	const [passwordError, setPasswordError] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
	const [verificationMessage, setVerificationMessage] = useState("");
	const pollingIntervalRef = useRef(null);
	const id = getSchoolIdInStore();
	const schoolName = getSchoolNameInStore();
	const role = getRoleInStore()
	const [showModal2, setShowModal2] = useState(false);
	const timeoutRef = useRef(null)

	useEffect(() => {
	  // Start polling when the component mounts
	  blockPageStartPolling(id, pollAPI, pollingIntervalRef);
  
	  // Stop polling and clear the interval when the component unmounts
	  return () => {
		if (timeoutRef.current) { //clear debounce timeout
			clearTimeout(timeoutRef.current);
		}
		stopPolling(pollingIntervalRef);
	  };
	}, []);
  
	useEffect(() => {
		pollAPI();
	  // Check if the browser tab is focused or not
	  const handleVisibilityChange = () => {
		if (document.hidden) {
		  stopPolling(pollingIntervalRef);
		} else {
		  blockPageStartPolling(id, pollAPI, pollingIntervalRef);
		}
	  };
  
	  // Add event listener for visibility change
	  document.addEventListener("visibilitychange", handleVisibilityChange);
  
	  // Clean up the event listener when the component unmounts
	  return () => {
		document.removeEventListener("visibilitychange", handleVisibilityChange);
	  };
	}, []);
  
  
	const pollAPI = async () => {
		try {
			const lowercasePathname = window.location.pathname.toLowerCase();
			if (!lowercasePathname.includes('email')) {
				stopPolling(pollingIntervalRef);
				return;
			}
			let response; 
			if ( role === 'SuperAdmin') {
				response = await apiInstance.get(`/api/v1/schools`)
				.catch( (error)=> {
					const result = errorFunction(error);
					if (typeof result === 'boolean') {
						setShowModal2(true);
					} else {
						blockPageStartPolling(id, pollAPI, pollingIntervalRef);
					};
				})
			} else {
				response = await apiInstance.get(`/api/v1/schools/${id}/status`)
				.catch( (error)=> {
					const result = errorFunction(error);
					if (typeof result === 'boolean') {
						setShowModal2(true);
					} else {
						blockPageStartPolling(id, pollAPI, pollingIntervalRef);
					};
				})
			}
		  
		  if (response && response.status === 200) {
			removeUserBlockedInStore();
			removeBlockedByAdminInStore();
			setSubmitted(false);
			setVerificationMessage("Verification successful!"); // Update the verification message on success
			stopPolling(pollingIntervalRef); // Stop polling after successful API call
			setTimeout(() => {
				role === 'SuperAdmin'
					? history.push('/organizations')
					: history.push('/dashboard');
			}, 2000);
		  }
		} catch (error) {
			console.log(error);
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				blockPageStartPolling(id, pollAPI, pollingIntervalRef);
			};
		}
	};

	const resendEmail = async() =>{
		logEvent({category:Categories.authentication,action:Actions.resend_verification_email_btn});
		setErrorMsg("");
        setPasswordError("");
        setLoading(true);
		try {
			let response;

			if (!submitted) {
                setSubmitted(true)
				response = await apiInstance.post("/api/v1/auth/resend-verification-email", {reset_token: token});
				setLoading(false);
				timeoutRef.current = setTimeout(() => {
					setSubmitted(false);
				}, 2000);
				if (response && response.status === 200) {
					logEvent({category:Categories.authentication,action:Actions.resend_verification_email_success});
                    toast.success("Successfully Email sent!", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
				}
			}
			setLoading(false);
			
		} catch (error) {
			setLoading(false);
			timeoutRef.current = setTimeout(() => {
				setSubmitted(false);
			}, 2000);
			let errorMsg = "Error in Verifying Email";
			if(error) {
				console.error('------ ', error);
				if (typeof error === 'string') {
					errorMsg = error;
		     	}
				else if(error.response && error.response.data && error.response.data.statusMessage){
					errorMsg = error.response.data.statusMessage;
				 }
		    }
			toast.error(errorMsg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
            });
 
		}
	}


	return (
		<div className='page-wrapper d-block'>
			<div id='root'>
				<div className='authentications'>
					<div
						className='auth-wrapper align-items-center d-flex'
						style={{
							backgroundImage: "url()",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center center",
						}}
					>
						<div className='ui container'>
								<div className='row'>
								<div className='col-lg-2 col-md-2 col-2'></div>
									<div className='col-lg-8 col-md-8 col-8'>
									<div className='border card mt-8'>
                                    

										<div className='card-body'>
											<div className='p-4'>

                                                <div>
                                                    {errorMsg && (
                                                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
                                                            {errorMsg}
                                                        </div>
                                                    )}
                                                </div>
												<h1 className='font-medium mb-3 mt-8' align='center'>
												{
												(role !== 'SuperAdmin' && schoolName && schoolName !== undefined) ? schoolName : ""}
												</h1>

												<h1 className='font-medium mb-3 mt-8' align='center'>
												{verificationMessage ? verificationMessage : Strings.notVerifiedEmail}
													{/* {Strings.notVerifiedEmail} */}
												</h1>
												<Form encType="multipart/form-data">
													<div>
													    {passwordError && (
															<p className='error-message'>{passwordError}</p>
														)}
													</div>
													<div style={{ position: 'relative', minHeight: '100px' }}>
													{loading && (
														<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
														<Spinner size="sm" animation="border" variant="secondary" />
														</div>
													)}
													</div>

													<div className='row'>
													{!verificationMessage ?  
														<div
															className='col-lg-12 col-md-12 col-sm-12 col-12 '
															id='loginpage-links'
															onClick={()=>resendEmail()}
															align='center'
														>
															<p
																className=' login_contact contact_msg'
																style={{textAlign:"center"}}
															>
																{Strings.resendVerificationEmail}
															</p>
														</div>
														: ''}
														
													</div>
												</Form>
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-2 col-md-2 col-2'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showModal2 ? <LogoutModal /> : null}
			<ToastContainer />
		</div>
	);
}

export default withRouter(VerifyEmailBlock);