import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import apiInstance from "../../api";
import { withRouter } from "react-router-dom";
import AlertModal from "../Modal/AlertModal";
import intl from "../../locale/en-US.json";
import { deleteEtag, 
  getListInStore, 
  getEtag, 
  setEtag, 
  setListInStore, 
  BADGES_ETAG_KEY, 
  BADGES_LIST_KEY,
  getPageAndCountInStore,
	BADGES_PAGECOUNT_KEY,
	setPageAndCountInStore,
  } from "../../utils/apiUtils";
import { toast, ToastContainer  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Pagination} from 'semantic-ui-react';
import { Spinner } from 'react-bootstrap';
import { errorFunction } from '../../utils/errors'
import LogoutModal from '../Modal/LogoutModal'
import { Actions, Categories, logEvent } from '../../analytics';
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';
const PAGE_OFFSET = 15;

const BadgeList = ({ history, searchTerm, currentPage, changePage, bulkModal, handleDelete, setHandleDelete, clearSearch, checkUserLength}) => {
  const pollingIntervalRef = React.useRef(null);
  const [badgeList, setBadgeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showHyphen, setShowHyphen] = useState(false);
  const [count, setCount] = useState(0);
	const [totalPages, setTotalPages] = useState(1);
  const [disablePagination, setDisablePagination] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
	const timeoutIdRef = React.useRef(null);
  const [selectedBadges, setSelectedBadges] = useState([]); // for bulk delete
	const [bulkDelete, setBulkDelete] = useState(false); // for showing selected message and select all option
	const [deleteAll, setDeleteAll] = useState(false); // for Total records option
	const [deleteAllCheckbox, setDeleteAllCheckbox] = useState([]); // for storing state of top checkbox on different pages
	const [tempSearchTerm, setTempSearchTerm] = useState(''); 

  useEffect(() => {
    selectedBadges.length >= 1 && setShowModal(true)
    setHandleDelete();
  },[handleDelete]);


  const handlePageChange = (event, { activePage }) => {
    // reset if page change when total records are selected
    if(deleteAll) {
			setBulkDelete(false);
			setDeleteAll(false);
			setSelectedBadges([]);
			checkUserLength([])
			setDeleteAllCheckbox([])
		}
    setIsLoading(true);
		setDisablePagination(true);
    changePage(activePage)
	};
  
  const fetchData = async () => {
    try {
      const lowercasePathname = window.location.pathname.toLowerCase();
      if (!lowercasePathname.includes('badge')) {
        stopPolling(pollingIntervalRef);
        return;
      }
      const headers = {};
      const storedEtag = getEtag(BADGES_ETAG_KEY);
      if (storedEtag) {
        headers["If-None-Match"] = storedEtag;
      }
      //headers['cache-control'] = 'public, max-age=3600';
      const params = {
        page: currentPage,
        limit: PAGE_OFFSET,
        };
      
      
      if (searchTerm) {
        params.search = searchTerm;
      }
      stopPolling(pollingIntervalRef);
      const res = await apiInstance.get('/api/v1/badges', {
      headers,
      params,
      });
      setIsLoading(false);
      if (res.status === 304) {
		  setErrorMessage('');
		  return;
		}
		if (res && res.data && res.data.data) {
			setBadgeList(res.data.data.badges);
			setCount(res.data.data.totalBadges)
			setEtag(BADGES_ETAG_KEY, res.headers.etag);
			setTotalPages(res.data.data.totalPages); // Set the total number of pages
			setListInStore(BADGES_LIST_KEY, JSON.stringify(res.data.data.badges));
			const pageAndCount = {count:res.data.data.totalBadges,
				totalPages:res.data.data.totalPages}
				setPageAndCountInStore(BADGES_PAGECOUNT_KEY, JSON.stringify(pageAndCount));
				setDisablePagination(false);
				setErrorMessage('');
			}
			startPolling(fetchData, pollingIntervalRef);
    } catch (error) {
      startPolling(fetchData, pollingIntervalRef);
      if (error.response && error.response.status === 304) {
        setErrorMessage("")
        return;
      }
      setIsLoading(false);
      const result = errorFunction(error);
      setErrorMessage(result);
      //console.log("result-------- ", result)
      if (typeof result === 'boolean') {
        setShowModal2(true);
      } else {
        // setErrorMessage(result);
      }

      //console.error("Error fetching badges:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    deleteEtag(BADGES_ETAG_KEY);
    const storedBadgelist = getListInStore(BADGES_LIST_KEY);
    const pageCountList = getPageAndCountInStore(BADGES_PAGECOUNT_KEY)
    if (storedBadgelist && pageCountList) {
      setBadgeList(JSON.parse(storedBadgelist));
      const data = JSON.parse(pageCountList);
      setTotalPages(data.totalPages);
      setCount(data.count);
    }

	// when something is searched for.
	if (searchTerm !== '') {
		resetStates();
		setTempSearchTerm(searchTerm);
	}

	// when search is cancelled.
	if (searchTerm === '' && tempSearchTerm !== '') {
		resetStates();
		setTempSearchTerm('');
	}

    setIsLoading(true);
    fetchData();
    return () => {
      setIsLoading(false);
    };
  }, [currentPage, searchTerm, bulkModal]);
  
	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);
  
  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    return () => {
      stopPolling(pollingIntervalRef);
      
      if(timeoutIdRef.current){
				clearTimeout(timeoutIdRef.current);
			}
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, [currentPage, searchTerm]);

  const closeModal = () => setShowModal(false);


  // checkboxes handling
	const handleDeleteCheckbox = (e) => {
		const { value, checked } = e.target;
		if (!checked) {
			setBulkDelete(false);
			setDeleteAll(false);
			setDeleteAllCheckbox(
				deleteAllCheckbox.filter((number) => number !== currentPage)
			);
			checkUserLength(selectedBadges.filter((id) => id !== value));
			setSelectedBadges(selectedBadges.filter((id) => id !== value));
			selectedBadges.length <= 1 ? setShowHyphen(false) : setShowHyphen(true);
		} else {
			setSelectedBadges([...selectedBadges, value]);
			checkUserLength([...selectedBadges, value])
			if (areAllCheckboxesChecked([...selectedBadges, value])) {
				let checkBox = deleteAllCheckbox;
				checkBox.push(currentPage);
				setDeleteAllCheckbox([...checkBox]);
				setShowHyphen(false);
			}
			else {
				setShowHyphen(true);
			}
		}
		// console.log([...selectedStaff, value])
	};

	const areAllCheckboxesChecked = (arr) => {
		return badgeList.every((badge) => arr.includes(badge._id));
	};

	const handleBulkDelete = async () => {
		closeModal();
		setShowHyphen(false);
		logEvent({category:Categories.badge,action:Actions.badge_delete_btn});
		setDisableDelete(true);
		const requestData = {badgeIds: selectedBadges}
		try {
			setSelectedBadges([]);
			checkUserLength([])
			if (selectedBadges.length !== 0) {
				
				let result;
				setIsLoading(true);

				if (deleteAll) {
					result = await apiInstance.delete(`/api/v1/badges/?deleteAll=true`);
				} else {
					result = await apiInstance.delete(`/api/v1/badges/?deleteAll=false`, {data: requestData});
				}	
				if (result && result.status === 200) {
					setIsLoading(false);
					logEvent({category:Categories.badge,action:Actions.badge_delete_success});
					toast.success('Successfully Deleted!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 1000,
					});
					setDisableDelete(false);
					setBulkDelete(false);
					setDeleteAllCheckbox([])
					setDeleteAll(false);
					// when at last page and all are selected
					if (currentPage === totalPages && selectedBadges.length === badgeList.length) {
						// console.log("currentPage === totalPages: ", currentPage === totalPages);
						// console.log("selectedStaff.length === staffList.length: ", selectedBadges.length === badgeList.length);
            			if (searchTerm !== '') {
							clearSearch();
							setDeleteAllCheckbox([]);
						} else {
							changePage(1);
							if (totalPages === 1) fetchData();
							// fetchData();
						}
					}
					// when all selected OR more are selected, only one is remaining on last page
					else if (selectedBadges.length >= PAGE_OFFSET || selectedBadges.length === badgeList.length ) {
						// console.log("selectedBadges.length >= PAGE_OFFSET ", selectedBadges.length >= PAGE_OFFSET);
						// console.log("selectedBadges.length === badgeList.length ", selectedBadges.length === badgeList.length);
						// console.log("selectedBadges.length - 1 === 0 ", selectedBadges.length - 1 === 0);
						currentPage === 1 ? fetchData() : changePage(1);
					}
					// when 1 or more selected 
					else fetchData();
				}
			}
		} catch (error) {
			setIsLoading(false);
			setDeleteAllCheckbox([])
			setDeleteAll(false);
			setDisableDelete(false);
			setBulkDelete(false);
			const result = errorFunction(error);
			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}
		}
		setShowModal(false);
	}

  const handleDeleteAllOnOnePage = (e) => {
		const { checked } = e.target;
		if (badgeList.length !== 0) setBulkDelete(true);
		let staffArr = [];
		badgeList.forEach((student) => staffArr.push(student._id));
		if (!checked) {
			setDeleteAll(false);
			setBulkDelete(false);
			setDeleteAllCheckbox(
				deleteAllCheckbox.filter(
					(deletePageNumber) => deletePageNumber !== currentPage
				)
			);
			checkUserLength(selectedBadges.filter((staff) => !staffArr.includes(staff)));
			setSelectedBadges(selectedBadges.filter((staff) => !staffArr.includes(staff)));
			selectedBadges.length === 0 && setBulkDelete(false);
		} else {
			// for making the top checkbox remain checked in all pages where it is selected
			let checkBox = deleteAllCheckbox;
			checkBox.push(currentPage);
			setDeleteAllCheckbox([...checkBox]);

			staffArr = staffArr.filter((staff) => !selectedBadges.includes(staff));
			setSelectedBadges([...selectedBadges, ...staffArr]);
			checkUserLength([...selectedBadges, ...staffArr]);
		}
	};

	const resetStates = () => {
		setSelectedBadges([]);
		setDeleteAllCheckbox([])
		setDisableDelete(false);
		setBulkDelete(false);
		setDeleteAll(false);
	};

 	return (
		<div className='table-responsive'>
			{bulkDelete && searchTerm === '' && totalPages > 1 ?(
				<div
					className='d-flex justify-content-center align-items-center'
					style={{ marginBottom: '0.5em' }}
				>
					<h6 className='' style={{ padding: '0.5em', marginLeft: '0.7em' }}>
						{deleteAll
							? `All ${count} records are selected`
							: ` ${selectedBadges.length} records are selected`}
					</h6>
					{!deleteAll ? (
						<h6 className='ml-4 delete-all ' onClick={() => setDeleteAll(true)}>
							Select all {count} records
						</h6>
					) : (
						<h6
							onClick={() => {
								setDeleteAll(false);
								setSelectedBadges([]);
								checkUserLength([])
								setBulkDelete(false);
								setDeleteAllCheckbox([]);
							}}
							className='delete-all'
						>
							Clear selection
						</h6>
					)}
				</div>
			):null}
			{errorMessage && (
				<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
					{errorMessage}
				</div>
			)}
			{isLoading ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner  animation="border" variant="secondary" />
				</div>
			) : (
				<table className='table table-bordered'>
					<thead>
						<tr>
							<th
								style={{ paddingBottom: 0 }}
								className='d-flex align-items-center justify-content-center'
							>
								<div className="input-label-container">
									<input
										type='checkbox'
										id='selectAll'
										disabled={(badgeList.length === 0 && true) || disableDelete}
										name='selectAll'
										value='selectAll'
										onChange={(e) => {
											setShowHyphen(false);
											handleDeleteAllOnOnePage(e)}
										}
										checked={deleteAll || deleteAllCheckbox.includes(currentPage)}
									/>
									<label htmlFor='selectAll'></label>
									{showHyphen ? (<span onClick={(e) => {
										setShowHyphen(false);
										handleDeleteAllOnOnePage(e)}
									} className="hyphen hover-pointer ">-</span>): ""}
								</div>
							</th>
							<th>{intl.badgeAddress}</th>
							<th>{intl.assigned}</th>
							<th scope='col' style={{ width: '10%' }}>
								{intl.actions}
							</th>
						</tr>
					</thead>
					<tbody>
						{badgeList && badgeList.length ? (
							badgeList.map((badge, i) => {
								return (
									<tr key={i}>
										<td
											className='pb-0 px-0 d-flex align-items-center justify-content-center'
											style={{
												border: 'none',
												borderColor: 'none',
												paddingTop: '14px'
											}}
										>
											<input
												type='checkbox'
												id={badge._id}
												disabled={disableDelete}
												name={badge._id}
												value={badge._id}
												onChange={(e) => handleDeleteCheckbox(e)}
												checked={deleteAll || (selectedBadges.includes(badge._id) && true)}
											/>
											<label htmlFor={badge._id}></label>
										</td>
										<td>{badge.mac_address}</td>
										<td>
											{/* {badge.user && Object.keys(badge.user).length !== 0  ? (
                        <FaCheck className="GreenIcon" />
                      ) : (
                        <FaTimes className="RedIcon" />
                      )} */}
											{badge.user && Object.keys(badge.user).length !== 0 ? (
												<span>
													<strong> {badge.user.role}: </strong>
													{badge.user.name}
												</span>
											) : (
												'-'
											)}
										</td>
										<td>
											<div className='d-flex'>
												<FaEdit
													className='iconAction-edit'
													title='Edit'
													type='button'
													onClick={() =>{
														history.push({
															pathname: '/addBadge',
															state: { data: badge },
														});
														logEvent({category:Categories.badge,action:Actions.badge_edit_btn});
										
													}
													}
												/>
											</div>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan='3' style={{ textAlign: 'center' }}>
									{intl.noRecordFound}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}
			<div>
				{showModal2 ? <LogoutModal /> : null}

				{showModal ? (
					<AlertModal
						closeModal={closeModal}
						header={intl.deleteHeader}
						body={selectedBadges.length > 1 ? intl.bulkDelete : intl.deleteBody}
						handlerFunction={handleBulkDelete}
					/>
				) : null}
			</div>
			{(badgeList && badgeList.length) > 0 ? (
				<div className='count_div'>
					<Pagination
						boundaryRange={1}
						ellipsisItem='...'
						firstItem={null}
						lastItem={null}
						activePage={currentPage}
						totalPages={totalPages}
						siblingRange={1}
						onPageChange={handlePageChange}
						disabled={disablePagination}
						className={totalPages === 1 ? 'paginationbutton' : null}
					/>
					<div>Total: {count}</div>
				</div>
			) : (
				''
			)}
			<ToastContainer />
		</div>
	);
};

export default withRouter(BadgeList);