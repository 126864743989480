import React from "react";
import intl from '../../locale/en-US.json';


const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <h1 className="not-found-text">{intl.pageNotFound}</h1>
    </div>
  );
};

export default NotFoundPage;