import { Form, Image } from 'semantic-ui-react';
import AllImages from '../../assets/images/images.js';
import React, { useState, useRef } from 'react';
import apiInstance from '../../api';
import Strings from '../../locale/en-US.json';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { version } from '../../../package.json';
import { withRouter } from 'react-router-dom';
//import AlertModal from '../Modal/AlertModal';
import { toast } from 'react-toastify';
import {
	setAccessTokenInStore,
	setRefreshTokenInStore,
	setRoleInStore,
	setUserIdInStore,
	setSchoolIdInStore,
	setPhoneNumberInStore,
	setSchoolNameInStore,
} from '../../utils/apiUtils';
import {
	MISMATCH_PASSWORD,
	LOGO_LENGTH,
	LOGO_REQUIRED,
	ERROR_OCCURED,
	REGISTERED_SUCCESS,
	PASSWORD_ERROR,
	PHONENO_ERROR,
	LENGTH_MAX,
} from '../../strings';
import { phoneCodes } from '../../utils/phoneCodes';
import { maxLength } from '../../utils/apiUtils';
import Spinner from 'react-bootstrap/Spinner';
import { Dropdown } from 'semantic-ui-react';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { tz } from 'moment-timezone';
import { useEffect } from 'react';
import { Actions, Categories, logEvent } from '../../analytics';
import { errorFunction } from '../../utils/errors';
import { guessCountryCode } from '../../utils/common.js';

const MAX_LOGO_SIZE = 3 * 1024 * 1024;
const MAX_LENGTH = 255;
const MIN_LENGTH = 3;

const SignUpPage = ({ history, onSignup }) => {
	//const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	const [email, setEmail] = useState('');
	const [schoolName, setSchoolName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [password, setPassword] = useState('');
	const [schoolLogo, setSchoolLogo] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [phoneAreaCode, setAreaPhoneCode] = useState('+1 CA');
	const [phoneNumber, setPhoneNumber] = useState('');
	const gender = 'Male';
	const [errorMsg, setErrorMsg] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const isAnyInputFocused = useInputFocus();
	//let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);
	const errorMsgRef = useRef(null);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	useEffect(() => {
		if (errorMsgRef.current) {
			errorMsgRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}
	}, [errorMsg]);

	useEffect(() => {
		try {
			const searchParams = new URLSearchParams(window.location.search);
			const emailFromUrl = searchParams.get('email') || null;
			if (emailFromUrl) {
				setEmail(emailFromUrl);
			}
			let detectedCountryCode = guessCountryCode();
			detectedCountryCode && setAreaPhoneCode(detectedCountryCode);
		} catch (error) {
			console.error('Error in detecting Country Phone Code ', error);
		}
	}, []);

	// const genderOptions = [
	// 	{ key: 1, text: 'Male', value: 'Male' },
	// 	{ key: 2, text: 'Female', value: 'Female' },
	// 	{ key: 3, text: 'Other', value: 'Other' },
	// ];

	const handleNavigation = (path) => {
		history.push(path);
	};

	const blockCheck = async (id, role) => {
		const response = await apiInstance
			.get(`/api/v1/schools/${id}/status`)
			.catch((error) => {
				console.log(error);
			});
		if (response) {
			if (role === 'SchoolAdmin') history.push('/dashboard');
		}
	};

	const handleFormChange = async (event) => {
		event.preventDefault();
		console.log(event.nativeEvent.inputType);
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	};

	// useEffect(() => {
	// 	const handleBeforeUnload = (e) => {
	// 		if (!allowNavigationRef.current) {
	// 			if (!checkUnsavedChanges()) {
	// 				e.preventDefault();
	// 				e.returnValue =
	// 					'You have unsaved changes. Are you sure you want to leave this page?';
	// 			}
	// 		}
	// 	};

	// 	window.addEventListener('beforeunload', handleBeforeUnload);

	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, []);

	// const closeUnsavedModal = () => setShowUnsavedModal(false);

	// const checkUnsavedChanges = () => {
	// 	if (!formChanged.current) {
	// 		return true;
	// 	}
	// 	return false;
	// };

	// const navigationBlockCheck = (nextLocation) => {
	// 	nextInHistoryRef.current = nextLocation && nextLocation.pathname;
	// 	if (allowNavigationRef.current) {
	// 		return true;
	// 	}
	// 	if (checkUnsavedChanges()) {
	// 		return true;
	// 	} else {
	// 		setShowUnsavedModal(true);
	// 		return false;
	// 	}
	// };

	// const handleNavigationConfirmation = () => {
	// 	setShowUnsavedModal(false);
	// 	allowNavigationRef.current = true;
	// 	history.push(nextInHistoryRef.current);
	// };

	// useEffect(() => {
	// 	const unblock = history.block(navigationBlockCheck);

	// 	return () => {
	// 		unblock();
	// 	};
	// }, [history]);

	function handleLogoChange(event) {
		const selectedFile = event.target.files[0];
		formChanged.current = true;
		if (selectedFile) {
			const allowedTypes = ['image/png', 'image/jpeg'];
			const maxSize = MAX_LOGO_SIZE; // Assuming MAX_LOGO_SIZE is defined elsewhere

			if (
				allowedTypes.includes(selectedFile.type) &&
				selectedFile.size <= maxSize
			) {
				const reader = new FileReader();
				reader.onloadend = () => {
					setPreviewImage(reader.result);
				};
				reader.readAsDataURL(selectedFile);
				setSchoolLogo(selectedFile);
				setErrorMsg('');
			} else {
				setErrorMsg(
					selectedFile.size > maxSize
						? LOGO_LENGTH
						: 'Please select a valid image file (PNG or JPEG).'
				);
				setPreviewImage(null);
				setSchoolLogo('');
				event.target.value = ''; // Reset the input
			}
		} else {
			setErrorMsg('');
			setPreviewImage(null);
			setSchoolLogo('');
		}
	}

	const handleSubmit = async (e) => {
		logEvent({
			category: Categories.authentication,
			action: Actions.signup_submitted_btn,
		});
		e.preventDefault();
		setErrorMsg('');
		if (password !== confirmPassword) {
			setErrorMsg(MISMATCH_PASSWORD);

			return;
		} else setErrorMsg('');

		if (schoolName.length < MIN_LENGTH || schoolName.length > MAX_LENGTH) {
			setErrorMsg(Strings.schoolName + ' : ' + LENGTH_MAX);
			return;
		}

		if (firstName.length < MIN_LENGTH || firstName.length > MAX_LENGTH) {
			setErrorMsg(Strings.firstName + ' : ' + LENGTH_MAX);
			return;
		}

		if (lastName.length < MIN_LENGTH || lastName.length > MAX_LENGTH) {
			setErrorMsg(Strings.lastName + ' : ' + LENGTH_MAX);
			return;
		}

		if (!schoolLogo) {
			setErrorMsg(LOGO_REQUIRED);
			return;
		}

		if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,40}$/.test(password)) {
			setErrorMsg(PASSWORD_ERROR);
			return;
		}

		if (gender === '') {
			setErrorMsg("Gender can't be empty");
			return;
		}

		if (
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length <
				8 ||
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length >
				15
		) {
			setErrorMsg(PHONENO_ERROR);
			return;
		}
		const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!email.match(emailRegex)) {
			setErrorMsg('Invalid Email format');
			return false;
		}

		const atIndex = email.indexOf('@');
		const lastCharBeforeAt = email[atIndex - 1];
		const lastCharBeforeAtRegex = /^[a-zA-Z0-9]$/;
		if (!lastCharBeforeAt.match(lastCharBeforeAtRegex)) {
			setErrorMsg('Invalid Email format');
			return false;
		}
		const timezone = tz.guess();
		const formData = new FormData();
		formData.append('school_name', schoolName);
		formData.append('first_name', firstName);
		formData.append('last_name', lastName);
		formData.append('email', email);
		formData.append('password', password);
		formData.append('logo', schoolLogo);
		formData.append('timezone', timezone);
		formData.append('role', 'SchoolAdmin');
		formData.append('gender', gender);
		formData.append(
			'phone_no',
			`${phoneAreaCode.split(' ')[0]}-${phoneNumber}`
		);

		try {
			let response;

			if (!submitted) {
				setLoading(true);
				setSubmitted(true);
				response = await apiInstance.post('/api/v1/schools/register', formData);

				if (response.status === 200) {
					if (response.data && response.data.user) {
						allowNavigationRef.current = true;
						const signUpInfo = response.data.user;
						setAccessTokenInStore(signUpInfo.access_token);
						setRefreshTokenInStore(signUpInfo.refresh_token);
						setRoleInStore(signUpInfo.role);
						setUserIdInStore(signUpInfo.user_id);
						setSchoolIdInStore(signUpInfo.school_id);
						setSchoolNameInStore(signUpInfo.school_name);
						setLoading(false);
						setPhoneNumberInStore(signUpInfo.phone_no);
						toast.success(REGISTERED_SUCCESS, {
							position: toast.POSITION.TOP_CENTER,
							autoClose: 2000, // Toast message will be shown for 2 seconds
						});

						logEvent({
							category: Categories.authentication,
							action: Actions.signup_submitted_success,
						});
						blockCheck(signUpInfo.user_id, signUpInfo.role);
					}
				}
			}
		} catch (error) {
			let errorMsg = ERROR_OCCURED;
			setLoading(false);
			setSubmitted(false);
			const result = errorFunction(error);

			if (typeof result === 'boolean') {
				setErrorMsg(errorMsg);
			} else {
				setErrorMsg(result);
			}
		}
	};

	return (
		<div className='page-wrapper d-block'>
			<div className='ui container'>
				<div className='row'>
					<div className='col-lg-4 col-md-2 col-2'></div>
					<div className='col-lg-4 col-md-8 col-8'>
						<div className='logo_top' align='center'>
							<Image
								src={AllImages['logo']}
								alt='Logo'
								className='logo_class'
							/>
							<p>v {version}</p>
						</div>
					</div>
					<div className='col-lg-4 col-md-2 col-2'></div>
				</div>
			</div>
			<div id='root'>
				<div className='authentications'>
					<div
						className='auth-wrapper align-items-center d-flex'
						style={{
							backgroundImage: 'url()',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center center',
						}}
					>
						<div className='ui container'>
							<div className='row'>
								<div className='col-lg-3 col-md-1 col-1'></div>
								<div className='col-lg-6 col-md-10 col-10'>
									<div className='border card'>
										<div className='card-body'>
											<div className='p-4'>
												<h3 className='font-medium mb-3' align='center'>
													{Strings.signUp}
												</h3>
												<Form
													onSubmit={handleSubmit}
													onChange={handleFormChange}
													encType='multipart/form-data'
												>
													<Form.Field className='double-height'>
														<label>
															{Strings.organizationName}
															<span className='font_red'>*</span>
														</label>
														<input
															placeholder={Strings.organizationName}
															type='text'
															value={schoolName}
															onChange={(e) =>
																setSchoolName(maxLength(e.target.value, 50))
															}
															required
														/>
													</Form.Field>
													<Form.Field className='double-height'>
														<label>
															{Strings.organizationLogo}
															<span className='font_red'>*</span>
														</label>
														<div className='file-input-container'>
															{previewImage && (
																<div className='image-preview'>
																	<img
																		src={previewImage}
																		alt='Logo'
																		style={{ width: '50px', height: '50px' }}
																	/>
																</div>
															)}
															<input
																type='file'
																accept='image/png, image/jpeg'
																name='logo'
																required
																onChange={handleLogoChange}
															/>
														</div>
													</Form.Field>
													<Form.Field className='double-height'>
														<label>
															{Strings.firstName}
															<span className='font_red'>*</span>
														</label>
														<input
															placeholder={Strings.firstName}
															type='text'
															value={firstName}
															onChange={(e) =>
																setFirstName(maxLength(e.target.value, 50))
															}
															required
														/>
													</Form.Field>
													<Form.Field className='double-height'>
														<label>
															{Strings.lastName}
															<span className='font_red'>*</span>
														</label>
														<input
															id='username'
															name='username'
															placeholder={Strings.lastName}
															type='text'
															value={lastName}
															onChange={(e) =>
																setLastName(maxLength(e.target.value, 50))
															}
															required
														/>
													</Form.Field>
													{/* <Form.Field className='double-height'>
														<label className='form-label'>
															{Strings.gender}
															<span className='font_red'>*</span>
														</label>
														<div className='form-group'>
															<span className='GenderGroup'>
																<Dropdown
																	options={genderOptions}
																	required
																	selection
																	placeholder='Gender'
																	style={{minWidth: '100%'}}
																	value={gender}
																	onChange={(e, { value }) => {
																		formChanged.current =true
																		setGender(value)}
																	}
						
																/>
															</span>
														</div>
													</Form.Field> */}
													<Form.Field className='double-height'>
														<label>
															{/* {Strings.email}<span className='font_red'>*</span> */}
															{Strings.email}
															<span className='font_red'>*</span>
															<span
																style={{
																	fontSize: '10px',
																	fontWeight: '150',
																	marginLeft: '5px',
																}}
															>
																({Strings.emailReasonMessage})
															</span>
														</label>
														<input
															id='email'
															name='email'
															placeholder={Strings.email}
															type='email'
															value={email}
															onChange={(e) =>
																setEmail(maxLength(e.target.value, 50))
															}
															required
														/>
													</Form.Field>
													<Form.Field>
														<label>
															{/* {Strings.phoneNo}
															<span className='font_red'>*</span> */}
															{/* {Strings.email}<span className='font_red'>*</span> */}
															{Strings.phoneNo}
															<span className='font_red'>*</span>
															<span
																style={{
																	fontSize: '10px',
																	fontWeight: '150',
																	marginLeft: '5px',
																}}
															>
																({Strings.smsReasonMessage})
															</span>
														</label>
														<div className='row'>
															<div className='input_margin col-lg-5 col-md-5 col-sm-5 col-12 '>
																<Dropdown
																	options={phoneCodes}
																	className='drop-down'
																	selection
																	search
																	style={{ minWidth: '6em' }}
																	value={phoneAreaCode}
																	onChange={(e, { value }) => {
																		formChanged.current = true;
																		setAreaPhoneCode(value);
																	}}
																/>
															</div>

															<div className='col-lg-7 col-md-7 col-sm-7 col-12'>
																<input
																	type='number'
																	className='w-100'
																	name='phoneNumber'
																	placeholder={Strings.phoneNo}
																	onKeyDown={(event) => {
																		const allowedKeys = [
																			'Backspace',
																			'Delete',
																			'ArrowLeft',
																			'ArrowRight',
																			'Tab',
																		];
																		if (
																			!allowedKeys.includes(event.key) &&
																			(isNaN(Number(event.key)) ||
																				event.key === ' ')
																		) {
																			event.preventDefault();
																		}
																	}}
																	value={phoneNumber}
																	onChange={(event) =>
																		setPhoneNumber(
																			maxLength(event.target.value, 20)
																		)
																	}
																	required
																/>
															</div>
														</div>
													</Form.Field>

													<Form.Field className='double-height'>
														<label>
															{Strings.password}
															<span className='font_red'>*</span>
														</label>
														<input
															id='password'
															name='password'
															placeholder={Strings.password}
															type='password'
															value={password}
															onChange={(e) =>
																setPassword(maxLength(e.target.value, 40))
															}
															required
														/>
														<div>
															<ul>
																<li>{Strings.PasswordValidationLength}</li>
																<li>{Strings.PasswordValidationType}</li>
															</ul>
														</div>
													</Form.Field>
													<Form.Field className='double-height'>
														<label>
															{Strings.confirmPassword}
															<span className='font_red'>*</span>
														</label>
														<input
															id='confirmPassword'
															name='confirmPassword'
															placeholder={Strings.confirmPassword}
															type='password'
															value={confirmPassword}
															onChange={(e) =>
																setConfirmPassword(
																	maxLength(e.target.value, 40)
																)
															}
															required
														/>
													</Form.Field>

													<div className='mb-4'>
														<p>
															By signing up you agreeing to our{' '}
															<a
																href={
																	window.location.origin +
																	'/privacy_pages/user-agreement.html'
																}
																target='_blank'
																rel='noopener noreferrer'
															>
																End User License Agreement
															</a>
															{' '} and{' '}
															<a
																href={
																	window.location.origin +
																	'/privacy_pages/privacy-policy.html'
																}
																target='_blank'
																rel='noopener noreferrer'
															>
																Privacy Policy
															</a>
															{/* , and{' '}
															<a
																href={
																	window.location.origin +
																	'/privacy_pages/privacy-policy.html'
																}
																target='_blank'
																rel='noopener noreferrer'
															>
																Terms & Conditions
															</a> */}
														</p>
													</div>
													{errorMsg && (
														<p ref={errorMsgRef} className='error-message'>
															{errorMsg}
														</p>
													)}
													<div align='center'>
														<button
															className='btn btn-login btn-lg btn-block loginloader LoginBtnLoader mb-2'
															type='submit'
														>
															{loading ? <Spinner size='sm' /> : Strings.signUp}
														</button>
													</div>
													<div className='row'>
														<div
															className='col-lg-4 col-md-4 col-sm-4 col-12 '
															// id='loginpage-links'
														>
															<span className='login_contact float-left blackText hover-pointer'>
																<span
																	onClick={() => {
																		handleNavigation('/login');
																		logEvent({
																			category: Categories.authentication,
																			action: Actions.signin_btn,
																		});
																	}}
																>
																	{Strings.signIn}
																</span>
															</span>
														</div>
														<div
															className='col-lg-4 col-md-4 col-sm-4 col-12 justify-content-center'
															// id='loginpage-links'
														>
															<span className='login_contact blackText hover-pointer'>
																<span
																	onClick={() => {
																		handleNavigation('/forgotpassword');
																		logEvent({
																			category: Categories.authentication,
																			action: Actions.forgot_pwd_btn,
																		});
																	}}
																>
																	{Strings.forgotPassword}
																</span>
															</span>
														</div>
														<div
															className='col-lg-4 col-md-4 col-sm-4 col-12'
															// id='loginpage-links'
														>
															<span className='login_contact  float	 blackText hover-pointer'>
																<span
																	onClick={() => {
																		handleNavigation('/contactus');
																		logEvent({
																			category: Categories.feedback,
																			action: Actions.contact_us_btn,
																		});
																	}}
																>
																	{Strings.contactUs}
																</span>
															</span>
														</div>
													</div>
												</Form>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-2 col-2'></div>
								</div>
								<div className='col-lg-3 col-md-1 col-1'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {showUnsavedModal ? (
				<AlertModal
					closeModal={closeUnsavedModal}
					header={Strings.unsavedChanges}
					body={Strings.unsavedChangesText}
					handlerFunction={handleNavigationConfirmation}
				/>
			) : null} */}
			<ToastContainer />
		</div>
	);
};

export default withRouter(SignUpPage);
