export const phoneCodes = [
	{ key: 0, text: '+1 CA', value: '+1 CA' },
	{ key: 1, text: '+1 MP', value: '+1 MP' },
	{ key: 2, text: '+1 PR', value: '+1 PR' },
	{ key: 3, text: '+1 TT', value: '+1 TT' },
	{ key: 4, text: '+1 USA', value: '+1 USA' },
	{ key: 5, text: '+1 VI', value: '+1 VI' },
	{ key: 6, text: '+7 RUS', value: '+7 RUS' },
	{ key: 7, text: '+20 EGY', value: '+20 EGY' },
	{ key: 8, text: '+27 ZAF', value: '+27 ZAF' },
	{ key: 9, text: '+30 GRC', value: '+30 GRC' },
	{ key: 10, text: '+31 NLD', value: '+31 NLD' },
	{ key: 11, text: '+32 BEL', value: '+32 BEL' },
	{ key: 12, text: '+33 FRA', value: '+33 FRA' },
	{ key: 13, text: '+34 ESP', value: '+34 ESP' },
	{ key: 14, text: '+36 HUN', value: '+36 HUN' },
	{ key: 15, text: '+39 ITA', value: '+39 ITA' },
	{ key: 16, text: '+40 ROU', value: '+40 ROU' },
	{ key: 17, text: '+41 CHE', value: '+41 CHE' },
	{ key: 18, text: '+43 AUT', value: '+43 AUT' },
	{ key: 19, text: '+44 GBR', value: '+44 GBR' },
	{ key: 20, text: '+45 DNK', value: '+45 DNK' },
	{ key: 21, text: '+46 SWE', value: '+46 SWE' },
	{ key: 22, text: '+47 NOR', value: '+47 NOR' },
	{ key: 23, text: '+48 POL', value: '+48 POL' },
	{ key: 24, text: '+49 DEU', value: '+49 DEU' },
	{ key: 25, text: '+51 PER', value: '+51 PER' },
	{ key: 26, text: '+52 MEX', value: '+52 MEX' },
	{ key: 27, text: '+53 CUB', value: '+53 CUB' },
	{ key: 28, text: '+54 ARG', value: '+54 ARG' },
	{ key: 29, text: '+55 BRA', value: '+55 BRA' },
	{ key: 30, text: '+56 CHL', value: '+56 CHL' },
	{ key: 31, text: '+57 COL', value: '+57 COL' },
	{ key: 32, text: '+58 VEN', value: '+58 VEN' },
	{ key: 33, text: '+60 MYS', value: '+60 MYS' },
	{ key: 34, text: '+61 AUS', value: '+61 AUS' },
	{ key: 35, text: '+62 IDN', value: '+62 IDN' },
	{ key: 36, text: '+63 PHL', value: '+63 PHL' },
	{ key: 37, text: '+64 NZL', value: '+64 NZL' },
	{ key: 38, text: '+65 SGP', value: '+65 SGP' },
	{ key: 39, text: '+66 THA', value: '+66 THA' },
	{ key: 40, text: '+81 JPN', value: '+81 JPN' },
	{ key: 41, text: '+82 KOR', value: '+82 KOR' },
	{ key: 42, text: '+84 VNM', value: '+84 VNM' },
	{ key: 43, text: '+86 CHN', value: '+86 CHN' },
	{ key: 44, text: '+90 TUR', value: '+90 TUR' },
	{ key: 45, text: '+91 IND', value: '+91 IND' },
	{ key: 46, text: '+92 PAK', value: '+92 PAK' },
	{ key: 47, text: '+93 AFG', value: '+93 AFG' },
	{ key: 48, text: '+94 LKA', value: '+94 LKA' },
	{ key: 49, text: '+95 MMR', value: '+95 MMR' },
	{ key: 50, text: '+98 IRN', value: '+98 IRN' },
	{ key: 51, text: '+212 MAR', value: '+212 MAR' },
	{ key: 52, text: '+213 DZA', value: '+213 DZA' },
	{ key: 53, text: '+216 TUN', value: '+216 TUN' },
	{ key: 54, text: '+218 LBY', value: '+218 LBY' },
	{ key: 55, text: '+220 GMB', value: '+220 GMB' },
	{ key: 56, text: '+221 SEN', value: '+221 SEN' },
	{ key: 57, text: '+222 MRT', value: '+222 MRT' },
	{ key: 58, text: '+223 MLI', value: '+223 MLI' },
	{ key: 59, text: '+224 GIN', value: '+224 GIN' },
	{ key: 60, text: '+225 CIV', value: '+225 CIV' },
	{ key: 61, text: '+226 BFA', value: '+226 BFA' },
	{ key: 62, text: '+227 NER', value: '+227 NER' },
	{ key: 63, text: '+228 TGO', value: '+228 TGO' },
	{ key: 64, text: '+229 BEN', value: '+229 BEN' },
	{ key: 65, text: '+230 MUS', value: '+230 MUS' },
	{ key: 66, text: '+231 LBR', value: '+231 LBR' },
	{ key: 67, text: '+232 SLE', value: '+232 SLE' },
	{ key: 68, text: '+233 GHA', value: '+233 GHA' },
	{ key: 69, text: '+234 NGA', value: '+234 NGA' },
	{ key: 70, text: '+235 TCD', value: '+235 TCD' },
	{ key: 71, text: '+236 CAF', value: '+236 CAF' },
	{ key: 72, text: '+237 CMR', value: '+237 CMR' },
	{ key: 73, text: '+238 CPV', value: '+238 CPV' },
	{ key: 74, text: '+239 STP', value: '+239 STP' },
	{ key: 75, text: '+240 GNQ', value: '+240 GNQ' },
	{ key: 76, text: '+241 GAB', value: '+241 GAB' },
	{ key: 77, text: '+242 BHS', value: '+242 BHS' },
	{ key: 78, text: '+242 COG', value: '+242 COG' },
	{ key: 79, text: '+243 CDF', value: '+243 CDF' },
	{ key: 80, text: '+244 AGO', value: '+244 AGO' },
	{ key: 81, text: '+245 GNB', value: '+245 GNB' },
	{ key: 82, text: '+246 BRB', value: '+246 BRB' },
	{ key: 83, text: '+248 SYC', value: '+248 SYC' },
	{ key: 84, text: '+249 SDN', value: '+249 SDN' },
	{ key: 85, text: '+250 RWA', value: '+250 RWA' },
	{ key: 86, text: '+251 ETH', value: '+251 ETH' },
	{ key: 87, text: '+252 SOM', value: '+252 SOM' },
	{ key: 88, text: '+253 DJI', value: '+253 DJI' },
	{ key: 89, text: '+254 KEN', value: '+254 KEN' },
	{ key: 90, text: '+255 TZA', value: '+255 TZA' },
	{ key: 91, text: '+256 UGA', value: '+256 UGA' },
	{ key: 92, text: '+257 BDI', value: '+257 BDI' },
	{ key: 93, text: '+258 MOZ', value: '+258 MOZ' },
	{ key: 94, text: '+260 ZMB', value: '+260 ZMB' },
	{ key: 95, text: '+261 MDG', value: '+261 MDG' },
	{ key: 96, text: '+262 REU', value: '+262 REU' },
	{ key: 97, text: '+263 ZWE', value: '+263 ZWE' },
	{ key: 98, text: '+264 NAM', value: '+264 NAM' },
	{ key: 99, text: '+265 MWI', value: '+265 MWI' },
	{ key: 100, text: '+266 LSO', value: '+266 LSO' },
	{ key: 101, text: '+267 BWA', value: '+267 BWA' },
	{ key: 102, text: '+268 SWZ', value: '+268 SWZ' },
	{ key: 103, text: '+269 COM', value: '+269 COM' },
	{ key: 104, text: '+290 SHN', value: '+290 SHN' },
	{ key: 105, text: '+291 ERI', value: '+291 ERI' },
	{ key: 106, text: '+297 ABW', value: '+297 ABW' },
	{ key: 107, text: '+298 FRO', value: '+298 FRO' },
	{ key: 108, text: '+299 GRL', value: '+299 GRL' },
	{ key: 109, text: '+350 GIB', value: '+350 GIB' },
	{ key: 110, text: '+351 PRT', value: '+351 PRT' },
	{ key: 111, text: '+352 LUX', value: '+352 LUX' },
	{ key: 112, text: '+353 IRL', value: '+353 IRL' },
	{ key: 113, text: '+354 ISL', value: '+354 ISL' },
	{ key: 114, text: '+355 ALB', value: '+355 ALB' },
	{ key: 115, text: '+356 MLT', value: '+356 MLT' },
	{ key: 116, text: '+357 CYP', value: '+357 CYP' },
	{ key: 117, text: '+358 FIN', value: '+358 FIN' },
	{ key: 118, text: '+359 BGR', value: '+359 BGR' },
	{ key: 119, text: '+370 LTU', value: '+370 LTU' },
	{ key: 120, text: '+371 LVA', value: '+371 LVA' },
	{ key: 121, text: '+372 EST', value: '+372 EST' },
	{ key: 122, text: '+373 MDA', value: '+373 MDA' },
	{ key: 123, text: '+374 ARM', value: '+374 ARM' },
	{ key: 124, text: '+375 BLR', value: '+375 BLR' },
	{ key: 125, text: '+376 AND', value: '+376 AND' },
	{ key: 126, text: '+377 MCO', value: '+377 MCO' },
	{ key: 127, text: '+378 SMR', value: '+378 SMR' },
	{ key: 128, text: '+379 VAT', value: '+379 VAT' },
	{ key: 129, text: '+380 UKR', value: '+380 UKR' },
	{ key: 130, text: '+381 SRB', value: '+381 SRB' },
	{ key: 131, text: '+382 MNE', value: '+382 MNE' },
	{ key: 132, text: '+385 HRV', value: '+385 HRV' },
	{ key: 133, text: '+386 SVN', value: '+386 SVN' },
	{ key: 134, text: '+387 BIH', value: '+387 BIH' },
	{ key: 135, text: '+389 MKD', value: '+389 MKD' },
	{ key: 136, text: '+420 CZE', value: '+420 CZE' },
	{ key: 137, text: '+421 SVK', value: '+421 SVK' },
	{ key: 138, text: '+423 LIE', value: '+423 LIE' },
	{ key: 139, text: '+500 FLK', value: '+500 FLK' },
	{ key: 140, text: '+501 BLZ', value: '+501 BLZ' },
	{ key: 141, text: '+502 GTM', value: '+502 GTM' },
	{ key: 142, text: '+503 SLV', value: '+503 SLV' },
	{ key: 143, text: '+504 HND', value: '+504 HND' },
	{ key: 144, text: '+505 NIC', value: '+505 NIC' },
	{ key: 145, text: '+506 CRI', value: '+506 CRI' },
	{ key: 146, text: '+507 PAN', value: '+507 PAN' },
	{ key: 147, text: '+508 SPM', value: '+508 SPM' },
	{ key: 148, text: '+509 HTI', value: '+509 HTI' },
	{ key: 149, text: '+590 GLP', value: '+590 GLP' },
	{ key: 150, text: '+591 BOL', value: '+591 BOL' },
	{ key: 151, text: '+592 GUY', value: '+592 GUY' },
	{ key: 152, text: '+593 ECU', value: '+593 ECU' },
	{ key: 153, text: '+594 GUF', value: '+594 GUF' },
	{ key: 154, text: '+595 PRY', value: '+595 PRY' },
	{ key: 155, text: '+596 MTQ', value: '+596 MTQ' },
	{ key: 156, text: '+597 SUR', value: '+597 SUR' },
	{ key: 157, text: '+598 URY', value: '+598 URY' },
	{ key: 158, text: '+599 ANT', value: '+599 ANT' },
	{ key: 159, text: '+599 BES', value: '+599 BES' },
	{ key: 160, text: '+599 CUW', value: '+599 CUW' },
	{ key: 161, text: '+670 TLS', value: '+670 TLS' },
	{ key: 162, text: '+672 ATA', value: '+672 ATA' },
	{ key: 163, text: '+673 BRN', value: '+673 BRN' },
	{ key: 164, text: '+674 NRU', value: '+674 NRU' },
	{ key: 165, text: '+675 PNG', value: '+675 PNG' },
	{ key: 166, text: '+676 TON', value: '+676 TON' },
	{ key: 167, text: '+677 SLB', value: '+677 SLB' },
	{ key: 168, text: '+678 VUT', value: '+678 VUT' },
	{ key: 169, text: '+679 FJI', value: '+679 FJI' },
	{ key: 170, text: '+680 PLW', value: '+680 PLW' },
	{ key: 171, text: '+681 WLF', value: '+681 WLF' },
	{ key: 172, text: '+682 COK', value: '+682 COK' },
	{ key: 173, text: '+683 NIU', value: '+683 NIU' },
	{ key: 174, text: '+685 WSM', value: '+685 WSM' },
	{ key: 175, text: '+686 KIR', value: '+686 KIR' },
	{ key: 176, text: '+687 NCL', value: '+687 NCL' },
	{ key: 177, text: '+688 TUV', value: '+688 TUV' },
	{ key: 178, text: '+689 PYF', value: '+689 PYF' },
	{ key: 179, text: '+690 TKL', value: '+690 TKL' },
	{ key: 180, text: '+691 FSM', value: '+691 FSM' },
	{ key: 181, text: '+692 MHL', value: '+692 MHL' },
	{ key: 182, text: '+850 PRK', value: '+850 PRK' },
	{ key: 183, text: '+852 HKG', value: '+852 HKG' },
	{ key: 184, text: '+853 MAC', value: '+853 MAC' },
	{ key: 185, text: '+855 KHM', value: '+855 KHM' },
	{ key: 186, text: '+856 LAO', value: '+856 LAO' },
	{ key: 187, text: '+880 BGD', value: '+880 BGD' },
	{ key: 188, text: '+886 TWN', value: '+886 TWN' },
	{ key: 189, text: '+960 MDV', value: '+960 MDV' },
	{ key: 190, text: '+961 LBN', value: '+961 LBN' },
	{ key: 191, text: '+962 JOR', value: '+962 JOR' },
	{ key: 192, text: '+963 SYR', value: '+963 SYR' },
	{ key: 193, text: '+964 IRQ', value: '+964 IRQ' },
	{ key: 194, text: '+965 KWT', value: '+965 KWT' },
	{ key: 195, text: '+966 SAU', value: '+966 SAU' },
	{ key: 196, text: '+967 YEM', value: '+967 YEM' },
	{ key: 197, text: '+968 OMN', value: '+968 OMN' },
	{ key: 198, text: '+970 PSE', value: '+970 PSE' },
	{ key: 199, text: '+971 ARE', value: '+971 ARE' },
	{ key: 200, text: '+972 ISR', value: '+972 ISR' },
	{ key: 201, text: '+973 BHR', value: '+973 BHR' },
	{ key: 202, text: '+974 QAT', value: '+974 QAT' },
	{ key: 203, text: '+975 BTN', value: '+975 BTN' },
	{ key: 204, text: '+976 MNG', value: '+976 MNG' },
	{ key: 205, text: '+977 NPL', value: '+977 NPL' },
	{ key: 206, text: '+992 TJK', value: '+992 TJK' },
	{ key: 207, text: '+993 TKM', value: '+993 TKM' },
	{ key: 208, text: '+994 AZE', value: '+994 AZE' },
	{ key: 209, text: '+995 GEO', value: '+995 GEO' },
	{ key: 210, text: '+996 KGZ', value: '+996 KGZ' },
	{ key: 211, text: '+998 UZB', value: '+998 UZB' }
  ];