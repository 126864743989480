import React, { useState, useEffect, useRef } from 'react';
import {FaTimes, FaPlus, FaEdit, FaTrashAlt} from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import apiInstance from '../../api';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import AlertModal from '../Modal/AlertModal';
import { Pagination } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { errorFunction } from '../../utils/errors'
import LogoutModal from '../Modal/LogoutModal'
import {maxLength} from '../../utils/apiUtils';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { deleteEtag, getEtag, setEtag, BADGES_ETAG_KEY } from "../../utils/apiUtils";
import { Categories, logEvent, Actions } from "../../analytics";
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';

const PAGE_OFFSET = 15;

const Plan = ({ history }) => {
	const [searchText, setSearchText] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [planList, setPlanList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [planId, setDeletePlanId] = useState(null);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');
	const [showModal2, setShowModal2] = useState(false);
	const isAnyInputFocused = useInputFocus();
	const pollingIntervalRef = useRef(null);
	const timeoutIdRef = useRef(null);

	
	useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault();
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);
	
	  const showError = (result) => {
		setErrorMessage(result);
		if (result) {
			document.documentElement.scrollTop = 0;
		}
	};

	useEffect(() => {
		deleteEtag(BADGES_ETAG_KEY);
		fetchData();
		// stopPolling(pollingIntervalRef);
		// startPolling(fetchData, pollingIntervalRef);
	}, [searchTerm, activePage]);


	async function fetchData(refreshAfterDelete) {
		console.log('fetch data search term ', searchTerm);
		try {
			const headers = {};
			const storedEtag = getEtag(BADGES_ETAG_KEY);
			if (storedEtag) {
				headers["If-None-Match"] = storedEtag;
			}

			let url = `/api/v1/plans?page=${
				refreshAfterDelete === 1 ? refreshAfterDelete : activePage
			}&limit=${PAGE_OFFSET}`;
			if (searchTerm) {
 				url += `&search=${encodeURIComponent(searchTerm)}`;
			}
			stopPolling(pollingIntervalRef);
			const res = await apiInstance.get(url, {
				headers,
				});
				if (res && res.status === 200) {
					setPlanList(res.data.data.plans);
					setTotalPages(res.data.data.totalPages);
					setCount(res.data.data.totalPlans);
					setEtag(BADGES_ETAG_KEY, res.headers.etag);
 					setLoading(false);
				 	showError('');
				}
			startPolling(fetchData, pollingIntervalRef);
 		} catch (error) {
			startPolling(fetchData, pollingIntervalRef);
 			if (error.response && error.response.status === 304) {
				setLoading(false);
				showError('');
				return; 
			}
			setLoading(false);
			console.error(error);
			const result = errorFunction(error)

 			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	}

	const onChange = (e, pageInfo) => {
		setActivePage(pageInfo.activePage);
	};

	const closeModal = () => setShowModal(false);

	const handleDeletePlan = async () => {
		setLoading(true);
		closeModal();
		logEvent({category:Categories.plan,action:Actions.plan_delete_btn});					
        try {
			const result = await apiInstance.delete(`/api/v1/plans/${planId}`);
			if (result && result.status === 200) {
			setLoading(false);
			logEvent({category:Categories.plan,action:Actions.plan_delete_success});					
            setPlanList(planList.filter(plan => plan._id !== planId));
			setCount(prevValue => prevValue-=1)
            toast.success('Successfully Deleted!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000, 
            });

				// setTimeout(() => {
					
				// }, 1100);
			}
		} catch (error) {
			setLoading(false);
			const result = errorFunction(error)
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
			
		}
		setShowModal(false);
	};

	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);

	useEffect(() => {
		document.addEventListener('visibilitychange', visibilityChangeHandler);

		return () => {
			stopPolling(pollingIntervalRef);

			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, [activePage, searchTerm]);
 
	return (
		<div>
			<Breadcrumb links={[{ link: '/plan', title: intl.planList }]}/>
			<div className='container-fluid'>
				<div className='row page-titles'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
						<div className='card CardNoBorder' id='PersonForm'>
							<div className='card-header card_headertitle'>
								<div className='card-title card_head'>
									<div className='row d-flex align-items-center'>
										<div className='col-lg-2 col-md-2 col-sm-6 col-6 d-flex align-items-center'>
											<h4 className='card_head CardTitle text-uppercase component-heading  '>
												{intl.planList}
											</h4>
										</div>
										<div className=' col-lg-7 col-md-6 mt-2 mb-2 col-sm-12 col-12 d-flex'>
											<input
											    id='searchName'
												type='search'
												placeholder={intl.searchButton}
												className='w-100 search-input'
												value={searchText}
												onKeyDown={(e) => {
													if (e.keyCode === 13) {
														setSearchTerm(searchText);
														if(searchTerm.length > 0) {
															setLoading(true);
														}
													}
												  }}
												style={searchText.length > 1 ? {borderRight: '0px solid red', borderTopRightRadius: '0em', borderBottomRightRadius:'0em'} : null}
												onChange={(e) => {
													maxLength(e.target.value, 255);
													setSearchText(e.target.value);
												}}
											/> 

											{searchText.length > 1 ? <span className='cancel-container'
											onClick={() => {
												setSearchTerm("");
												setSearchText("");
												}}>
												<FaTimes id="btnClear" className='cancel-icon' 
												
												/>
											</span> : null }
										</div>
										<div className='col-lg-1 col-md-2 col-sm-6 col-6'>
											<div className='float_left mr-3'>
												<input
													type='button'
													onClick={() => {
														setSearchTerm(searchText);
														setActivePage(1);
														logEvent({category:Categories.plan,action:Actions.plan_search_btn});
													}}
													className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
													value={intl.searchButton}
													id='PersonBtn'
												/>
											</div>
										</div>
										<div className='col-lg-2 col-md-2 col-sm-6 col-6 '>
											<span zxtitle='Add Student'
												className='btn AddButton btn-sm float_right'
												onClick={() => {history.push('/addplan');logEvent({category:Categories.plan,action:Actions.plan_add_btn});}}
											>
												<i>
													<FaPlus />
												</i>{' '}
												{intl.addPlan}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='card-body DataTable PersonGrid'>
								<div className='table-responsive'>
									{errorMessage && (
										<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
											{errorMessage}
										</div>
									)}
									{loading ? (
												<div className="d-flex justify-content-center align-items-center">
													<Spinner animation="border" variant="secondary" />
												</div>
											) : 
									<table className='table table-bordered'>
										<thead>
											<tr>
												<th scope='col' style={{'minWidth': '12em'}}>{intl.SearchName}</th>
												<th scope='col'>{intl.licenseDuration}</th>
												<th scope='col'>{intl.staffs}</th>
												<th scope='col'>{intl.students}</th>
												<th scope='col'>{intl.devices}</th>
												<th scope='col'>{intl.badges}</th>
												<th scope='col' style={{width:'10%'}}>{intl.actions}</th>
											</tr>
										</thead>
										<tbody>
											{planList && planList.length > 0 ? (
												planList.map((plan, i) => {
													return (
														<tr key={i}>
															<td>
																{plan.license_type}{plan.is_default && <span className='small-text'> ({intl.default})</span>}
															</td>
															<td>{plan.trial_period}</td>
															<td>{plan.allowed_teachers}</td>
															<td>{plan.allowed_students}</td>
															<td>{plan.allowed_devices}</td>
															<td>{plan.allowed_badges}</td>
															<td>
																<div className='d-flex'>
																<FaEdit className="iconAction-edit" 
																		title="Edit"
																		onClick={() =>{
																			history.push({
																				pathname: `/editplan/${plan._id}`,
																				state: { data: plan },
																			});
																			
																			logEvent({category:Categories.plan,action:Actions.plan_edit_btn});
																		}
																	}
																	/>
																		
																<FaTrashAlt className="iconAction-danger"
																	title="Delete" 
																	onClick={() => {
																			setDeletePlanId(plan._id);
																			setShowModal(true);
																		}}
																	/>
																		
																</div>
															</td>
														</tr>
													);
												})
											) : (
											
												<tr>
													<td colSpan="5" style={{ textAlign: 'center' }}>
														{intl.noRecordFound}
													</td>
												</tr>
												
											)}
										</tbody>
									</table>}
									{showModal ? (
										<AlertModal
											closeModal={closeModal}
											header={intl.deleteHeader}
											body={intl.deleteBody}
											handlerFunction={handleDeletePlan}
										/>
									) : null}
									{ (planList && planList.length) > 0 ?
										<div className='count_div'>
											<Pagination
												boundaryRange={1}
												ellipsisItem="..."
												firstItem={null}
												lastItem={null}
												activePage={activePage}
												onPageChange={onChange}
												siblingRange={1}
												totalPages={totalPages}
												className={totalPages === 1 ? "paginationbutton" : null}
											/>
											<div>Total: {count}</div>
										</div>
										: ''
									}
									<ToastContainer />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showModal2 ? (
					<LogoutModal/>
				) : null}
		</div>
	);
};

export default withRouter(Plan);
